const icons = {
  absFill: "\ue900",
  absLine: "\ue901",
  addCircleFill: "\ue902",
  addCircleLine: "\ue903",
  addFill: "\ue904",
  addLine: "\ue905",
  aerialLiftFill: "\ue906",
  aerialLiftLine: "\ue907",
  aiming2Fill: "\ue908",
  aiming2Line: "\ue909",
  aimingFill: "\ue90a",
  aimingLine: "\ue90b",
  airbnbFill: "\ue90c",
  airbnbLine: "\ue90d",
  airplaneFill: "\ue90e",
  airplaneLine: "\ue90f",
  airplayFill: "\ue910",
  airplayLine: "\ue911",
  airpodsFill: "\ue912",
  airpodsLine: "\ue913",
  alarm1Fill: "\ue914",
  alarm1Line: "\ue915",
  alarm2Fill: "\ue916",
  alarm2Line: "\ue917",
  album2Fill: "\ue918",
  album2Line: "\ue919",
  albumFill: "\ue91a",
  albumLine: "\ue91b",
  alertFill: "\ue91c",
  alertLine: "\ue91d",
  alertOctagonFill: "\ue91e",
  alertOctagonLine: "\ue91f",
  alignBottomFill: "\ue920",
  alignBottomLine: "\ue921",
  alignCenterFill: "\ue922",
  alignCenterLine: "\ue923",
  alignHorizontalCenterFill: "\ue924",
  alignHorizontalCenterLine: "\ue925",
  alignLeft2Fill: "\ue926",
  alignLeft2Line: "\ue927",
  alignLeftFill: "\ue928",
  alignLeftLine: "\ue929",
  alignRight2Fill: "\ue92a",
  alignRight2Line: "\ue92b",
  alignRightFill: "\ue92c",
  alignRightLine: "\ue92d",
  alignTopFill: "\ue92e",
  alignTopLine: "\ue92f",
  alignVerticalCenterFill: "\ue930",
  alignVerticalCenterLine: "\ue931",
  alipayFill: "\ue932",
  alipayLine: "\ue933",
  anchorFill: "\ue934",
  anchorLine: "\ue935",
  andFill: "\ue936",
  andLine: "\ue937",
  android2Fill: "\ue938",
  android2Line: "\ue939",
  androidFill: "\ue93a",
  androidLine: "\ue93b",
  announcementFill: "\ue93c",
  announcementLine: "\ue93d",
  anticlockwiseFill: "\ue93e",
  anticlockwiseLine: "\ue93f",
  appleFill: "\ue940",
  appleLine: "\ue941",
  appstoreFill: "\ue942",
  appstoreLine: "\ue943",
  arrowDownFill: "\ue944",
  arrowDownLine: "\ue945",
  arrowLeftDownFill: "\ue946",
  arrowLeftDownLine: "\ue947",
  arrowLeftFill: "\ue948",
  arrowLeftLine: "\ue949",
  arrowRightFill: "\ue94a",
  arrowRightLine: "\ue94b",
  arrowUpFill: "\ue94c",
  arrowUpLine: "\ue94d",
  asteriskFill: "\ue94e",
  asteriskLine: "\ue94f",
  atFill: "\ue950",
  atLine: "\ue951",
  attachment2Fill: "\ue952",
  attachment2Line: "\ue953",
  attachmentFill: "\ue954",
  attachmentLine: "\ue955",
  auctionFill: "\ue956",
  auctionLine: "\ue957",
  audioTapeFill: "\ue958",
  audioTapeLine: "\ue959",
  autoHoldFill: "\ue95a",
  autoHoldLine: "\ue95b",
  avalancheAvaxFill: "\ue95c",
  avalancheAvaxLine: "\ue95d",
  awardFill: "\ue95e",
  awardLine: "\ue95f",
  azSortAscendingLettersFill: "\ue960",
  azSortAscendingLettersLine: "\ue961",
  babyCarriageFill: "\ue962",
  babyCarriageLine: "\ue963",
  back2Fill: "\ue964",
  back2Line: "\ue965",
  backFill: "\ue966",
  backLine: "\ue967",
  badgeFill: "\ue968",
  badgeLine: "\ue969",
  balanceFill: "\ue96a",
  balanceLine: "\ue96b",
  bankCardFill: "\ue96c",
  bankCardLine: "\ue96d",
  bankFill: "\ue96e",
  bankLine: "\ue96f",
  bankOfChinaTowerFill: "\ue970",
  bankOfChinaTowerLine: "\ue971",
  basketFill: "\ue972",
  basketLine: "\ue973",
  batteryAutomotiveFill: "\ue974",
  batteryAutomotiveLine: "\ue975",
  batteryChargingFill: "\ue976",
  batteryChargingLine: "\ue977",
  batteryFill: "\ue978",
  batteryLine: "\ue979",
  beardFill: "\ue97a",
  beardLine: "\ue97b",
  bigBenFill: "\ue97c",
  bigBenLine: "\ue97d",
  bikeFill: "\ue97e",
  bikeLine: "\ue97f",
  billFill: "\ue980",
  billLine: "\ue981",
  binanceCoinBnbFill: "\ue982",
  binanceCoinBnbLine: "\ue983",
  binanceUsdBusdFill: "\ue984",
  binanceUsdBusdLine: "\ue985",
  blingFill: "\ue986",
  blingLine: "\ue987",
  bluetoothFill: "\ue988",
  bluetoothLine: "\ue989",
  bluetoothOffFill: "\ue98a",
  bluetoothOffLine: "\ue98b",
  bnbFill: "\ue98c",
  bnbLine: "\ue98d",
  bodyFill: "\ue98e",
  bodyLine: "\ue98f",
  boldFill: "\ue990",
  boldLine: "\ue991",
  book2Fill: "\ue992",
  book2Line: "\ue993",
  book3Fill: "\ue994",
  book3Line: "\ue995",
  book4Fill: "\ue996",
  book4Line: "\ue997",
  book5Fill: "\ue998",
  book5Line: "\ue999",
  book6Fill: "\ue99a",
  book6Line: "\ue99b",
  bookFill: "\ue99c",
  bookLine: "\ue99d",
  bookmarkFill: "\ue99e",
  bookmarkLine: "\ue99f",
  borderBlankFill: "\ue9a0",
  borderBlankLine: "\ue9a1",
  borderBottomFill: "\ue9a2",
  borderBottomLine: "\ue9a3",
  borderHorizontalFill: "\ue9a4",
  borderHorizontalLine: "\ue9a5",
  borderInnerFill: "\ue9a6",
  borderInnerLine: "\ue9a7",
  borderLeftFill: "\ue9a8",
  borderLeftLine: "\ue9a9",
  borderOuterFill: "\ue9aa",
  borderOuterLine: "\ue9ab",
  borderRadiusFill: "\ue9ac",
  borderRadiusLine: "\ue9ad",
  borderTopFill: "\ue9ae",
  borderTopLine: "\ue9af",
  borderVerticalFill: "\ue9b0",
  borderVerticalLine: "\ue9b1",
  bowTieFill: "\ue9b2",
  bowTieLine: "\ue9b3",
  bowlFill: "\ue9b4",
  bowlLine: "\ue9b5",
  box2Fill: "\ue9b6",
  box2Line: "\ue9b7",
  box3Fill: "\ue9b8",
  box3Line: "\ue9b9",
  boxFill: "\ue9ba",
  boxLine: "\ue9bb",
  bracesFill: "\ue9bc",
  bracesLine: "\ue9bd",
  bracketsFill: "\ue9be",
  bracketsLine: "\ue9bf",
  brakeFill: "\ue9c0",
  brakeLine: "\ue9c1",
  breadFill: "\ue9c2",
  breadLine: "\ue9c3",
  bridge2Fill: "\ue9c4",
  bridge2Line: "\ue9c5",
  bridgeFill: "\ue9c6",
  bridgeLine: "\ue9c7",
  briefFill: "\ue9c8",
  briefLine: "\ue9c9",
  briefcaseFill: "\ue9ca",
  briefcaseLine: "\ue9cb",
  brightnessFill: "\ue9cc",
  brightnessLine: "\ue9cd",
  brush2Fill: "\ue9ce",
  brush2Line: "\ue9cf",
  brushFill: "\ue9d0",
  brushLine: "\ue9d1",
  bugFill: "\ue9d2",
  bugLine: "\ue9d3",
  building1Fill: "\ue9d4",
  building1Line: "\ue9d5",
  building2Fill: "\ue9d6",
  building2Line: "\ue9d7",
  building3Fill: "\ue9d8",
  building3Line: "\ue9d9",
  building4Fill: "\ue9da",
  building4Line: "\ue9db",
  building5Fill: "\ue9dc",
  building5Line: "\ue9dd",
  building6Fill: "\ue9de",
  building6Line: "\ue9df",
  bulbFill: "\ue9e0",
  bulbLine: "\ue9e1",
  burjAlArabFill: "\ue9e2",
  burjAlArabLine: "\ue9e3",
  burjKhalifaTowerFill: "\ue9e4",
  burjKhalifaTowerLine: "\ue9e5",
  bus2Fill: "\ue9e6",
  bus2Line: "\ue9e7",
  busFill: "\ue9e8",
  busLine: "\ue9e9",
  cakeFill: "\ue9ea",
  cakeLine: "\ue9eb",
  calendarFill: "\ue9ec",
  calendarLine: "\ue9ed",
  camcorderFill: "\ue9ee",
  camcorderLine: "\ue9ef",
  camera2Fill: "\ue9f0",
  camera2Line: "\ue9f1",
  cameraFill: "\ue9f2",
  cameraLine: "\ue9f3",
  cameraRotateFill: "\ue9f4",
  cameraRotateLine: "\ue9f5",
  campgroundFill: "\ue9f6",
  campgroundLine: "\ue9f7",
  candleFill: "\ue9f8",
  candleLine: "\ue9f9",
  cantonTowerFill: "\ue9fa",
  cantonTowerLine: "\ue9fb",
  car2Fill: "\ue9fc",
  car2Line: "\ue9fd",
  car3Fill: "\ue9fe",
  car3Line: "\ue9ff",
  carFill: "\uea00",
  carLine: "\uea01",
  cardanoAdaFill: "\uea02",
  cardanoAdaLine: "\uea03",
  carplayFill: "\uea04",
  carplayLine: "\uea05",
  ceilingLampFill: "\uea06",
  ceilingLampLine: "\uea07",
  cellphoneFill: "\uea08",
  cellphoneLine: "\uea09",
  cellphoneVibrationFill: "\uea0a",
  cellphoneVibrationLine: "\uea0b",
  certificate2Fill: "\uea0c",
  certificate2Line: "\uea0d",
  certificateFill: "\uea0e",
  certificateLine: "\uea0f",
  chargingPileFill: "\uea10",
  chargingPileLine: "\uea11",
  chartBarFill: "\uea12",
  chartBarLine: "\uea13",
  chartLineFill: "\uea14",
  chartLineLine: "\uea15",
  chartPieFill: "\uea16",
  chartPieLine: "\uea17",
  chat1Fill: "\uea18",
  chat1Line: "\uea19",
  chat2Fill: "\uea1a",
  chat2Line: "\uea1b",
  chat3Fill: "\uea1c",
  chat3Line: "\uea1d",
  checkCircleFill: "\uea1e",
  checkCircleLine: "\uea1f",
  checkFill: "\uea20",
  checkLine: "\uea21",
  chipFill: "\uea22",
  chipLine: "\uea23",
  christTheRedeemerFill: "\uea24",
  christTheRedeemerLine: "\uea25",
  chromeFill: "\uea26",
  chromeLine: "\uea27",
  clapperboardFill: "\uea28",
  clapperboardLine: "\uea29",
  classifyFill: "\uea2a",
  classifyLine: "\uea2b",
  clipboardFill: "\uea2c",
  clipboardLine: "\uea2d",
  clock2Fill: "\uea2e",
  clock2Line: "\uea2f",
  clockFill: "\uea30",
  clockLine: "\uea31",
  clockwiseFill: "\uea32",
  clockwiseLine: "\uea33",
  closeCircleFill: "\uea34",
  closeCircleLine: "\uea35",
  closeFill: "\uea36",
  closeLine: "\uea37",
  cloudFill: "\uea38",
  cloudLightningFill: "\uea39",
  cloudLightningLine: "\uea3a",
  cloudLine: "\uea3b",
  cloudSnowFill: "\uea3c",
  cloudSnowLine: "\uea3d",
  coatFill: "\uea3e",
  coatLine: "\uea3f",
  codeFill: "\uea40",
  codeLine: "\uea41",
  coin2Fill: "\uea42",
  coin2Line: "\uea43",
  coinFill: "\uea44",
  coinLine: "\uea45",
  colorFilterFill: "\uea46",
  colorFilterLine: "\uea47",
  colorPickerFill: "\uea48",
  colorPickerLine: "\uea49",
  columnFill: "\uea4a",
  columnLine: "\uea4b",
  columns2Fill: "\uea4c",
  columns2Line: "\uea4d",
  columns3Fill: "\uea4e",
  columns3Line: "\uea4f",
  commandFill: "\uea50",
  commandLine: "\uea51",
  commentFill: "\uea52",
  commentLine: "\uea53",
  compassFill: "\uea54",
  compassLine: "\uea55",
  computerCameraFill: "\uea56",
  computerCameraLine: "\uea57",
  computerCameraOffFill: "\uea58",
  computerCameraOffLine: "\uea59",
  computerFill: "\uea5a",
  computerLine: "\uea5b",
  contactsFill: "\uea5c",
  contactsLine: "\uea5d",
  copperCoinFill: "\uea5e",
  copperCoinLine: "\uea5f",
  copy2Fill: "\uea60",
  copy2Line: "\uea61",
  copy3Fill: "\uea62",
  copy3Line: "\uea63",
  copyFill: "\uea64",
  copyLine: "\uea65",
  copyrightFill: "\uea66",
  copyrightLine: "\uea67",
  counter2Fill: "\uea68",
  counter2Line: "\uea69",
  counterFill: "\uea6a",
  counterLine: "\uea6b",
  couponFill: "\uea6c",
  couponLine: "\uea6d",
  currencyBaht2Fill: "\uea6e",
  currencyBaht2Line: "\uea6f",
  currencyBahtFill: "\uea70",
  currencyBahtLine: "\uea71",
  currencyBitcoin2Fill: "\uea72",
  currencyBitcoin2Line: "\uea73",
  currencyBitcoinFill: "\uea74",
  currencyBitcoinLine: "\uea75",
  currencyCny2Fill: "\uea76",
  currencyCny2Line: "\uea77",
  currencyCnyFill: "\uea78",
  currencyCnyLine: "\uea79",
  currencyDollar2Fill: "\uea7a",
  currencyDollar2Line: "\uea7b",
  currencyDollarFill: "\uea7c",
  currencyDollarLine: "\uea7d",
  currencyEuro2Fill: "\uea7e",
  currencyEuro2Line: "\uea7f",
  currencyEuroFill: "\uea80",
  currencyEuroLine: "\uea81",
  currencyLira2Fill: "\uea82",
  currencyLira2Line: "\uea83",
  currencyLiraFill: "\uea84",
  currencyLiraLine: "\uea85",
  currencyPound2Fill: "\uea86",
  currencyPound2Line: "\uea87",
  currencyPoundFill: "\uea88",
  currencyPoundLine: "\uea89",
  currencyRubel2Fill: "\uea8a",
  currencyRubel2Line: "\uea8b",
  currencyRubelFill: "\uea8c",
  currencyRubelLine: "\uea8d",
  currencyRupee2Fill: "\uea8e",
  currencyRupee2Line: "\uea8f",
  currencyRupeeFill: "\uea90",
  currencyRupeeLine: "\uea91",
  currencyShekel2Fill: "\uea92",
  currencyShekel2Line: "\uea93",
  currencyShekelFill: "\uea94",
  currencyShekelLine: "\uea95",
  currencyWon2Fill: "\uea96",
  currencyWon2Line: "\uea97",
  currencyWonFill: "\uea98",
  currencyWonLine: "\uea99",
  cursorFill: "\uea9a",
  cursorLine: "\uea9b",
  dashboard2Fill: "\uea9c",
  dashboard2Line: "\uea9d",
  dashboard3Fill: "\uea9e",
  dashboard3Line: "\uea9f",
  dashboard4Fill: "\ueaa0",
  dashboard4Line: "\ueaa1",
  dashboardFill: "\ueaa2",
  dashboardLine: "\ueaa3",
  delete2Fill: "\ueaa4",
  delete2Line: "\ueaa5",
  deleteBackFill: "\ueaa6",
  deleteBackLine: "\ueaa7",
  deleteFill: "\ueaa8",
  deleteLine: "\ueaa9",
  dentalFill: "\ueaaa",
  dentalLine: "\ueaab",
  departmentFill: "\ueaac",
  departmentLine: "\ueaad",
  deskFill: "\ueaae",
  deskLampFill: "\ueaaf",
  deskLampLine: "\ueab0",
  deskLine: "\ueab1",
  deviceFill: "\ueab2",
  deviceLine: "\ueab3",
  diamond2Fill: "\ueab4",
  diamond2Line: "\ueab5",
  diamondFill: "\ueab6",
  diamondLine: "\ueab7",
  diaryFill: "\ueab8",
  diaryLine: "\ueab9",
  directions2Fill: "\ueaba",
  directions2Line: "\ueabb",
  directionsFill: "\ueabc",
  directionsLine: "\ueabd",
  directoryFill: "\ueabe",
  directoryLine: "\ueabf",
  discFill: "\ueac0",
  discLine: "\ueac1",
  discordFill: "\ueac2",
  discordLine: "\ueac3",
  displayFill: "\ueac4",
  displayLine: "\ueac5",
  distributeSpacingHorizontalFill: "\ueac6",
  distributeSpacingHorizontalLine: "\ueac7",
  distributeSpacingVerticalFill: "\ueac8",
  distributeSpacingVerticalLine: "\ueac9",
  dividingLineFill: "\ueaca",
  dividingLineLine: "\ueacb",
  docFill: "\ueacc",
  docLine: "\ueacd",
  document2Fill: "\ueace",
  document2Line: "\ueacf",
  document3Fill: "\uead0",
  document3Line: "\uead1",
  documentFill: "\uead2",
  documentLine: "\uead3",
  documentsFill: "\uead4",
  documentsLine: "\uead5",
  dogecoinDogeFill: "\uead6",
  dogecoinDogeLine: "\uead7",
  doorFill: "\uead8",
  doorLine: "\uead9",
  dotGridFill: "\ueada",
  dotGridLine: "\ueadb",
  downFill: "\ueadc",
  downLine: "\ueadd",
  download2Fill: "\ueade",
  download2Line: "\ueadf",
  download3Fill: "\ueae0",
  download3Line: "\ueae1",
  downloadFill: "\ueae2",
  downloadLine: "\ueae3",
  dribbbleFill: "\ueae4",
  dribbbleLine: "\ueae5",
  drinkFill: "\ueae6",
  drinkLine: "\ueae7",
  dropFill: "\ueae8",
  dropLine: "\ueae9",
  dutchWindmillFill: "\ueaea",
  dutchWindmillLine: "\ueaeb",
  earFill: "\ueaec",
  earLine: "\ueaed",
  earthFill: "\ueaee",
  earthLine: "\ueaef",
  ebikeFill: "\ueaf0",
  ebikeLine: "\ueaf1",
  edit2Fill: "\ueaf2",
  edit2Line: "\ueaf3",
  edit3Fill: "\ueaf4",
  edit3Line: "\ueaf5",
  editFill: "\ueaf6",
  editLine: "\ueaf7",
  egyptianPyramidsFill: "\ueaf8",
  egyptianPyramidsLine: "\ueaf9",
  eiffelTowerFill: "\ueafa",
  eiffelTowerLine: "\ueafb",
  emergencyFlashersFill: "\ueafc",
  emergencyFlashersLine: "\ueafd",
  emoji2Fill: "\ueafe",
  emoji2Line: "\ueaff",
  emojiFill: "\ueb00",
  emojiLine: "\ueb01",
  engineFill: "\ueb02",
  engineLine: "\ueb03",
  enterDoorFill: "\ueb04",
  enterDoorLine: "\ueb05",
  entranceFill: "\ueb06",
  entranceLine: "\ueb07",
  eraserFill: "\ueb08",
  eraserLine: "\ueb09",
  ethereumFill: "\ueb0a",
  ethereumLine: "\ueb0b",
  exchangeBahtFill: "\ueb0c",
  exchangeBahtLine: "\ueb0d",
  exchangeBitcoinFill: "\ueb0e",
  exchangeBitcoinLine: "\ueb0f",
  exchangeCnyFill: "\ueb10",
  exchangeCnyLine: "\ueb11",
  exchangeDollarFill: "\ueb12",
  exchangeDollarLine: "\ueb13",
  exchangeEuroFill: "\ueb14",
  exchangeEuroLine: "\ueb15",
  exitDoorFill: "\ueb16",
  exitDoorLine: "\ueb17",
  exitFill: "\ueb18",
  exitLine: "\ueb19",
  expandPlayerFill: "\ueb1a",
  expandPlayerLine: "\ueb1b",
  externalLinkFill: "\ueb1c",
  externalLinkLine: "\ueb1d",
  eye2Fill: "\ueb1e",
  eye2Line: "\ueb1f",
  eyeCloseFill: "\ueb20",
  eyeCloseLine: "\ueb21",
  eyeFill: "\ueb22",
  eyeLine: "\ueb23",
  eyebrowFill: "\ueb24",
  eyebrowLine: "\ueb25",
  eyeglassFill: "\ueb26",
  eyeglassLine: "\ueb27",
  faceFill: "\ueb28",
  faceLine: "\ueb29",
  facebookFill: "\ueb2a",
  facebookLine: "\ueb2b",
  faceidFill: "\ueb2c",
  faceidLine: "\ueb2d",
  factoryFill: "\ueb2e",
  factoryLine: "\ueb2f",
  fan2Fill: "\ueb30",
  fan2Line: "\ueb31",
  fanDirectionDownFill: "\ueb32",
  fanDirectionDownLine: "\ueb33",
  fanDirectionFrontFill: "\ueb34",
  fanDirectionFrontLine: "\ueb35",
  fanDirectionUpFill: "\ueb36",
  fanDirectionUpLine: "\ueb37",
  fanFill: "\ueb38",
  fanLine: "\ueb39",
  fastForwardFill: "\ueb3a",
  fastForwardLine: "\ueb3b",
  fastRewindFill: "\ueb3c",
  fastRewindLine: "\ueb3d",
  femaleFill: "\ueb3e",
  femaleLine: "\ueb3f",
  fileCertificateFill: "\ueb40",
  fileCertificateLine: "\ueb41",
  fileCheckFill: "\ueb42",
  fileCheckLine: "\ueb43",
  fileCodeFill: "\ueb44",
  fileCodeLine: "\ueb45",
  fileDownloadFill: "\ueb46",
  fileDownloadLine: "\ueb47",
  fileExportFill: "\ueb48",
  fileExportLine: "\ueb49",
  fileFill: "\ueb4a",
  fileForbidFill: "\ueb4b",
  fileForbidLine: "\ueb4c",
  fileImportFill: "\ueb4d",
  fileImportLine: "\ueb4e",
  fileInforFill: "\ueb4f",
  fileInforLine: "\ueb50",
  fileLine: "\ueb51",
  fileLockedFill: "\ueb52",
  fileLockedLine: "\ueb53",
  fileMoreFill: "\ueb54",
  fileMoreLine: "\ueb55",
  fileMusicFill: "\ueb56",
  fileMusicLine: "\ueb57",
  fileNewFill: "\ueb58",
  fileNewLine: "\ueb59",
  fileSearchFill: "\ueb5a",
  fileSearchLine: "\ueb5b",
  fileSecurityFill: "\ueb5c",
  fileSecurityLine: "\ueb5d",
  fileStarFill: "\ueb5e",
  fileStarLine: "\ueb5f",
  fileUnknownFill: "\ueb60",
  fileUnknownLine: "\ueb61",
  fileUploadFill: "\ueb62",
  fileUploadLine: "\ueb63",
  fileWarningFill: "\ueb64",
  fileWarningLine: "\ueb65",
  fileZipFill: "\ueb66",
  fileZipLine: "\ueb67",
  filmFill: "\ueb68",
  filmLine: "\ueb69",
  filter2Fill: "\ueb6a",
  filter2Line: "\ueb6b",
  filter3Fill: "\ueb6c",
  filter3Line: "\ueb6d",
  filterFill: "\ueb6e",
  filterLine: "\ueb6f",
  fingerPressFill: "\ueb70",
  fingerPressLine: "\ueb71",
  fingerRockFill: "\ueb72",
  fingerRockLine: "\ueb73",
  fingerSwipeFill: "\ueb74",
  fingerSwipeLine: "\ueb75",
  fingerTapFill: "\ueb76",
  fingerTapLine: "\ueb77",
  fingerprintFill: "\ueb78",
  fingerprintLine: "\ueb79",
  fireFill: "\ueb7a",
  fireLine: "\ueb7b",
  fishFill: "\ueb7c",
  fishLine: "\ueb7d",
  flag1Fill: "\ueb7e",
  flag1Line: "\ueb7f",
  flag2Fill: "\ueb80",
  flag2Line: "\ueb81",
  flag3Fill: "\ueb82",
  flag3Line: "\ueb83",
  flag4Fill: "\ueb84",
  flag4Line: "\ueb85",
  flashFill: "\ueb86",
  flashLine: "\ueb87",
  flashlightFill: "\ueb88",
  flashlightLine: "\ueb89",
  flask2Fill: "\ueb8a",
  flask2Line: "\ueb8b",
  flaskFill: "\ueb8c",
  flaskLine: "\ueb8d",
  flightInflightFill: "\ueb8e",
  flightInflightLine: "\ueb8f",
  flightLandFill: "\ueb90",
  flightLandLine: "\ueb91",
  flightTakeoffFill: "\ueb92",
  flightTakeoffLine: "\ueb93",
  flipHorizontalFill: "\ueb94",
  flipHorizontalLine: "\ueb95",
  flipVerticalFill: "\ueb96",
  flipVerticalLine: "\ueb97",
  flower2Fill: "\ueb98",
  flower2Line: "\ueb99",
  flowerFill: "\ueb9a",
  flowerLine: "\ueb9b",
  folder2Fill: "\ueb9c",
  folder2Line: "\ueb9d",
  folder3Fill: "\ueb9e",
  folder3Line: "\ueb9f",
  folderCheckFill: "\ueba0",
  folderCheckLine: "\ueba1",
  folderDeleteFill: "\ueba2",
  folderDeleteLine: "\ueba3",
  folderDownloadFill: "\ueba4",
  folderDownloadLine: "\ueba5",
  folderFill: "\ueba6",
  folderForbidFill: "\ueba7",
  folderForbidLine: "\ueba8",
  folderInforFill: "\ueba9",
  folderInforLine: "\uebaa",
  folderLine: "\uebab",
  folderLocked2Fill: "\uebac",
  folderLocked2Line: "\uebad",
  folderLockedFill: "\uebae",
  folderLockedLine: "\uebaf",
  folderMinusFill: "\uebb0",
  folderMinusLine: "\uebb1",
  folderMoreFill: "\uebb2",
  folderMoreLine: "\uebb3",
  folderOpen2Fill: "\uebb4",
  folderOpen2Line: "\uebb5",
  folderOpenFill: "\uebb6",
  folderOpenLine: "\uebb7",
  folderSecurityFill: "\uebb8",
  folderSecurityLine: "\uebb9",
  folderStarFill: "\uebba",
  folderStarLine: "\uebbb",
  folderUploadFill: "\uebbc",
  folderUploadLine: "\uebbd",
  folderWarningFill: "\uebbe",
  folderWarningLine: "\uebbf",
  fontFill: "\uebc0",
  fontLine: "\uebc1",
  fontSizeFill: "\uebc2",
  fontSizeLine: "\uebc3",
  footFill: "\uebc4",
  footLine: "\uebc5",
  forbidCircleFill: "\uebc6",
  forbidCircleLine: "\uebc7",
  formulaFill: "\uebc8",
  formulaLine: "\uebc9",
  forward2Fill: "\uebca",
  forward2Line: "\uebcb",
  forwardFill: "\uebcc",
  forwardLine: "\uebcd",
  fourWheelDriveFill: "\uebce",
  fourWheelDriveLine: "\uebcf",
  frameFill: "\uebd0",
  frameLine: "\uebd1",
  fridgeFill: "\uebd2",
  fridgeLine: "\uebd3",
  frontFogLightsFill: "\uebd4",
  frontFogLightsLine: "\uebd5",
  frontWindshieldDefrosterFill: "\uebd6",
  frontWindshieldDefrosterLine: "\uebd7",
  fullscreen2Fill: "\uebd8",
  fullscreen2Line: "\uebd9",
  fullscreenExit2Fill: "\uebda",
  fullscreenExit2Line: "\uebdb",
  fullscreenExitFill: "\uebdc",
  fullscreenExitLine: "\uebdd",
  fullscreenFill: "\uebde",
  fullscreenLine: "\uebdf",
  game1Fill: "\uebe0",
  game1Line: "\uebe1",
  game2Fill: "\uebe2",
  game2Line: "\uebe3",
  gasStationFill: "\uebe4",
  gasStationLine: "\uebe5",
  ghostFill: "\uebe6",
  ghostLine: "\uebe7",
  gift2Fill: "\uebe8",
  gift2Line: "\uebe9",
  giftFill: "\uebea",
  giftLine: "\uebeb",
  gitBranchFill: "\uebec",
  gitBranchLine: "\uebed",
  gitCommitFill: "\uebee",
  gitCommitLine: "\uebef",
  gitCompareFill: "\uebf0",
  gitCompareLine: "\uebf1",
  gitLabFill: "\uebf2",
  gitLabLine: "\uebf3",
  gitMergeFill: "\uebf4",
  gitMergeLine: "\uebf5",
  gitPullRequestCloseFill: "\uebf6",
  gitPullRequestCloseLine: "\uebf7",
  gitPullRequestFill: "\uebf8",
  gitPullRequestLine: "\uebf9",
  githubFill: "\uebfa",
  githubLine: "\uebfb",
  glassCupFill: "\uebfc",
  glassCupLine: "\uebfd",
  globeFill: "\uebfe",
  globeLine: "\uebff",
  gloveFill: "\uec00",
  gloveLine: "\uec01",
  googleFill: "\uec02",
  googleLine: "\uec03",
  googlePlayFill: "\uec04",
  googlePlayLine: "\uec05",
  governmentFill: "\uec06",
  governmentLine: "\uec07",
  greatwallFill: "\uec08",
  greatwallLine: "\uec09",
  grid2Fill: "\uec0a",
  grid2Line: "\uec0b",
  gridFill: "\uec0c",
  gridLine: "\uec0d",
  groupFill: "\uec0e",
  groupLine: "\uec0f",
  hair2Fill: "\uec10",
  hair2Line: "\uec11",
  hairFill: "\uec12",
  hairLine: "\uec13",
  handFill: "\uec14",
  handFinger2Fill: "\uec15",
  handFinger2Line: "\uec16",
  handFingerFill: "\uec17",
  handFingerLine: "\uec18",
  handGrabFill: "\uec19",
  handGrabLine: "\uec1a",
  handLine: "\uec1b",
  handTwoFingersFill: "\uec1c",
  handTwoFingersLine: "\uec1d",
  hashtagFill: "\uec1e",
  hashtagLine: "\uec1f",
  hat2Fill: "\uec20",
  hat2Line: "\uec21",
  hatFill: "\uec22",
  hatLine: "\uec23",
  heading1Fill: "\uec24",
  heading1Line: "\uec25",
  heading2Fill: "\uec26",
  heading2Line: "\uec27",
  heading3Fill: "\uec28",
  heading3Line: "\uec29",
  headphone2Fill: "\uec2a",
  headphone2Line: "\uec2b",
  headphoneFill: "\uec2c",
  headphoneLine: "\uec2d",
  heartFill: "\uec2e",
  heartLine: "\uec2f",
  hexagonFill: "\uec30",
  hexagonLine: "\uec31",
  hightBeamHeadlightsFill: "\uec32",
  hightBeamHeadlightsLine: "\uec33",
  history2Fill: "\uec34",
  history2Line: "\uec35",
  historyFill: "\uec36",
  historyLine: "\uec37",
  home1Fill: "\uec38",
  home1Line: "\uec39",
  home2Fill: "\uec3a",
  home2Line: "\uec3b",
  home3Fill: "\uec3c",
  home3Line: "\uec3d",
  home4Fill: "\uec3e",
  home4Line: "\uec3f",
  home5Fill: "\uec40",
  home5Line: "\uec41",
  home6Fill: "\uec42",
  home6Line: "\uec43",
  hornFill: "\uec44",
  hornLine: "\uec45",
  hospitalFill: "\uec46",
  hospitalLine: "\uec47",
  hotkeyFill: "\uec48",
  hotkeyLine: "\uec49",
  idcardFill: "\uec4a",
  idcardLine: "\uec4b",
  imacFill: "\uec4c",
  imacLine: "\uec4d",
  inbox2Fill: "\uec4e",
  inbox2Line: "\uec4f",
  inboxFill: "\uec50",
  inboxLine: "\uec51",
  indentDecreaseFill: "\uec52",
  indentDecreaseLine: "\uec53",
  indentIncreaseFill: "\uec54",
  indentIncreaseLine: "\uec55",
  informationFill: "\uec56",
  informationLine: "\uec57",
  insFill: "\uec58",
  insLine: "\uec59",
  inviteFill: "\uec5a",
  inviteLine: "\uec5b",
  italicFill: "\uec5c",
  italicLine: "\uec5d",
  jeepFill: "\uec5e",
  jeepLine: "\uec5f",
  kakaoTalkFill: "\uec60",
  kakaoTalkLine: "\uec61",
  key1Fill: "\uec62",
  key1Line: "\uec63",
  key2Fill: "\uec64",
  key2Line: "\uec65",
  keyboardFill: "\uec66",
  keyboardLine: "\uec67",
  kingkey100TowerFill: "\uec68",
  kingkey100TowerLine: "\uec69",
  knifeFill: "\uec6a",
  knifeLine: "\uec6b",
  lanternFill: "\uec6c",
  lanternLine: "\uec6d",
  laptopFill: "\uec6e",
  laptopLine: "\uec6f",
  layout2Fill: "\uec70",
  layout2Line: "\uec71",
  layout3Fill: "\uec72",
  layout3Line: "\uec73",
  layout4Fill: "\uec74",
  layout4Line: "\uec75",
  layout5Fill: "\uec76",
  layout5Line: "\uec77",
  layout6Fill: "\uec78",
  layout6Line: "\uec79",
  layout7Fill: "\uec7a",
  layout7Line: "\uec7b",
  layout8Fill: "\uec7c",
  layout8Line: "\uec7d",
  layout9Fill: "\uec7e",
  layout9Line: "\uec7f",
  layout10Fill: "\uec80",
  layout10Line: "\uec81",
  layout11Fill: "\uec82",
  layout11Line: "\uec83",
  layoutBottomFill: "\uec84",
  layoutBottomLine: "\uec85",
  layoutFill: "\uec86",
  layoutGridFill: "\uec87",
  layoutGridLine: "\uec88",
  layoutLeftFill: "\uec89",
  layoutLeftLine: "\uec8a",
  layoutLine: "\uec8b",
  layoutRightFill: "\uec8c",
  layoutRightLine: "\uec8d",
  layoutTopFill: "\uec8e",
  layoutTopLine: "\uec8f",
  leftFill: "\uec90",
  leftLine: "\uec91",
  letterSpacingFill: "\uec92",
  letterSpacingLine: "\uec93",
  lifebuoyFill: "\uec94",
  lifebuoyLine: "\uec95",
  lightFill: "\uec96",
  lightLine: "\uec97",
  lighthouseFill: "\uec98",
  lighthouseLine: "\uec99",
  lightningFill: "\uec9a",
  lightningLine: "\uec9b",
  lineAppFill: "\uec9c",
  lineAppLine: "\uec9d",
  lineHeightFill: "\uec9e",
  lineHeightLine: "\uec9f",
  link2Fill: "\ueca0",
  link2Line: "\ueca1",
  linkFill: "\ueca2",
  linkLine: "\ueca3",
  linkedinFill: "\ueca4",
  linkedinLine: "\ueca5",
  listCheck2Fill: "\ueca6",
  listCheck2Line: "\ueca7",
  listCheck3Fill: "\ueca8",
  listCheck3Line: "\ueca9",
  listCheckFill: "\uecaa",
  listCheckLine: "\uecab",
  listCollapseFill: "\uecac",
  listCollapseLine: "\uecad",
  listExpansionFill: "\uecae",
  listExpansionLine: "\uecaf",
  listOrderedFill: "\uecb0",
  listOrderedLine: "\uecb1",
  liveLocationFill: "\uecb2",
  liveLocationLine: "\uecb3",
  livePhotoFill: "\uecb4",
  livePhotoLine: "\uecb5",
  loading2Fill: "\uecb6",
  loading2Line: "\uecb7",
  loading4Line: "\uecbd",
  loadingFill: "\uecbe",
  loadingLine: "\uecbf",
  location2Fill: "\uecc0",
  location2Line: "\uecc1",
  location3Fill: "\uecc2",
  location3Line: "\uecc3",
  locationFill: "\uecc4",
  locationLine: "\uecc5",
  lockFill: "\uecc6",
  lockLine: "\uecc7",
  lollipopFill: "\uecc8",
  lollipopLine: "\uecc9",
  lowBeamHeadlightsFill: "\uecca",
  lowBeamHeadlightsLine: "\ueccb",
  luggageFill: "\ueccc",
  luggageLine: "\ueccd",
  magic1Fill: "\uecce",
  magic1Line: "\ueccf",
  magic2Fill: "\uecd0",
  magic2Line: "\uecd1",
  mailFill: "\uecd2",
  mailLine: "\uecd3",
  mailOpenFill: "\uecd4",
  mailOpenLine: "\uecd5",
  mailSendFill: "\uecd6",
  mailSendLine: "\uecd7",
  mailboxFill: "\uecd8",
  mailboxLine: "\uecd9",
  maleFill: "\uecda",
  maleLine: "\uecdb",
  map2Fill: "\uecdc",
  map2Line: "\uecdd",
  mapFill: "\uecde",
  mapLine: "\uecdf",
  mapPinFill: "\uece0",
  mapPinLine: "\uece1",
  marinaBaySandFill: "\uece2",
  marinaBaySandLine: "\uece3",
  markPenFill: "\uece4",
  markPenLine: "\uece5",
  markupFill: "\uece6",
  markupLine: "\uece7",
  mastercardFill: "\uece8",
  mastercardLine: "\uece9",
  mayaPyramidsFill: "\uecea",
  mayaPyramidsLine: "\ueceb",
  medalFill: "\uecec",
  medalLine: "\ueced",
  mediumFill: "\uecee",
  mediumLine: "\uecef",
  menuFill: "\uecf0",
  menuLine: "\uecf1",
  message1Fill: "\uecf2",
  message1Line: "\uecf3",
  message2Fill: "\uecf4",
  message2Line: "\uecf5",
  message3Fill: "\uecf6",
  message3Line: "\uecf7",
  message4Fill: "\uecf8",
  message4Line: "\uecf9",
  messengerFill: "\uecfa",
  messengerLine: "\uecfb",
  metaFill: "\uecfc",
  metaLine: "\uecfd",
  mic2Fill: "\uecfe",
  mic2Line: "\uecff",
  micFill: "\ued00",
  micLine: "\ued01",
  micOffFill: "\ued02",
  micOffLine: "\ued03",
  microphoneFill: "\ued04",
  microphoneLine: "\ued05",
  microscopeFill: "\ued06",
  microscopeLine: "\ued07",
  middleFingerFill: "\ued08",
  middleFingerLine: "\ued09",
  mingcuteFill: "\ued0a",
  mingcuteLine: "\ued0b",
  minimizeFill: "\ued0c",
  minimizeLine: "\ued0d",
  miniplayerFill: "\ued0e",
  miniplayerLine: "\ued0f",
  minusCircleFill: "\ued10",
  minusCircleLine: "\ued11",
  miyajimaToriiFill: "\ued12",
  miyajimaToriiLine: "\ued13",
  moaiFill: "\ued14",
  moaiLine: "\ued15",
  momentFill: "\ued16",
  momentLine: "\ued17",
  monumentFill: "\ued18",
  monumentLine: "\ued19",
  moonCloudyFill: "\ued1a",
  moonCloudyLine: "\ued1b",
  moonFill: "\ued1c",
  moonLine: "\ued1d",
  moonStarsFill: "\ued1e",
  moonStarsLine: "\ued1f",
  more1Fill: "\ued20",
  more1Line: "\ued21",
  more2Fill: "\ued22",
  more2Line: "\ued23",
  more3Fill: "\ued24",
  more3Line: "\ued25",
  more4Fill: "\ued26",
  more4Line: "\ued27",
  mortarboardFill: "\ued28",
  mortarboardLine: "\ued29",
  mosaicFill: "\ued2a",
  mosaicLine: "\ued2b",
  mouseFill: "\ued2c",
  mouseLine: "\ued2d",
  mouthFill: "\ued2e",
  mouthLine: "\ued2f",
  moveFill: "\ued30",
  moveLine: "\ued31",
  movieFill: "\ued32",
  movieLine: "\ued33",
  music2Fill: "\ued34",
  music2Line: "\ued35",
  music3Fill: "\ued36",
  music3Line: "\ued37",
  musicFill: "\ued38",
  musicLine: "\ued39",
  navigationFill: "\ued3a",
  navigationLine: "\ued3b",
  necktieFill: "\ued3c",
  necktieLine: "\ued3d",
  newFolderFill: "\ued3e",
  newFolderLine: "\ued3f",
  newdotFill: "\ued40",
  newdotLine: "\ued41",
  newsFill: "\ued42",
  newsLine: "\ued43",
  noseFill: "\ued44",
  noseLine: "\ued45",
  notebook2Fill: "\ued46",
  notebook2Line: "\ued47",
  notebookFill: "\ued48",
  notebookLine: "\ued49",
  notificationFill: "\ued4a",
  notificationLine: "\ued4b",
  notificationOffFill: "\ued4c",
  notificationOffLine: "\ued4d",
  octagonFill: "\ued4e",
  octagonLine: "\ued4f",
  oilFill: "\ued50",
  oilLine: "\ued51",
  omegaFill: "\ued52",
  omegaLine: "\ued53",
  openDoorFill: "\ued54",
  openDoorLine: "\ued55",
  padFill: "\ued56",
  padLine: "\ued57",
  paint2Fill: "\ued58",
  paint2Line: "\ued59",
  paintBrushFill: "\ued5a",
  paintBrushLine: "\ued5b",
  paintFill: "\ued5c",
  paintLine: "\ued5d",
  palaceFill: "\ued5e",
  palaceLine: "\ued5f",
  palette2Fill: "\ued60",
  palette2Line: "\ued61",
  paletteFill: "\ued62",
  paletteLine: "\ued63",
  paperFill: "\ued64",
  paperLine: "\ued65",
  paragraphFill: "\ued66",
  paragraphLine: "\ued67",
  parenthesesFill: "\ued68",
  parenthesesLine: "\ued69",
  parkFill: "\ued6a",
  parkLine: "\ued6b",
  parkingFill: "\ued6c",
  parkingLightsFill: "\ued6d",
  parkingLightsLine: "\ued6e",
  parkingLine: "\ued6f",
  passportFill: "\ued70",
  passportLine: "\ued71",
  pasterFill: "\ued72",
  pasterLine: "\ued73",
  pauseCircleFill: "\ued74",
  pauseCircleLine: "\ued75",
  pauseFill: "\ued76",
  pauseLine: "\ued77",
  pavilionFill: "\ued78",
  pavilionLine: "\ued79",
  paypalFill: "\ued7a",
  paypalLine: "\ued7b",
  pdfFill: "\ued7c",
  pdfLine: "\ued7d",
  pen2Fill: "\ued7e",
  pen2Line: "\ued7f",
  penFill: "\ued80",
  penLine: "\ued81",
  pencil2Fill: "\ued82",
  pencil2Line: "\ued83",
  pencilFill: "\ued84",
  pencilLine: "\ued85",
  pentagonFill: "\ued86",
  pentagonLine: "\ued87",
  performanceFill: "\ued88",
  performanceLine: "\ued89",
  phoneAddFill: "\ued8a",
  phoneAddLine: "\ued8b",
  phoneBlockFill: "\ued8c",
  phoneBlockLine: "\ued8d",
  phoneCallFill: "\ued8e",
  phoneCallLine: "\ued8f",
  phoneFill: "\ued90",
  phoneIncomingFill: "\ued91",
  phoneIncomingLine: "\ued92",
  phoneLine: "\ued93",
  phoneOutgoingFill: "\ued94",
  phoneOutgoingLine: "\ued95",
  phoneSuccessFill: "\ued96",
  phoneSuccessLine: "\ued97",
  photoAlbum2Fill: "\ued98",
  photoAlbum2Line: "\ued99",
  photoAlbumFill: "\ued9a",
  photoAlbumLine: "\ued9b",
  pic2Fill: "\ued9c",
  pic2Line: "\ued9d",
  picFill: "\ued9e",
  picLine: "\ued9f",
  pigMoneyFill: "\ueda0",
  pigMoneyLine: "\ueda1",
  pin2Fill: "\ueda2",
  pin2Line: "\ueda3",
  pinFill: "\ueda4",
  pinLine: "\ueda5",
  pinterestFill: "\ueda6",
  pinterestLine: "\ueda7",
  pisaTowerFill: "\ueda8",
  pisaTowerLine: "\ueda9",
  planetFill: "\uedaa",
  planetLine: "\uedab",
  playCircleFill: "\uedac",
  playCircleLine: "\uedad",
  playFill: "\uedae",
  playLine: "\uedaf",
  plugin2Fill: "\uedb0",
  plugin2Line: "\uedb1",
  pluginFill: "\uedb2",
  pluginLine: "\uedb3",
  polkadotDotFill: "\uedb4",
  polkadotDotLine: "\uedb5",
  powerFill: "\uedb6",
  powerLine: "\uedb7",
  pptFill: "\uedb8",
  pptLine: "\uedb9",
  presentation1Fill: "\uedba",
  presentation1Line: "\uedbb",
  presentation2Fill: "\uedbc",
  presentation2Line: "\uedbd",
  printFill: "\uedbe",
  printLine: "\uedbf",
  profileFill: "\uedc0",
  profileLine: "\uedc1",
  qqFill: "\uedc2",
  qqLine: "\uedc3",
  qrcode2Fill: "\uedc4",
  qrcode2Line: "\uedc5",
  qrcodeFill: "\uedc6",
  qrcodeLine: "\uedc7",
  questionFill: "\uedc8",
  questionLine: "\uedc9",
  quillPenFill: "\uedca",
  quillPenLine: "\uedcb",
  quoteLeftFill: "\uedcc",
  quoteLeftLine: "\uedcd",
  quoteRightFill: "\uedce",
  quoteRightLine: "\uedcf",
  radar2Fill: "\uedd0",
  radar2Line: "\uedd1",
  radarFill: "\uedd2",
  radarLine: "\uedd3",
  radioFill: "\uedd4",
  radioLine: "\uedd5",
  rainFill: "\uedd6",
  rainLine: "\uedd7",
  rareFogLightsFill: "\uedd8",
  rareFogLightsLine: "\uedd9",
  reactFill: "\uedda",
  reactLine: "\ueddb",
  rearWindshieldDefrosterFill: "\ueddc",
  rearWindshieldDefrosterLine: "\ueddd",
  receiveMoneyFill: "\uedde",
  receiveMoneyLine: "\ueddf",
  recordMailFill: "\uede0",
  recordMailLine: "\uede1",
  redPacketFill: "\uede2",
  redPacketLine: "\uede3",
  redPacketOpenFill: "\uede4",
  redPacketOpenLine: "\uede5",
  redditFill: "\uede6",
  redditLine: "\uede7",
  refresh1Fill: "\uede8",
  refresh1Line: "\uede9",
  refresh2Fill: "\uedea",
  refresh2Line: "\uedeb",
  remoteFill: "\uedec",
  remoteLine: "\ueded",
  repeatFill: "\uedee",
  repeatLine: "\uedef",
  repeatOneFill: "\uedf0",
  repeatOneLine: "\uedf1",
  restAreaFill: "\uedf2",
  restAreaLine: "\uedf3",
  restoreFill: "\uedf4",
  restoreLine: "\uedf5",
  ridingFill: "\uedf6",
  ridingLine: "\uedf7",
  rightFill: "\uedf8",
  rightLine: "\uedf9",
  roadFill: "\uedfa",
  roadLine: "\uedfb",
  rocket2Fill: "\uedfc",
  rocket2Line: "\uedfd",
  rocketFill: "\uedfe",
  rocketLine: "\uedff",
  rotateToHorizontalFill: "\uee00",
  rotateToHorizontalLine: "\uee01",
  rotateToVerticalFill: "\uee02",
  rotateToVerticalLine: "\uee03",
  roundFill: "\uee04",
  roundLine: "\uee05",
  routeFill: "\uee06",
  routeLine: "\uee07",
  routerModemFill: "\uee08",
  routerModemLine: "\uee09",
  rows2Fill: "\uee0a",
  rows2Line: "\uee0b",
  rows3Fill: "\uee0c",
  rows3Line: "\uee0d",
  rows4Fill: "\uee0e",
  rows4Line: "\uee0f",
  rulerFill: "\uee10",
  rulerLine: "\uee11",
  runFill: "\uee12",
  runLine: "\uee13",
  safeAlertFill: "\uee14",
  safeAlertLine: "\uee15",
  safeBoxFill: "\uee16",
  safeBoxLine: "\uee17",
  safeFlashFill: "\uee18",
  safeFlashLine: "\uee19",
  safeLockFill: "\uee1a",
  safeLockLine: "\uee1b",
  safeShield2Fill: "\uee1c",
  safeShield2Line: "\uee1d",
  safeShieldFill: "\uee1e",
  safeShieldLine: "\uee1f",
  safetyCertificateFill: "\uee20",
  safetyCertificateLine: "\uee21",
  saleFill: "\uee22",
  saleLine: "\uee23",
  sandglassFill: "\uee24",
  sandglassLine: "\uee25",
  save2Fill: "\uee26",
  save2Line: "\uee27",
  saveFill: "\uee28",
  saveLine: "\uee29",
  scaleFill: "\uee2a",
  scaleLine: "\uee2b",
  scan2Fill: "\uee2c",
  scan2Line: "\uee2d",
  scanFill: "\uee2e",
  scanLine: "\uee2f",
  scarfFill: "\uee30",
  scarfLine: "\uee31",
  scheduleFill: "\uee32",
  scheduleLine: "\uee33",
  schoolFill: "\uee34",
  schoolLine: "\uee35",
  scienceFill: "\uee36",
  scienceLine: "\uee37",
  scissors2Fill: "\uee38",
  scissors2Line: "\uee39",
  scissors3Fill: "\uee3a",
  scissors3Line: "\uee3b",
  scissorsFill: "\uee3c",
  scissorsLine: "\uee3d",
  scooterFill: "\uee3e",
  scooterLine: "\uee3f",
  screenshotFill: "\uee40",
  screenshotLine: "\uee41",
  sealFill: "\uee42",
  sealLine: "\uee43",
  search2Fill: "\uee44",
  search2Line: "\uee45",
  search3Fill: "\uee46",
  search3Line: "\uee47",
  searchFill: "\uee48",
  searchLine: "\uee49",
  seatFill: "\uee4a",
  seatHeatedFill: "\uee4b",
  seatHeatedLine: "\uee4c",
  seatLine: "\uee4d",
  sendPlaneFill: "\uee4e",
  sendPlaneLine: "\uee4f",
  server2Fill: "\uee50",
  server2Line: "\uee51",
  serverFill: "\uee52",
  serverLine: "\uee53",
  serviceFill: "\uee54",
  serviceLine: "\uee55",
  settings1Fill: "\uee56",
  settings1Line: "\uee57",
  settings2Fill: "\uee58",
  settings2Line: "\uee59",
  settings3Fill: "\uee5a",
  settings3Line: "\uee5b",
  settings4Fill: "\uee5c",
  settings4Line: "\uee5d",
  settings5Fill: "\uee5e",
  settings5Line: "\uee5f",
  share2Fill: "\uee60",
  share2Line: "\uee61",
  share3Fill: "\uee62",
  share3Line: "\uee63",
  shareForwardFill: "\uee64",
  shareForwardLine: "\uee65",
  shieldFill: "\uee66",
  shieldLine: "\uee67",
  shieldShapeFill: "\uee68",
  shieldShapeLine: "\uee69",
  shipFill: "\uee6a",
  shipLine: "\uee6b",
  shirtFill: "\uee6c",
  shirtLine: "\uee6d",
  shoe2Fill: "\uee6e",
  shoe2Line: "\uee6f",
  shoeFill: "\uee70",
  shoeLine: "\uee71",
  shoppingBag1Fill: "\uee72",
  shoppingBag1Line: "\uee73",
  shoppingBag2Fill: "\uee74",
  shoppingBag2Line: "\uee75",
  shoppingBag3Fill: "\uee76",
  shoppingBag3Line: "\uee77",
  shoppingCart1Fill: "\uee78",
  shoppingCart1Line: "\uee79",
  shoppingCart2Fill: "\uee7a",
  shoppingCart2Line: "\uee7b",
  shortsFill: "\uee7c",
  shortsLine: "\uee7d",
  shuffleFill: "\uee7e",
  shuffleLine: "\uee7f",
  skipForwardFill: "\uee80",
  skipForwardLine: "\uee81",
  skipPreviousFill: "\uee82",
  skipPreviousLine: "\uee83",
  skirtFill: "\uee84",
  skirtLine: "\uee85",
  snapchatFill: "\uee86",
  snapchatLine: "\uee87",
  snowFill: "\uee88",
  snowLine: "\uee89",
  sockFill: "\uee8a",
  sockLine: "\uee8b",
  solanaSolFill: "\uee8c",
  solanaSolLine: "\uee8d",
  sortAscendingFill: "\uee8e",
  sortAscendingLine: "\uee8f",
  sortDescendingFill: "\uee90",
  sortDescendingLine: "\uee91",
  spacingHorizontalFill: "\uee92",
  spacingHorizontalLine: "\uee93",
  spacingVerticalFill: "\uee94",
  spacingVerticalLine: "\uee95",
  sparklesFill: "\uee96",
  sparklesLine: "\uee97",
  speakerFill: "\uee98",
  speakerLine: "\uee99",
  spotifyFill: "\uee9a",
  spotifyLine: "\uee9b",
  squareFill: "\uee9c",
  squareLine: "\uee9d",
  starFill: "\uee9e",
  starLine: "\uee9f",
  statueOfLibertyFill: "\ueea0",
  statueOfLibertyLine: "\ueea1",
  steeringWheelFill: "\ueea2",
  steeringWheelLine: "\ueea3",
  stockFill: "\ueea4",
  stockLine: "\ueea5",
  stopCircleFill: "\ueea6",
  stopCircleLine: "\ueea7",
  stopFill: "\ueea8",
  stopLine: "\ueea9",
  stopwatchFill: "\ueeaa",
  stopwatchLine: "\ueeab",
  store2Fill: "\ueeac",
  store2Line: "\ueead",
  storeFill: "\ueeae",
  storeLine: "\ueeaf",
  strikethroughFill: "\ueeb0",
  strikethroughLine: "\ueeb1",
  stripeFill: "\ueeb2",
  stripeLine: "\ueeb3",
  sunCloudyFill: "\ueeb4",
  sunCloudyLine: "\ueeb5",
  sunFill: "\ueeb6",
  sunLine: "\ueeb7",
  sunriseFill: "\ueeb8",
  sunriseLine: "\ueeb9",
  sunsetFill: "\ueeba",
  sunsetLine: "\ueebb",
  switchFill: "\ueebc",
  switchLine: "\ueebd",
  sydneyOperaHouseFill: "\ueebe",
  sydneyOperaHouseLine: "\ueebf",
  tShirt2Fill: "\ueec0",
  tShirt2Line: "\ueec1",
  tShirtFill: "\ueec2",
  tShirtLine: "\ueec3",
  table2Fill: "\ueec4",
  table2Line: "\ueec5",
  tableFill: "\ueec6",
  tableLine: "\ueec7",
  tag2Fill: "\ueec8",
  tag2Line: "\ueec9",
  tagFill: "\ueeca",
  tagLine: "\ueecb",
  taipei101Fill: "\ueecc",
  taipei101Line: "\ueecd",
  tajMahalFill: "\ueece",
  tajMahalLine: "\ueecf",
  targetFill: "\ueed0",
  targetLine: "\ueed1",
  teacupFill: "\ueed2",
  teacupLine: "\ueed3",
  telegramFill: "\ueed4",
  telegramLine: "\ueed5",
  telescopeFill: "\ueed6",
  telescopeLine: "\ueed7",
  templeOfHeavenFill: "\ueed8",
  templeOfHeavenLine: "\ueed9",
  terminalBoxFill: "\ueeda",
  terminalBoxLine: "\ueedb",
  terminalFill: "\ueedc",
  terminalLine: "\ueedd",
  tetherUsdtFill: "\ueede",
  tetherUsdtLine: "\ueedf",
  textFill: "\ueee0",
  textLine: "\ueee1",
  thermometerFill: "\ueee2",
  thermometerLine: "\ueee3",
  thumbDown2Fill: "\ueee4",
  thumbDown2Line: "\ueee5",
  thumbDownFill: "\ueee6",
  thumbDownLine: "\ueee7",
  thumbUp2Fill: "\ueee8",
  thumbUp2Line: "\ueee9",
  thumbUpFill: "\ueeea",
  thumbUpLine: "\ueeeb",
  tiktokFill: "\ueeec",
  tiktokLine: "\ueeed",
  timeFill: "\ueeee",
  timeLine: "\ueeef",
  toggleLeft2Fill: "\ueef0",
  toggleLeft2Line: "\ueef1",
  toggleLeftFill: "\ueef2",
  toggleLeftLine: "\ueef3",
  toggleRight2Fill: "\ueef4",
  toggleRight2Line: "\ueef5",
  toggleRightFill: "\ueef6",
  toggleRightLine: "\ueef7",
  toolFill: "\ueef8",
  toolLine: "\ueef9",
  towerFill: "\ueefa",
  towerLine: "\ueefb",
  trafficConeFill: "\ueefc",
  trafficConeLine: "\ueefd",
  trafficLightsFill: "\ueefe",
  trafficLightsLine: "\ueeff",
  train2Fill: "\uef00",
  train2Line: "\uef01",
  train3Fill: "\uef02",
  train3Line: "\uef03",
  trainFill: "\uef04",
  trainLine: "\uef05",
  transfer2Fill: "\uef06",
  transfer2Line: "\uef07",
  transfer3Fill: "\uef08",
  transfer3Line: "\uef09",
  transfer4Fill: "\uef0a",
  transfer4Line: "\uef0b",
  transferFill: "\uef0c",
  transferLine: "\uef0d",
  transformationFill: "\uef0e",
  transformationLine: "\uef0f",
  translateFill: "\uef10",
  translateLine: "\uef11",
  trelloBoardFill: "\uef12",
  trelloBoardLine: "\uef13",
  triangleFill: "\uef14",
  triangleLine: "\uef15",
  triumphalArchFill: "\uef16",
  triumphalArchLine: "\uef17",
  trophyFill: "\uef18",
  trophyLine: "\uef19",
  trouserFill: "\uef1a",
  trouserLine: "\uef1b",
  truckFill: "\uef1c",
  truckLine: "\uef1d",
  tv1Fill: "\uef1e",
  tv1Line: "\uef1f",
  tv2Fill: "\uef20",
  tv2Line: "\uef21",
  tvToweFill: "\uef22",
  tvToweLine: "\uef23",
  twitterFill: "\uef24",
  twitterLine: "\uef25",
  typhoonFill: "\uef26",
  typhoonLine: "\uef27",
  tyreFill: "\uef28",
  tyreLine: "\uef29",
  ufoFill: "\uef2a",
  ufoLine: "\uef2b",
  umbrella2Fill: "\uef2c",
  umbrella2Line: "\uef2d",
  umbrellaFill: "\uef2e",
  umbrellaLine: "\uef2f",
  underlineFill: "\uef30",
  underlineLine: "\uef31",
  unlink2Fill: "\uef32",
  unlink2Line: "\uef33",
  unlinkFill: "\uef34",
  unlinkLine: "\uef35",
  unlockFill: "\uef36",
  unlockLine: "\uef37",
  upFill: "\uef38",
  upLine: "\uef39",
  upload2Fill: "\uef3a",
  upload2Line: "\uef3b",
  upload3Fill: "\uef3c",
  upload3Line: "\uef3d",
  uploadFill: "\uef3e",
  uploadLine: "\uef3f",
  usbFill: "\uef40",
  usbFlashDiskFill: "\uef41",
  usbFlashDiskLine: "\uef42",
  usbLine: "\uef43",
  usdCoinUsdcFill: "\uef44",
  usdCoinUsdcLine: "\uef45",
  user1Fill: "\uef46",
  user1Line: "\uef47",
  user2Fill: "\uef48",
  user2Line: "\uef49",
  user3Fill: "\uef4a",
  user3Line: "\uef4b",
  user4Fill: "\uef4c",
  user4Line: "\uef4d",
  user5Fill: "\uef4e",
  user5Line: "\uef4f",
  userAddFill: "\uef50",
  userAddLine: "\uef51",
  userFollowFill: "\uef52",
  userFollowLine: "\uef53",
  userRemoveFill: "\uef54",
  userRemoveLine: "\uef55",
  vectorBezier2Fill: "\uef56",
  vectorBezier2Line: "\uef57",
  vectorBezier3Fill: "\uef58",
  vectorBezier3Line: "\uef59",
  vectorBezierFill: "\uef5a",
  vectorBezierLine: "\uef5b",
  versionFill: "\uef5c",
  versionLine: "\uef5d",
  vestFill: "\uef5e",
  vestLine: "\uef5f",
  videoFill: "\uef60",
  videoLine: "\uef61",
  vip1Fill: "\uef62",
  vip1Line: "\uef63",
  vip2Fill: "\uef64",
  vip2Line: "\uef65",
  vip3Fill: "\uef66",
  vip3Line: "\uef67",
  vip4Fill: "\uef68",
  vip4Line: "\uef69",
  visaFill: "\uef6a",
  visaLine: "\uef6b",
  vkontakteFill: "\uef6c",
  vkontakteLine: "\uef6d",
  voiceFill: "\uef6e",
  voiceLine: "\uef6f",
  volumeFill: "\uef70",
  volumeLine: "\uef71",
  volumeMuteFill: "\uef72",
  volumeMuteLine: "\uef73",
  volumeOffFill: "\uef74",
  volumeOffLine: "\uef75",
  vueFill: "\uef76",
  vueLine: "\uef77",
  walkFill: "\uef78",
  walkLine: "\uef79",
  wallet2Fill: "\uef7a",
  wallet2Line: "\uef7b",
  wallet3Fill: "\uef7c",
  wallet3Line: "\uef7d",
  wallet4Fill: "\uef7e",
  wallet4Line: "\uef7f",
  wallet5Fill: "\uef80",
  wallet5Line: "\uef81",
  walletFill: "\uef82",
  walletLine: "\uef83",
  warningFill: "\uef84",
  warningLine: "\uef85",
  wastebasketFill: "\uef86",
  wastebasketLine: "\uef87",
  watchFill: "\uef88",
  watchLine: "\uef89",
  webFill: "\uef8a",
  webLine: "\uef8b",
  wechatFill: "\uef8c",
  wechatLine: "\uef8d",
  weiboFill: "\uef8e",
  weiboLine: "\uef8f",
  whatsappFill: "\uef90",
  whatsappLine: "\uef91",
  wheelFill: "\uef92",
  wheelLine: "\uef93",
  wifiFill: "\uef94",
  wifiLine: "\uef95",
  wifiOffFill: "\uef96",
  wifiOffLine: "\uef97",
  windFill: "\uef98",
  windLine: "\uef99",
  windowsFill: "\uef9a",
  windowsLine: "\uef9b",
  wineglass2Fill: "\uef9c",
  wineglass2Line: "\uef9d",
  wineglassFill: "\uef9e",
  wineglassLine: "\uef9f",
  wiperFill: "\uefa0",
  wiperLine: "\uefa1",
  world2Fill: "\uefa2",
  world2Line: "\uefa3",
  worldFill: "\uefa4",
  worldLine: "\uefa5",
  xlsFill: "\uefa6",
  xlsLine: "\uefa7",
  xrpFill: "\uefa8",
  xrpLine: "\uefa9",
  youtubeFill: "\uefaa",
  youtubeLine: "\uefab",
  zaSortDescendingLettersFill: "\uefac",
  zaSortDescendingLettersLine: "\uefad",
  zoomInFill: "\uefae",
  zoomInLine: "\uefaf",
  zoomOutFill: "\uefb0",
  zoomOutLine: "\uefb1",
}
export default icons;