import { Container, FlexBox, Row } from "components/studium_ui";
import Card from "components/cards/card";
import { createUseStyles, useTheme } from "react-jss";
import * as styles from "utils/styles";
import BreadCrumbs from "components/breadcrumbs/breadcrumbs";
import Grid from "components/studium_ui/grid";
import React, { useContext, useEffect } from "react";
import { LanguageContext } from "utils/itn";
import { itns } from "pages/libraries/texts/libraries.itn";
import { className } from "utils/className";

export default function Libraries() {
	const theme = useTheme();
	const classes = useStyles({ theme });
	const language = useContext(LanguageContext);
	const itn = itns[language];
	const [firstRender, setFirstRender] = React.useState(true);

	useEffect(() => {
		setTimeout(() => {
			setFirstRender(false);
		}, 10);
	}, []);

	return (
		<FlexBox
			className={className([
				classes.container,
				!firstRender ? classes.containerInitialized : null,
			])}
			column
			gap={styles.size(1)}>
			<BreadCrumbs items={[{ text: itn.breadcrumbs.libraries }]} />
			<FlexBox column gap={12} crossAxisAlignment="stretch">
				<Grid>
					<a
						className={classes.anchor}
						href="https://www.bibliotecadigitalsenac.com.br/#/"
						target="_blank"
						rel="noreferrer">
						<Card className={classes.card} flex={1}>
							<span className={classes.title}>
								Biblioteca Digital Senac
							</span>
						</Card>
					</a>
					<a
						className={classes.anchor}
						href="http://cvc.instituto-camoes.pt/conhecer/biblioteca-digital-camoes.html"
						target="_blank"
						rel="noreferrer">
						<Card className={classes.card} flex={1}>
							<span className={classes.title}>
								Biblioteca Digital Camões
							</span>
						</Card>
					</a>
					<a
						className={classes.anchor}
						href="https://bndigital.bn.gov.br"
						target="_blank"
						rel="noreferrer">
						<Card className={classes.card} flex={1}>
							<span className={classes.title}>
								Biblioteca Nacional Digital
							</span>
						</Card>
					</a>
					<a
						className={classes.anchor}
						href="https://www.culturaacademica.com.br"
						target="_blank"
						rel="noreferrer">
						<Card className={classes.card} flex={1}>
							<span className={classes.title}>
								Cultura Acadêmica
							</span>
						</Card>
					</a>
					<a
						className={classes.anchor}
						href="https://openlibrary.org"
						target="_blank"
						rel="noreferrer">
						<Card className={classes.card} flex={1}>
							<span className={classes.title}>Open Library</span>
						</Card>
					</a>
					<a
						className={classes.anchor}
						href="http://www2.fe.usp.br:8080/livres/"
						target="_blank"
						rel="noreferrer">
						<Card className={classes.card} flex={1}>
							<span className={classes.title}>
								Banco de Dados de Livros Escolares Brasileiros
							</span>
						</Card>
					</a>
					<a
						className={classes.anchor}
						href="https://www.bibliotecadigital.unicamp.br/bd/"
						target="_blank"
						rel="noreferrer">
						<Card className={classes.card} flex={1}>
							<span className={classes.title}>
								Biblioteca Digital da Unicamp
							</span>
						</Card>
					</a>
					<a
						className={classes.anchor}
						href="https://bibdig.biblioteca.unesp.br/home"
						target="_blank"
						rel="noreferrer">
						<Card className={classes.card} flex={1}>
							<span className={classes.title}>
								Biblioteca Digital Unesp
							</span>
						</Card>
					</a>
				</Grid>
			</FlexBox>
		</FlexBox>
	);
}

const useStyles = createUseStyles({
	container: {
		// transition: "all 0.2s ease-in-out",
		// transform: "translateY(.5%) scale(0.995)",
		// opacity: 0.95,
	},
	containerInitialized: {
		// transform: "translateY(0%) scale(1)",
		// opacity: 1,
	},
	card: {
		minHeight: styles.size(20),
		cursor: "pointer",
	},
	anchor: {
		textDecoration: "none",
		flex: 1,
	},
	title: {
		color: ({ theme }: any) => theme.foreground,
		textDecoration: "none",
		fontWeight: "bold",
		fontSize: styles.size(2),
	},
	content: {
		backgroundColor: ({ theme }: any) => theme.background,
		paddingInline: styles.size(1),
		paddingBlock: styles.size(1.5),
		height: "calc(100vh - 60px)",
		overflowY: "scroll",
	},
});
