// region imports
import {useContext, useEffect, useMemo} from "react";
import {Outlet,  useSearchParams} from "react-router-dom";
import {observer} from "mobx-react";
import ProfessorViewModel from "../../view_models/professors/professor_view_model";
import {StoreContext} from "../../store";
// endregion

// region ProfessorsView
// this page is the home, the professors here can see every feature available for them.
const ProfessorView = observer(({viewModel} : { viewModel: ProfessorViewModel }) => {
    // region render
    return (
        <Outlet context={viewModel} />
    )
    // endregion
});
// endregion

// region export
export default function Professor() {
    // const notificationsContext = useNotifications()
    const store = useContext(StoreContext);
    const [searchParams] = useSearchParams()
    const viewModel = useMemo(() =>
            new ProfessorViewModel(store, searchParams),
        [searchParams, store]);
    useEffect(() => {
        viewModel.onInitialized();
    }, [viewModel]);
    useEffect(() => {

        viewModel.handleSearchParams(searchParams);
    }, [searchParams, viewModel]);
    return <ProfessorView viewModel={viewModel} />
}
// endregion