import {FlexBox} from "../../components/studium_ui";
import BreadCrumbs from "../../components/breadcrumbs/breadcrumbs";
import * as styles from "../../utils/styles";
import { useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import React, {useContext, useEffect, useMemo} from "react";
import {StoreContext} from "../../store";
import {useNotifications} from "reapop";
import {useItn} from "../../utils/itn";
import {observer} from "mobx-react";
import SubjectsDetailsViewModel from "../../view_models/subjects/subjects_details_view_model";
import {itns} from "./texts/subjects.itn";
import Card from "../../components/cards/card";
import Button from "../../components/button/button";
import TabView from "../../components/studium_ui/tab_view";
import { createUseStyles, useTheme } from "react-jss";
import Grid from "../../components/studium_ui/grid";
import { className } from "../../utils/className";
import Icon from "../../components/icon/icon";
import icons from "../../utils/icons";
import StudentViewModel from "../../view_models/students/student_view_model";

// region SubjectsDetailsView
const SubjectsDetailsView = observer(({viewModel} : {viewModel: StudentViewModel}) => {
    const theme = useTheme();
    // @ts-ignore
    const classes = useStyles({theme});
    const { itn } = useItn(itns);
    const navigate = useNavigate();
    return (
        <FlexBox column gap={styles.size(1)} flex={1}>
            <BreadCrumbs
                items={[
                    {
                        text: itn.breadcrumbs.subjects,
                        route: "/students",
                    },
                    {
                        text: viewModel.subjectSelected?.name,
                    },
                ]} />
            <Card
                flex={1}
                style={{ padding: `${styles.size(1)} ${styles.size(1)}`, paddingInline: styles.size(2) }}>
                <FlexBox mainAxisAlignment="space-between" crossAxisAlignment="center">
                    <FlexBox column>
                        <h1 style={{ fontSize: styles.size(1.5) }}><strong>{viewModel.subjectSelected?.name}</strong></h1>
                        <h5 style={{opacity: 0.8, fontSize: 14}}>{viewModel.subjectSelected?.description}</h5>
                    </FlexBox>
                    <Button style={{width: styles.size(10), height: styles.size(3), margin: 0}} onClick={() => navigate(`/students/content`)}>{itn.details.buttons.content}</Button>
                </FlexBox>
            </Card>

            <Card style={{flexGrow: 1}}>
                <FlexBox column gap={styles.size(1)}>
                    <TabView
                        items={[
                            {
                                id: 1,
                                text: itn.details.tabView.aboutTheSubject,
                            },
                            {
                                id: 2,
                                text: itn.details.tabView.calendar,
                            },
                            {
                                id: 3,
                                text: itn.details.tabView.discussions,
                            },
                            {
                                id: 4,
                                text: itn.details.tabView.gradeBook,
                            },
                            {
                                id: 5,
                                text: itn.details.tabView.messages,
                            },
                            {
                                id: 6,
                                text: itn.details.tabView.announcements,
                            },
                            {
                                id: 7,
                                text: itn.details.tabView.analytics,
                            },
                        ]} />
                    <FlexBox gap={styles.size(2)} className={classes.courseFacultyTitle}>
                        <h5 className={classes.title}>{itn.details.aboutTheSubject.courseFaculty}</h5>
                    </FlexBox>
                    <Grid>
                        {
                            viewModel.professorsAgreements ?
                            viewModel.professorsAgreements.map((professor) => (
                            <FlexBox
                                key={professor.id}
                                className={className([classes.eventsBox, classes.courseFacultyBox])}
                                mainAxisAlignment="space-between"
                                crossAxisAlignment="center">
                                <FlexBox gap={styles.size(1)} crossAxisAlignment="center">
                                    <img src={`http://static.studium.academy/${professor.userPhoto}`} alt="profile" style={{width: styles.size(2), height: styles.size(2), borderRadius: "50%"}} />
                                    {professor.userName}
                                </FlexBox>
                                <svg width="18" height="4" viewBox="0 0 18 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="2" cy="2" r="2" fill="#D9D9D9"/>
                                    <circle cx="9" cy="2" r="2" fill="#D9D9D9"/>
                                    <circle cx="16" cy="2" r="2" fill="#D9D9D9"/>
                                </svg>
                            </FlexBox>
                            )) : null
                        }
                        {/*<FlexBox*/}
                        {/*    className={className([classes.eventsBox, classes.courseFacultyBox])}*/}
                        {/*    mainAxisAlignment="space-between"*/}
                        {/*    crossAxisAlignment="center">*/}
                        {/*    Minerva McGonagall*/}
                        {/*    <svg width="18" height="4" viewBox="0 0 18 4" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                        {/*        <circle cx="2" cy="2" r="2" fill="#D9D9D9"/>*/}
                        {/*        <circle cx="9" cy="2" r="2" fill="#D9D9D9"/>*/}
                        {/*        <circle cx="16" cy="2" r="2" fill="#D9D9D9"/>*/}
                        {/*    </svg>*/}
                        {/*</FlexBox>*/}
                    </Grid>
                <div className={classes.divider} />
                    <Grid>
                        <FlexBox
                            className={className([classes.eventsBox, classes.aboutTheSubjectCard])}
                            style={{height: styles.size(15)}}
                            mainAxisAlignment="start"
                            gap={styles.size(1)}
                            crossAxisAlignment="center">
                            <Icon name={icons.listCheckFill} size={styles.size(3)} />
                            {itn.details.aboutTheSubject.classRoster}
                        </FlexBox>
                        <FlexBox
                            className={className([classes.eventsBox, classes.aboutTheSubjectCard])}
                            style={{height: styles.size(15)}}
                            mainAxisAlignment="start"
                            gap={styles.size(1)}
                            crossAxisAlignment="center">
                            <Icon name={icons.textLine} size={styles.size(3)} />
                            {itn.details.aboutTheSubject.courseDescription}
                        </FlexBox>
                        <FlexBox
                            className={className([classes.eventsBox, classes.aboutTheSubjectCard])}
                            style={{height: styles.size(15)}}
                            mainAxisAlignment="start"
                            gap={styles.size(1)}
                            crossAxisAlignment="center">
                            <Icon name={icons.groupLine} size={styles.size(3)} />
                            {itn.details.aboutTheSubject.courseGroups}
                        </FlexBox>
                        <FlexBox
                            className={className([classes.eventsBox, classes.aboutTheSubjectCard])}
                            style={{height: styles.size(15)}}
                            mainAxisAlignment="start"
                            gap={styles.size(1)}
                            crossAxisAlignment="center">
                            <Icon name={icons.briefcaseLine} size={styles.size(3)} />
                            {itn.details.aboutTheSubject.courseMaterials}
                        </FlexBox>
                        <FlexBox
                            className={className([classes.eventsBox, classes.aboutTheSubjectCard])}
                            style={{height: styles.size(15)}}
                            mainAxisAlignment="start"
                            gap={styles.size(1)}
                            crossAxisAlignment="center">
                            <Icon name={icons.questionLine} size={styles.size(3)} />
                            {itn.details.aboutTheSubject.questionBanks}
                        </FlexBox>
                        <FlexBox
                            className={className([classes.eventsBox, classes.aboutTheSubjectCard])}
                            style={{height: styles.size(15)}}
                            mainAxisAlignment="start"
                            gap={styles.size(1)}
                            crossAxisAlignment="center">
                            <Icon name={icons.settings1Line} size={styles.size(3)} />
                            {itn.details.aboutTheSubject.courseSettings}
                        </FlexBox>
                        <FlexBox
                            className={className([classes.eventsBox, classes.aboutTheSubjectCard])}
                            style={{height: styles.size(15)}}
                            mainAxisAlignment="start"
                            gap={styles.size(1)}
                            crossAxisAlignment="center">
                            <Icon name={icons.chartBarLine} size={styles.size(3)} />
                            {itn.details.aboutTheSubject.courseStatistics}
                        </FlexBox>
                        <FlexBox
                            className={className([classes.eventsBox, classes.aboutTheSubjectCard])}
                            style={{height: styles.size(15)}}
                            mainAxisAlignment="start"
                            gap={styles.size(1)}
                            crossAxisAlignment="center">
                            <Icon name={icons.toolLine} size={styles.size(3)} />
                            {itn.details.aboutTheSubject.courseTools}
                        </FlexBox>
                    </Grid>
                </FlexBox>
            </Card>
        </FlexBox>
    );
});
// endregion

const useStyles = createUseStyles({
    title: {
        fontWeight: 500,
        color: ({theme}: any) => theme.foreground,
        fontSize: styles.size(1.25),
        margin: 0,
    },
    eventsBox: {
        border: ({theme}: any) => `1px solid ${theme.elementBackgroundSecondary}`,
        borderRadius: styles.size(0.75),
        fontSize: styles.size(1),
        color: ({theme}: any) => theme.foregroundSecondary,
        height: styles.size(3),
        paddingInline: styles.size(1),
        cursor: "pointer",
    },
    courseFacultyTitle: {
        marginTop: styles.size(2),
    },
    courseFacultyBox: {
        backgroundColor: ({theme}: any) => theme.backgroundSemiAlt,
    },
    aboutTheSubjectCard: {
        backgroundColor: ({theme}: any) => theme.backgroundSemiAlt,
        fontSize: styles.size(1.5),
        paddingInline: styles.size(3),
        transition: "all 0.2s ease-in-out",
        "&:hover": {
            backgroundColor: ({theme}: any) => styles.blend(theme.backgroundSemiAlt, theme.background, .1),
            boxShadow: ({theme}: any) => `0px 0px 5px 2.5px rgba(0,0, 0, 0.1)`,
            transform: "translateY(-2px) scale(1.01)",
        },
        "&:active": {
            backgroundColor: ({theme}: any) => styles.blend(theme.backgroundSemiAlt, theme.background, .3),
        }
    },
    divider: {
        height: 1,
        width: "100%",
        backgroundColor: ({theme}: any) => theme.elementBackgroundSecondary,
    },
});

// region export
export default function SubjectsDetails(): JSX.Element {
    const viewModel: StudentViewModel = useOutletContext();
    return <SubjectsDetailsView viewModel={viewModel} />;
}
// endregion

// region export
// export default function Subjects() {
//     const store = useContext(StoreContext);
//     // const location = useLocation();
//     const [searchParams] = useSearchParams();
//     const {notify} = useNotifications()
//     // const language = useContext(LanguageContext);
//     // const itn = itns[language];
//
//     const viewModel = useMemo(() => new SubjectsDetailsViewModel(), []);
//
//     // viewModel.notification = (notification, status) => notify(t("auth.notFound"), status);
//
//     useEffect(() => {
//         viewModel.onInitialized(store, searchParams);
//     }, [store, viewModel])
//
//     return <SubjectsDetailsView viewModel={viewModel}/>
// }
// endregion