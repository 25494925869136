// region imports
import {createUseStyles, useTheme} from "react-jss";
import * as styles from "../../../utils/styles";
import Button from "../../../components/button/button";
import {FlexBox} from "../../../components/studium_ui";
import DropZone from "../../../components/drop_zone/drop_zone";
import ProfileViewModel from "../../../view_models/users/profile_view_model";
import {useContext, useRef} from "react";
import {useOutsideEvent} from "../../../utils/useOutsideEvent";
import ProfessorViewModel from "../../../view_models/professors/professor_view_model";
import {LanguageContext} from "../../../utils/itn";
import {itns} from "../texts/professor.itn";
// endregion

// region UploadFileModal
export default function UserModal({viewModel}: { viewModel: ProfessorViewModel }) {
    // region properties
    const theme: any = useTheme();
    const classes = useStyles({theme});
    const wrapperRef = useRef(null);
    const language = useContext(LanguageContext);
    const itn = itns[language];
    useOutsideEvent(wrapperRef, () => viewModel.showUserModal = false);

    const items = [
        {"text": itn.classroom.students.modal.sendNoticeToStudent, "icon": "send", "onClick": () => viewModel.showUserModal = false},
        {"text": itn.classroom.students.modal.sendNoticeToParents, "icon": "send", "onClick": () => viewModel.showUserModal = false},
        {"text": itn.classroom.students.modal.viewGrades, "icon": "send", "onClick": () => viewModel.showUserModal = false},
        {"text": itn.classroom.students.modal.viewAbsences, "icon": "send", "onClick": () => viewModel.showUserModal = false},
    ]
    // endregion

    // region render
    return (
        <div
            className={classes.background}
            tabIndex={-1}
        >
            <FlexBox
                column
                ref={wrapperRef}
                className={classes.container}>
                <FlexBox
                    style={{ marginBottom: styles.size(2) }}
                    crossAxisAlignment="center"
                    mainAxisAlignment="space-between">
                    <h2 className={classes.title}>{viewModel.studentSelected?.name}</h2>
                    <div
                        style={{ cursor: "pointer" }}
                        onClick={() => viewModel.showUserModal = false}>
                        <svg
                            width="19"
                            height="18"
                            viewBox="0 0 19 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M10.2072 8.79289L18.0001 1L17.293 0.292892L9.50012 8.08579L1.70723 0.292892L1.00012 1L8.79301 8.79289L0.293015 17.2929L1.00012 18L9.50012 9.5L18.0001 18L18.7072 17.2929L10.2072 8.79289Z"
                                fill={theme.foreground}/>
                        </svg>
                    </div>
                </FlexBox>
                <FlexBox column gap={styles.size(5)}>
                    <FlexBox column crossAxisAlignment="center">
                        {viewModel.studentSelected?.photo ? <img src={`http://static.studium.academy/${viewModel.studentSelected.photo}`} alt="profile" className={classes.photo}/> : <div className={classes.photo}/>}
                    </FlexBox>
                        <FlexBox
                            flex={1}
                            column
                            gap={styles.size(1)}
                            mainAxisAlignment="start"
                            crossAxisAlignment="stretch">
                            {items.map((item, index) => (
                            <FlexBox
                                key={index}
                                className={classes.item}
                                gap={styles.size(1)}
                                mainAxisAlignment="center"
                                crossAxisAlignment="center">
                                <FlexBox
                                    flex={1}
                                    gap={styles.size(1)}
                                    className={classes.eventsBox}
                                    mainAxisAlignment="start"
                                    crossAxisAlignment="center">
                                    {item.text}
                                </FlexBox>
                            </FlexBox>
                        ))}
                    </FlexBox>
                </FlexBox>
                {/*<Button*/}
                {/*    // disabled={viewModel.files.length === 0}*/}
                {/*    // onClick={viewModel.onPictureSubmit}*/}
                {/*>*/}
                {/*    Salvar*/}
                {/*</Button>*/}
            </FlexBox>
        </div>
    );
    // endregion
}
// endregion

// region styles
const useStyles = createUseStyles({
    background: {
        position: "fixed",
        pointerEvents: "none",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 12,
        backgroundColor: ({theme}: any) => "rgba(0, 0, 0, 0.3)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingInline: styles.size(2),
    },
    container: {
        backgroundColor: ({theme}: any) => theme.background,
        pointerEvents: "all",
        width: "100%",
        maxWidth: styles.size(50),
        boxShadow: ({theme}: any) => "0px 5px 15px 5px rgba(0,0,0,0.2)",
        borderRadius: styles.size(0.75),
        padding: styles.size(2),
    },
    photo: {
        width: styles.size(15),
        height: styles.size(15),
        objectFit: "cover",
        borderRadius: "50%",
        border: ({theme}: any) => `5px solid ${theme.elementBlue}`,
    },
    item: {
        "&:hover": {
            "& > div": {
                backgroundColor: ({theme}: any) => theme.elementBackgroundSecondary,
            }
        },
    },
    eventsBox: {
        border: ({theme}: any) => styles.border(theme.border, "solid", 1),
        borderRadius: styles.size(0.75),
        fontSize: styles.size(1),
        color: ({theme}: any) => theme.foregroundSecondary,
        height: styles.size(3),
        paddingInline: styles.size(1),
        cursor: "pointer",
    },
    title: {
        color: ({theme}: any) => theme.foreground,
        margin: 0,
    },
});
// endregion