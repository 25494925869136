// region imports
import { FlexBox } from "components/studium_ui";
import * as store from "store/indexOld";
import { className } from "utils/className";
import { createUseStyles, useTheme } from "react-jss";
import * as styles from "utils/styles";
import Card from "components/cards/card";
import { useContext, useEffect } from "react";
import { StoreContext } from "store";
import { useNavigate, useOutletContext } from "react-router-dom";
import { observer } from "mobx-react";
import BreadCrumbs from "components/breadcrumbs/breadcrumbs";
import Loading from "components/loading/loading";
import ProfessorViewModel from "view_models/professors/professor_view_model";
import { LanguageContext } from "utils/itn";
import { itns } from "pages/professors/texts/professor.itn";
// endregion

// region ProfessorsAgreementsView
// this page is the professor grade, here they can see the students enrolled in the grade and the classrooms
const ProfessorsAgreementsView = observer(
	({ viewModel }: { viewModel: ProfessorViewModel }) => {
		// region properties
		const theme = useTheme();
		const classes = useStyles({ theme });
		const navigate = useNavigate();
		const language = useContext(LanguageContext);
		const itn = itns[language];
		const store = useContext(StoreContext);
		// endregion

		// region render
		return (
			<FlexBox direction="column" gap={styles.size(1)}>
				{viewModel.professorsAgreements?.map((professorAgreement) => (
					<Card key={professorAgreement.id}>
						<FlexBox flex={1} mainAxisAlignment="space-between">
							<FlexBox direction="column" gap={12}>
								<div className={classes.itemName}>
									{professorAgreement?.subjectName}
								</div>
								<div className={classes.itemDescription}>
									{professorAgreement?.jobDescription}
								</div>
								{/* {enrollment?.scholarshipPercentage !== undefined && enrollment?.scholarshipPercentage !== 0
                                ? <div className={classes.itemDescription}>
                                    Bolsa: {enrollment?.scholarshipPercentage * 100}%
                                </div>
                                : null
                            }
                            {enrollment?.scholarshipValue !== undefined && enrollment?.scholarshipValue !== 0
                                ? <div className={classes.itemDescription}>
                                    Bolsa: R${Number(enrollment?.scholarshipValue).toFixed(2).replace(".", ",")}
                                </div>
                                : null
                            } */}
							</FlexBox>
							<FlexBox
								direction="column"
								mainAxisAlignment="space-between"
								crossAxisAlignment="end">
								<FlexBox crossAxisAlignment={"center"} gap={12}>
									<div className={classes.itemStatus}>
										{professorAgreement?.active
											? "Ativa"
											: "Inativa"}
									</div>
									<div
										className={className([
											classes.itemStatusBadge,
											professorAgreement.active
												? classes.active
												: classes.inactive,
										])}
									/>
								</FlexBox>
								<div className={classes.createdAt}>
									{professorAgreement.startDate
										? `Período Inicial ${new Date(
												professorAgreement.startDate
										  ).toLocaleDateString()} |`
										: null}
									{professorAgreement.endDate
										? `Período Final ${new Date(
												professorAgreement.endDate
										  ).toLocaleDateString()} |`
										: null}
									Acordo docente realizado em{" "}
									{professorAgreement?.created !==
										undefined &&
										new Date(
											professorAgreement?.created
										).toLocaleDateString()}
								</div>
							</FlexBox>
						</FlexBox>
					</Card>
				))}
			</FlexBox>
		);
		// endregion
	}
);
// endregion

// region style
const useStyles = createUseStyles({
	title: {
		color: ({ theme }: any) => theme.foreground,
		marginTop: 0,
	},
	button: {
		border: "none",
		minWidth: "103px",
		height: styles.size(3),
		paddingInline: styles.size(1),
		left: "822px",
		top: "336px",
		borderRadius: "9px",
		width: "100%",
		marginTop: styles.size(1),
		backgroundColor: ({ theme }: any) => theme.elementBlue,
		// color: ({theme}: any) => theme.foregroundSecondary,
		color: ({ theme }: any) => theme.foreground,
	},
	item: {
		backgroundColor: "rgba(255, 255, 255, 0.3)",
		border: "1px solid rgba(0, 0, 0, 0.1)",
		height: "180px",
		padding: "24px",
		borderRadius: "6px",
		fontSize: "18px",
		boxShadow: "0 2px 8px 0 rgba(0,0,0,0.1)",
		display: "flex",
	},
	itemName: {
		fontWeight: "bold",
		fontSize: "18px",
		color: ({ theme }: any) => theme.foreground,
	},
	itemDescription: {
		fontSize: "12px",
		color: ({ theme }: any) => theme.foregroundSecondary,
	},
	itemStatusBadge: {
		backgroundColor: "cyan",
		height: "12px",
		width: "12px",
		border: "1px solid rgba(0, 0, 0, 0.5)",
		borderRadius: "6px",
	},
	active: {
		backgroundColor: "rgb(80, 240, 130)",
		boxShadow: "0 0 12px rgba(80, 240, 130, 0.8)",
	},
	inactive: {
		backgroundColor: "rgb(240, 40, 80)",
	},
	scholarshipValue: {},
	itemStatus: {
		color: ({ theme }: any) => theme.foreground,
	},
	createdAt: {
		fontSize: styles.size(1),
		color: ({ theme }: any) => theme.foregroundSecondary,
	},
});
// endregion

// region export
export default function ProfessorsAgreements() {
	const viewModel: ProfessorViewModel = useOutletContext();
	return <ProfessorsAgreementsView viewModel={viewModel} />;
}
// endregion
