// region imports
import { makeAutoObservable } from "mobx";
import { Grade } from "domains/grade";
import { Subject } from "domains/subject";
import Store from "store";
import { fetchGrades, FetchGradesFilter } from "api/grades";
import fetchSubjects, { FetchSubjectsFilter } from "api/subjects";
import { Course } from "domains/course";
import { fetchCourses } from "api/courses";
import { executeGeppetto, ExecuteGeppettoRequest } from "api/geppetto";
import {
	FetchProfessorsAgreementsQueryRequestDTO,
	FetchProfessorsAgreementsResponseDTO,
} from "adapters/dto/professor_agreement";
import { fetchProfessorsAgreements } from "api/professor_agreement";
// endregion

// region StudentViewModel
export default class StudentViewModel {
	// region properties
	// public _schoolPeriodSelected: Grade | undefined;
	public _courseSelected: Course | undefined;
	public _gradeSelected: Grade | undefined;
	public _subjectSelected: Subject | undefined;
	public professorAgreementSelected:
		| FetchProfessorsAgreementsResponseDTO
		| undefined;
	public geppetto: ExecuteGeppettoRequest = {
		context: "",
		messages: [],
	};
	public grades: Grade[] | undefined;
	public courses: Course[] | undefined;
	public subjects: Subject[] | undefined;
	public professorsAgreements:
		| FetchProfessorsAgreementsResponseDTO[]
		| undefined;
	public loading: boolean = true;
	public geppettoLoading: boolean = false;
	public geppettoShowError: boolean = false;
	public pathname: string | undefined;
	// private searchParams: URLSearchParams = new URLSearchParams();
	public image: string | undefined;
	public store: Store = new Store();
	public notification: (
		notification: string,
		status: "error" | "success" | "info" | "warning"
	) => any = () => {};
	// endregion
	// width > 768
	// region constructor
	constructor(store: Store) {
		makeAutoObservable(this);
		this.store = store;
		// this.courseSelected = store.coursesStore.courses[0];
		// this.schoolPeriodSelected = store.enrollmentsStore.enrollments[0];
	}

	// endregion

	// region onInitialized
	onInitialized(): void {
		// pathname: string | undefined,
		this.fetchCourses();
		// searchParams: URLSearchParams,
		// this.pathname = pathname;
		// this.searchParams = searchParams;
	}
	// endregion

	// region getters and setters
	// get schoolPeriodSelected(): Grade | undefined {
	//     return this._schoolPeriodSelected;
	// }
	//
	// set schoolPeriodSelected(value) {
	//     this._schoolPeriodSelected = value;
	//     void this.fetchGrades(value?.id);
	// }

	get courseSelected(): Course | undefined {
		return this._courseSelected;
	}

	set courseSelected(value) {
		this._courseSelected = value;
		this.fetchGrades(value?.id).then((r) => {});
	}

	get gradeSelected(): Course | undefined {
		return this._gradeSelected;
	}

	set gradeSelected(value) {
		this._gradeSelected = value;
		this.fetchSubjects().then((r) => {});
	}
	get subjectSelected(): Subject | undefined {
		return this._subjectSelected;
	}

	set subjectSelected(value) {
		this._subjectSelected = value;
		this.fetchProfessorsAgreements().then((r) => {});
	}
	// endregion

	private async fetchCourses(): Promise<void> {
		const response = await fetchCourses(
			{ userId: this.store.userStore.user?.id },
			this.store.authStore.token
		);
		if (!response.payload) return;
		this.courses = response.payload;
		if (this.courses.length > 0) this.courseSelected = this.courses[0];
	}

	private async fetchGrades(enrollmentId: string | undefined): Promise<void> {
		if (!enrollmentId) return;
		const filter: FetchGradesFilter = {
			courseId: this.courseSelected?.id,
		};
		const response = await fetchGrades(filter, this.store.authStore.token);
		if (!response.payload) return;
		this.grades = response.payload;
		this.gradeSelected = this.grades?.[0];
		// this.subjects = response.payload.subjects;
		// console.log("fetchSubjects", response);
		// await this.fetchCourses();
		this.loading = false;
	}

	private async fetchSubjects() {
		console.log("gradeSelected", this.gradeSelected);
		const filter: FetchSubjectsFilter = {
			page: 1,
			pageSize: 100,
			courseId: this.courseSelected?.id,
			userId: this.store.userStore.user?.id,
			gradeId: this.gradeSelected?.id,
			institutionUnitId: this.store.institutionStore.units?.[0].id,
		};

		const response = await fetchSubjects(
			filter,
			this.store.authStore.token
		);
		console.log("fetchSubjects", response);
		if (!response.payload) return;

		this.subjects = response.payload;
	}

	// region fetchProfessorsAgreements
	private async fetchProfessorsAgreements() {
		const filter: FetchProfessorsAgreementsQueryRequestDTO = {
			page: 1,
			pageSize: 100,
			courseId: this.courseSelected?.id,
			subjectId: this.subjectSelected?.id,
			institutionUnitId: this.store.institutionStore.units?.[0].id,
		};
		const response = await fetchProfessorsAgreements(
			filter,
			this.store.authStore.token
		);
		if (!response.payload) return (this.professorsAgreements = []);
		this.professorsAgreements = response.payload;
	}
	// endregion

	public sendMessageToGeppetto(message: string) {
		this.geppetto.messages?.push({
			role: "user",
			content: message,
		});
		void this.executeGeppetto();
	}

	private async executeGeppetto() {
		this.geppettoLoading = true;
		const response = await executeGeppetto(
			this.geppetto,
			this.store.authStore.token
		);
		if (!(response && response.payload)) {
			if (!response?.message) return;
			this.notification(response.message, "error");
			this.geppettoShowError = true;
			return;
		}
		this.geppetto.messages = response.payload.messages?.filter(
			(m) => m.role !== "system"
		);
		this.geppettoLoading = false;
	}
}
// endregion
