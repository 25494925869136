import {DaysOfWeek, daysOfWeek, ItnType, Months, months} from "../../../utils/itn";

type Type = {
    subjects: {
        nextEvents: string,
        scheduleOfTheWeek: string,
    },
    courses: {
        title: string,
    },
    classroom: {
        subjects: {
            title: string,
            emptySubjects: string,
        },
        students: {
            title: string,
            emptyStudents: string,
            modal: {
                sendNoticeToStudent: string,
                sendNoticeToParents: string,
                viewGrades: string,
                viewAbsences: string,
            },
        }
    },
    academicYears: string,
    classrooms: string,
    emptyClassrooms: string,
    months: Months,
    daysOfWeek: DaysOfWeek,
}

export const itns: ItnType<Type> = {
    ptBr: {
        subjects: {
            nextEvents: "Próximos eventos",
            scheduleOfTheWeek: "Cronograma da semana",
        },
        courses: {
            title: "Cursos",
        },
        classroom: {
            subjects: {
                title: "Matérias",
                emptySubjects: "Este ano letivo não possui matérias cadastradas.",
            },
            students: {
                title: "Alunos",
                emptyStudents: "Esta turma não possui alunos cadastrados.",
                modal: {
                    sendNoticeToStudent: "Enviar comunicado para o aluno",
                    sendNoticeToParents: "Enviar comunicado para os pais",
                    viewGrades: "Ver notas",
                    viewAbsences: "Ver faltas",
                }
            }
        },
        academicYears: "Anos letivos",
        classrooms: "Turmas",
        emptyClassrooms: "Este ano letivo não possui turmas cadastradas.",
        months: months.ptBr,
        daysOfWeek: daysOfWeek.ptBr,
    },
    ptEu: {
        subjects: {
            nextEvents: "Próximos eventos",
            scheduleOfTheWeek: "Cronograma da semana",
        },
        courses: {
            title: "Cursos",
        },
        classroom: {
            subjects: {
                title: "Matérias",
                emptySubjects: "Este ano letivo não possui matérias cadastradas.",
            },
            students: {
                title: "Alunos",
                emptyStudents: "Esta turma não possui alunos cadastrados.",
                modal: {
                    sendNoticeToStudent: "Enviar comunicado para o aluno",
                    sendNoticeToParents: "Enviar comunicado para os pais",
                    viewGrades: "Ver notas",
                    viewAbsences: "Ver faltas",
                }
            }
        },
        academicYears: "Anos letivos",
        classrooms: "Turmas",
        emptyClassrooms: "Este ano letivo não possui turmas cadastradas.",
        months: months.ptEu,
        daysOfWeek: daysOfWeek.ptEu,
    },
    en: {
        subjects: {
            nextEvents: "Next events",
            scheduleOfTheWeek: "Schedule of the week",
        },
        courses: {
            title: "Courses",
        },
        classroom: {
            subjects: {
                title: "Subjects",
                emptySubjects: "This academic year does not have registered students.",
            },
            students: {
                title: "Students",
                emptyStudents: "This classroom does not have registered students.",
                modal: {
                    sendNoticeToStudent: "Send notice to student",
                    sendNoticeToParents: "Send notice to parents",
                    viewGrades: "View grades",
                    viewAbsences: "View absences",
                }
            }
        },
        academicYears: "Academic years",
        classrooms: "Classrooms",
        emptyClassrooms: "This academic year does not have registered classrooms.",
        months: months.en,
        daysOfWeek: daysOfWeek.en,
    },
    es: {
        subjects: {
            nextEvents: "Próximos eventos",
            scheduleOfTheWeek: "Cronograma de la semana",
        },
        courses: {
            title: "Cursos",
        },
        classroom: {
            subjects: {
                title: "Materias",
                emptySubjects: "Este año lectivo no tiene materias registradas.",
            },
            students: {
                title: "Alumnos",
                emptyStudents: "Esta sala de aula no tiene alumnos registrados.",
                modal: {
                    sendNoticeToStudent: "Enviar comunicado al alumno",
                    sendNoticeToParents: "Enviar comunicado a los padres",
                    viewGrades: "Ver notas",
                    viewAbsences: "Ver faltas",
                }
            }
        },
        academicYears: "Años lectivos",
        classrooms: "Salas de aula",
        emptyClassrooms: "Este año lectivo no tiene salas de aula registradas.",
        months: months.es,
        daysOfWeek: daysOfWeek.es,
    },
    fr: {
        subjects: {
            nextEvents: "Prochains événements",
            scheduleOfTheWeek: "Calendrier de la semaine",
        },
        courses: {
            title: "Cours",
        },
        classroom: {
            subjects: {
                title: "Matières",
                emptySubjects: "Cette année académique ne comporte pas de matières enregistrées.",
            },
            students: {
                title: "Élèves",
                emptyStudents: "Cette salle de classe ne comporte pas d'élèves enregistrés.",
                modal: {
                    sendNoticeToStudent: "Envoyer un avis à l'élève",
                    sendNoticeToParents: "Envoyer un avis aux parents",
                    viewGrades: "Voir les notes",
                    viewAbsences: "Voir les absences",
                }
            }
        },
        academicYears: "Années académiques",
        classrooms: "Salles de classe",
        emptyClassrooms: "Cette année académique ne comporte pas de salles de classe enregistrées.",
        months: months.fr,
        daysOfWeek: daysOfWeek.fr,
    },
    it: {
        subjects: {
            nextEvents: "Prossimi eventi",
            scheduleOfTheWeek: "Programma della settimana",
        },
        courses: {
            title: "Corsi",
        },
        classroom: {
            subjects: {
                title: "Materie",
                emptySubjects: "Questo anno accademico non ha materie registrate.",
            },
            students: {
                title: "Studenti",
                emptyStudents: "Questa aula non ha studenti registrati.",
                modal: {
                    sendNoticeToStudent: "Invia avviso allo studente",
                    sendNoticeToParents: "Invia avviso ai genitori",
                    viewGrades: "Visualizza voti",
                    viewAbsences: "Visualizza assenze",
                }
            }
        },
        academicYears: "Anni accademici",
        classrooms: "Aule",
        emptyClassrooms: "Questo anno accademico non ha aule registrate.",
        months: months.it,
        daysOfWeek: daysOfWeek.it,
    },
    de: {
        subjects: {
            nextEvents: "Nächste Veranstaltungen",
            scheduleOfTheWeek: "Zeitplan der Woche",
        },
        courses: {
            title: "Kurse",
        },
        classroom: {
            subjects: {
                title: "Fächer",
                emptySubjects: "Dieses akademische Jahr hat keine registrierten Fächer.",
            },
            students: {
                title: "Schüler",
                emptyStudents: "Dieser Klassenraum hat keine registrierten Schüler.",
                modal: {
                    sendNoticeToStudent: "Benachrichtigung an den Schüler senden",
                    sendNoticeToParents: "Benachrichtigung an die Eltern senden",
                    viewGrades: "Noten anzeigen",
                    viewAbsences: "Fehlzeiten anzeigen",
                }
            }
        },
        academicYears: "Akademische Jahre",
        classrooms: "Klassenzimmer",
        emptyClassrooms: "Dieses akademische Jahr hat keine registrierten Klassenzimmer.",
        months: months.de,
        daysOfWeek: daysOfWeek.de,
    },
}