import { useNavigate, useOutletContext } from "react-router-dom";
import ProfessorViewModel from "view_models/professors/professor_view_model";
import { observer } from "mobx-react";
import * as styles from "utils/styles";
import BreadCrumbs from "components/breadcrumbs/breadcrumbs";
import Card from "components/cards/card";
import { FlexBox } from "components/studium_ui";
import Loading from "components/loading/loading";
import { createUseStyles, useTheme } from "react-jss";
import { useContext } from "react";
import { LanguageContext } from "utils/itn";
import { itns } from "pages/professors/texts/professor.itn";
import Accordion from "components/accordion/accordion";
import List from "components/list/list";

const ProfessorCoursesView = observer(
	({ viewModel }: { viewModel: ProfessorViewModel }) => {
		const theme = useTheme();
		const classes = useStyles({ theme });
		const navigate = useNavigate();
		const language = useContext(LanguageContext);
		const itn = itns[language];

		const rowChildren = (factor: number) => [
			{
				id: Math.random().toString(),
				name: "Primeiro semestre",
				children: [
					{
						id: (Math.random() * factor).toString(),
						name: "Turma A",
					},
					{
						id: (Math.random() * factor).toString(),
						name: "Turma B",
					},
				],
			},
		];

		return (
			<FlexBox gap={styles.size(1)} column flex={1}>
				<BreadCrumbs homeRoute="/professors/" />
				<Card
					leftHeader={
						<FlexBox
							gap={styles.size(2)}
							mainAxisAlignment="center"
							crossAxisAlignment="center">
							<h5 className={classes.title}>
								{itn.courses.title}
							</h5>
						</FlexBox>
					}>
					<FlexBox gap={styles.size(1)} column flex={1}>
						{/* <List
                        rows={[{
                            id: Math.random().toString(),
                            name: "Engenharia da Computação",
                            children: rowChildren(0.1)
                        }, {
                            id: Math.random().toString(),
                            name: "Engenharia civil",
                            children: rowChildren(0.2)
                        },{
                            id: Math.random().toString(),
                            name: "Matemática",
                            children: rowChildren(0.3)
                        },
                        {
                            id: Math.random().toString(),
                            name: "Física",
                            children: rowChildren(0.4)
                        }]} />
                    {viewModel.loadingCourses ? <Loading /> :
                        <List
                            callback={(row) => {}}
                            rows={viewModel.courses?.map((course, index) => (
                                {
                                    id: course.id ?? Math.random().toString(),
                                    name: course.name ?? "",
                                    children: rowChildren(0.5)
                                }
                            ))} />
                    } */}
						{viewModel.loadingCourses ? (
							<Loading />
						) : (
							<List
								callback={(rows) => (viewModel.rows = rows)}
								rows={viewModel.rows}
							/>
						)}
						{/* {viewModel.loadingCourses ? <Loading /> :
                        viewModel.courses?.map((course, index) => (
                            <FlexBox
                                onClick={() => {
                                    viewModel.courseSelected = course;
                                    navigate(`/professors/courses`);//?id=${course.id}`);
                                }}
                                key={course.id}
                                className={classes.item}
                                gap={styles.size(1)}
                                mainAxisAlignment="center"
                                crossAxisAlignment="center">
                                <FlexBox
                                    className={classes.eventsBox}
                                    flex={1}
                                    // style={{ width: styles.size(20) }}
                                    mainAxisAlignment="center"
                                    crossAxisAlignment="center">
                                    {course.name}
                                </FlexBox>
                            </FlexBox>
                        ))
                    } */}
						{/* <Accordion index={0} title="Engenharia da Computação">
                        <Accordion index={1} title="Primeiro semestre">
                            <Accordion index={2} title="Turma A">
                                Content
                            </Accordion>
                        </Accordion>
                        <Accordion index={1} title="Segundo semestre">
                            <Accordion index={2} title="Turma B">
                                Content
                            </Accordion>
                        </Accordion>
                        <Accordion index={1} title="Terceiro semestre">
                            <Accordion index={2} title="Turma C">
                                Content
                            </Accordion>
                        </Accordion>
                        <Accordion index={1} title="Quarto semestre">
                            <Accordion index={2} title="Turma D">
                                Content
                            </Accordion>
                        </Accordion>
                    </Accordion>

                    <Accordion index={0} title="Engenharia Civil">
                        <Accordion index={1} title="Primeiro semestre">
                            <Accordion index={2} title="Turma A">
                                Content
                            </Accordion>
                        </Accordion>
                        <Accordion index={1} title="Segundo semestre">
                            <Accordion index={2} title="Turma A">
                                Content
                            </Accordion>
                            <Accordion index={2} title="Turma B">
                                Content
                            </Accordion>
                        </Accordion>
                        <Accordion index={1} title="Terceiro semestre">
                            <Accordion index={2} title="Turma A">
                                Content
                            </Accordion>
                        </Accordion>
                        <Accordion index={1} title="Quarto semestre">
                            <Accordion index={2} title="Turma A">
                                Content
                            </Accordion>
                        </Accordion>
                    </Accordion>

                    <Accordion index={0} title="Matemática">
                        <Accordion index={1} title="Primeiro semestre">
                            <Accordion index={2} title="Turma A">
                                Content
                            </Accordion>
                        </Accordion>
                        <Accordion index={1} title="Segundo semestre">
                            <Accordion index={2} title="Turma B">
                                Content
                            </Accordion>
                        </Accordion>
                        <Accordion index={1} title="Terceiro semestre">
                            <Accordion index={2} title="Turma C">
                                Content
                            </Accordion>
                        </Accordion>
                        <Accordion index={1} title="Quarto semestre">
                            <Accordion index={2} title="Turma D">
                                Content
                            </Accordion>
                        </Accordion>
                    </Accordion> */}
					</FlexBox>
				</Card>
			</FlexBox>
		);
	}
);

// region styles
const useStyles = createUseStyles({
	gradeItem: {
		color: ({ theme }: any) => theme.foregroundSecondary,
		border: ({ theme }: any) => `1px solid ${theme.foregroundSecondary}33`,
		borderRadius: styles.size(0.75),
		height: styles.size(4),
		paddingInline: styles.size(1),
		transition: "all 0.2s ease-in-out",
	},
	goBack: {
		cursor: "pointer",
	},
	goBackIcon: {
		fontSize: styles.size(1.5),
		"&:before": {
			color: ({ theme }: any) => theme.foregroundSecondary,
		},
	},
	title: {
		fontWeight: 500,
		color: ({ theme }: any) => theme.foreground,
		fontSize: styles.size(1.5),
		margin: 0,
	},
	item: {
		"&:hover": {
			"& > div": {
				backgroundColor: ({ theme }: any) =>
					theme.elementBackgroundSecondary,
			},
		},
	},
	eventsBox: {
		border: ({ theme }: any) => `1px solid ${theme.border}`,
		borderRadius: styles.size(0.75),
		fontSize: styles.size(1),
		color: ({ theme }: any) => theme.foregroundSecondary,
		height: styles.size(3),
		paddingInline: styles.size(1),
		cursor: "pointer",
	},
	eventBoxActive: {
		backgroundColor: ({ theme }: any) => theme.elementBackgroundSecondary,
	},
});
// endregion

// region export
export default function ProfessorCourses() {
	const viewModel: ProfessorViewModel = useOutletContext();
	return <ProfessorCoursesView viewModel={viewModel} />;
}
// endregion
