import { Response } from "domains/response";
import { requests } from "api/requests";

export type GeppettoMessage = {
	role: "user" | "assistant" | "system"; // GeppettoMessageRole,
	content: string;
	name?: string;
};

export type ExecuteGeppettoRequest = {
	context?: string;
	messages?: GeppettoMessage[];
};

export type ExecuteGeppettoResponse = {
	messages?: GeppettoMessage[];
};

export async function executeGeppetto(
	data: ExecuteGeppettoRequest,
	token?: string
): Promise<Response<ExecuteGeppettoResponse>> {
	const { post } = requests();

	return await post<ExecuteGeppettoResponse>("/geppetto", data, token);
}
