// region imports
import { FlexBox } from "components/studium_ui";
import * as store from "store/indexOld";
import { className } from "utils/className";
import { createUseStyles, useTheme } from "react-jss";
import * as styles from "utils/styles";
import Card from "components/cards/card";
import { useContext } from "react";
import { StoreContext } from "store";
// endregion

// region Enrollments
export default function Enrollments() {
	// region properties
	const store = useContext(StoreContext);
	const theme = useTheme();
	const classes = useStyles({ theme });
	// endregion

	// region render
	return (
		<FlexBox direction="column" gap={styles.size(1)}>
			{store.enrollmentsStore?.enrollments?.map((enrollment) => (
				<Card key={enrollment.id}>
					<FlexBox flex={1} mainAxisAlignment="space-between">
						<FlexBox direction="column" gap={12}>
							<div className={classes.itemName}>
								{enrollment?.name}
							</div>
							<div className={classes.itemDescription}>
								{enrollment?.description}
							</div>
							{enrollment?.scholarshipPercentage !== undefined &&
							enrollment?.scholarshipPercentage !== 0 ? (
								<div className={classes.itemDescription}>
									Bolsa:{" "}
									{enrollment?.scholarshipPercentage * 100}%
								</div>
							) : null}
							{enrollment?.scholarshipValue !== undefined &&
							enrollment?.scholarshipValue !== 0 ? (
								<div className={classes.itemDescription}>
									Bolsa: R$
									{Number(enrollment?.scholarshipValue)
										.toFixed(2)
										.replace(".", ",")}
								</div>
							) : null}
						</FlexBox>
						<FlexBox
							direction="column"
							mainAxisAlignment="space-between"
							crossAxisAlignment="end">
							<FlexBox crossAxisAlignment={"center"} gap={12}>
								<div className={classes.itemStatus}>
									{enrollment?.active ? "Ativa" : "Inativa"}
								</div>
								<div
									className={className([
										classes.itemStatusBadge,
										enrollment.active
											? classes.active
											: classes.inactive,
									])}
								/>
							</FlexBox>
							<div className={classes.createdAt}>
								Matrícula efetuada em{" "}
								{enrollment?.created !== undefined &&
									new Date(
										enrollment?.created * 1000
									).toLocaleDateString()}
							</div>
						</FlexBox>
					</FlexBox>
				</Card>
			))}
		</FlexBox>
	);
	// endregion
}
// endregion

// region style
const useStyles = createUseStyles({
	title: {
		color: ({ theme }: any) => theme.foreground,
		marginTop: 0,
	},
	button: {
		border: "none",
		minWidth: "103px",
		height: styles.size(3),
		paddingInline: styles.size(1),
		left: "822px",
		top: "336px",
		borderRadius: "9px",
		width: "100%",
		marginTop: styles.size(1),
		backgroundColor: ({ theme }: any) => theme.elementBlue,
		// color: ({theme}: any) => theme.foregroundSecondary,
		color: ({ theme }: any) => theme.foreground,
	},
	item: {
		backgroundColor: "rgba(255, 255, 255, 0.3)",
		border: "1px solid rgba(0, 0, 0, 0.1)",
		height: "180px",
		padding: "24px",
		borderRadius: "6px",
		fontSize: "18px",
		boxShadow: "0 2px 8px 0 rgba(0,0,0,0.1)",
		display: "flex",
	},
	itemName: {
		fontWeight: "bold",
		fontSize: "18px",
		color: ({ theme }: any) => theme.foreground,
	},
	itemDescription: {
		fontSize: "12px",
		color: ({ theme }: any) => theme.foregroundSecondary,
	},
	itemStatusBadge: {
		backgroundColor: "cyan",
		height: "12px",
		width: "12px",
		border: "1px solid rgba(0, 0, 0, 0.5)",
		borderRadius: "6px",
	},
	active: {
		backgroundColor: "rgb(80, 240, 130)",
		boxShadow: "0 0 12px rgba(80, 240, 130, 0.8)",
	},
	inactive: {
		backgroundColor: "rgb(240, 40, 80)",
	},
	scholarshipValue: {},
	itemStatus: {
		color: ({ theme }: any) => theme.foreground,
	},
	createdAt: {
		fontSize: styles.size(1),
		color: ({ theme }: any) => theme.foregroundSecondary,
	},
});
// endregion
