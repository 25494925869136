// region imports
import Card from "../../components/cards/card";
import { FlexBox } from "../../components/studium_ui";
import { createUseStyles, useTheme } from "react-jss";
import * as styles from "../../utils/styles";
import { useNavigate, useOutletContext } from "react-router-dom";
import { observer } from "mobx-react";
import BreadCrumbs from "../../components/breadcrumbs/breadcrumbs";
import Loading from "../../components/loading/loading";
import type ProfessorViewModel from "../../view_models/professors/professor_view_model";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { useContext } from "react";
import UserModal from "./components/user_modal";
import { LanguageContext } from "../../utils/itn";
import { itns } from "./texts/professor.itn";
import DropDown from "../../components/drop_down/drop_down";
import { className } from "../../utils/className";
// import icons from "../../utils/icons";
// endregion

// region ProfessorsClassrooms
// this page is the professor's classrooms, here they can see the students enrolled in the grade and the classrooms
const ProfessorClassroomView = observer(
    ({ viewModel }: { viewModel: ProfessorViewModel }) => {
        // region properties
        const theme = useTheme();
        const classes = useStyles({ theme });
        const navigate = useNavigate();
        const { width } = useWindowDimensions();
        const language = useContext(LanguageContext);
        const itn = itns[language];
        const days = ['sunday','monday','tuesday','wednesday','thursday','friday','saturday'];
        const now = new Date();
        const day = days[ now.getDay() ];
        // endregion

        // region render
        return (
            <FlexBox flex={1} gap={styles.size(1)} column >
                <BreadCrumbs
                    homeRoute="/professors/"
                    items={[
                        {
                            text: viewModel.courseSelected?.name,
                            route: `/professors/courses`, // ?courseId=${viewModel.courseSelected?.id}`
                        },
                        {
                            text: viewModel.gradeSelected?.name,
                            route: `/professors/grades`, // ?gradeId=${viewModel.gradeSelected?.id}`
                        },
                        {
                            text:
                                viewModel.classroomSelected?.name &&
                                `Classe ${viewModel.classroomSelected?.name ?? ""}`,
                        },
                    ]}
                />
                {/* region header */}
                <FlexBox gap={styles.size(1)}>
                    <Card
                        flex={1}
                        style={{ padding: `${styles.size(1)} ${styles.size(1)}` }}>
                        <FlexBox
                            mainAxisAlignment="space-between"
                            wrap
                            gap={styles.size(1)}>

                            <FlexBox crossAxisAlignment="center" gap={styles.size(1)}>
                                <div
                                    style={{
                                        whiteSpace: "nowrap",
                                        fontSize: styles.size(1)
                                    }}>
                                    {/* {itn.header.dropDownCourseTitle} */}
                                    itn_todo Disciplinas
                                </div>

                                <DropDown
                                    style={{ width: "14rem", flexShrink: 1 }}
                                    selected={viewModel.subjectSelected?.id}
                                    items={
                                        viewModel.subjects?.map((subject) => {
                                            return {
                                                id: subject.id,
                                                name: subject.name
                                            };
                                        }) ?? []
                                    }
                                    displayValuePath="name"
                                    onChange={(item: any) => (viewModel.subjectSelected = viewModel.subjects?.find((e) => e.id === item.id))} />

                            </FlexBox>

                            <FlexBox crossAxisAlignment="center" gap={styles.size(1)}>
                                <div
                                    style={{
                                        whiteSpace: "nowrap",
                                        fontSize: styles.size(1)
                                    }}>
                                    {/* {itn.header.dropDownSchoolPeriodTitle} */}
                                </div>

                                {/* <DropDown
                                    style={{ width: "14rem", flexShrink: 1 }}
                                    selected={viewModel.gradeSelected?.id}
                                    items={
                                        viewModel.grades ? [
                                            {
                                                id: undefined,
                                                name: itn.header.dropDownSchoolPeriodAll
                                            },
                                            ...(viewModel.grades?.map((grade) => {
                                                return {
                                                    id: grade.id,
                                                    name: grade.name
                                                };
                                            }))] : []
                                    }
                                    displayValuePath="name"
                                    onChange={(item: any) => (viewModel.gradeSelected = viewModel.grades?.find((e) => e.id === item.id))} /> */}

                            </FlexBox>

                        </FlexBox>
                    </Card>
                </FlexBox>
                {/* endregion */}
                <FlexBox shrink={0} column={width <= 992} flex={1} gap={styles.size(1)}>
                    <Card title={itn.subjects.nextEvents} flex={1}>
                        <FlexBox flex={1} column gap={styles.size(1)} mainAxisAlignment="start" crossAxisAlignment="stretch">
                            <FlexBox gap={styles.size(1)} mainAxisAlignment="center" crossAxisAlignment="center">
                                <FlexBox className={classes.eventsBox} style={{ width: styles.size(8) }} mainAxisAlignment="center" crossAxisAlignment="center">
                                    07/08/2023
                                </FlexBox>
                                <FlexBox flex={1} className={classes.eventsBox} mainAxisAlignment="start" crossAxisAlignment="center">
                                    Entrega do trabalho 1
                                </FlexBox>
                            </FlexBox>
                            <FlexBox gap={styles.size(1)} mainAxisAlignment="center" crossAxisAlignment="center">
                                <FlexBox className={classes.eventsBox} style={{ width: styles.size(8) }} mainAxisAlignment="center" crossAxisAlignment="center">
                                    07/08/2023
                                </FlexBox>
                                <FlexBox flex={1} className={classes.eventsBox} mainAxisAlignment="start" crossAxisAlignment="center">
                                    Prova 1
                                </FlexBox>
                            </FlexBox>
                            <FlexBox gap={styles.size(1)} mainAxisAlignment="center" crossAxisAlignment="center">
                                <FlexBox className={classes.eventsBox} style={{ width: styles.size(8) }} mainAxisAlignment="center" crossAxisAlignment="center">
                                    07/08/2023
                                </FlexBox>
                                <FlexBox flex={1} className={classes.eventsBox} mainAxisAlignment="start" crossAxisAlignment="center">
                                    Entrega do trabalho 2
                                </FlexBox>
                            </FlexBox>
                            <FlexBox gap={styles.size(1)} mainAxisAlignment="center" crossAxisAlignment="center">
                                <FlexBox className={classes.eventsBox} style={{ width: styles.size(8) }} mainAxisAlignment="center" crossAxisAlignment="center">
                                    07/08/2023
                                </FlexBox>
                                <FlexBox flex={1} className={classes.eventsBox} mainAxisAlignment="start" crossAxisAlignment="center">
                                    Entrega do trabalho 3
                                </FlexBox>
                            </FlexBox>
                            <FlexBox gap={styles.size(1)} mainAxisAlignment="center" crossAxisAlignment="center">
                                <FlexBox className={classes.eventsBox} style={{ width: styles.size(8) }} mainAxisAlignment="center" crossAxisAlignment="center">
                                    07/08/2023
                                </FlexBox>
                                <FlexBox flex={1} className={classes.eventsBox} mainAxisAlignment="start" crossAxisAlignment="center">
                                    Prova 2
                                </FlexBox>
                            </FlexBox>
                        </FlexBox>
                    </Card>
                    <Card title={itn.subjects.scheduleOfTheWeek} flex={1}>
                        <FlexBox flex={1} column gap={styles.size(1)} mainAxisAlignment="start" crossAxisAlignment="stretch">
                            <FlexBox gap={styles.size(1)} mainAxisAlignment="center" crossAxisAlignment="center">
                                <FlexBox className={className([classes.eventsBox, day === "monday" ? classes.eventBoxActive : null])} style={{ width: styles.size(8) }} mainAxisAlignment="center" crossAxisAlignment="center">
                                    12am - 2pm
                                </FlexBox>
                                <FlexBox flex={1} className={className([classes.eventsBox, day === "monday" ? classes.eventBoxActive : null])} mainAxisAlignment="start" crossAxisAlignment="center">
                                    {itn.daysOfWeek.monday}
                                </FlexBox>
                            </FlexBox>
                            <FlexBox gap={styles.size(1)} mainAxisAlignment="center" crossAxisAlignment="center">
                                <FlexBox className={className([classes.eventsBox, day === "thursday" ? classes.eventBoxActive : null])} style={{ width: styles.size(8) }} mainAxisAlignment="center" crossAxisAlignment="center">
                                    12am - 2pm
                                </FlexBox>
                                <FlexBox flex={1} className={className([classes.eventsBox, day === "thursday" ? classes.eventBoxActive : null])} mainAxisAlignment="start" crossAxisAlignment="center">
                                    {itn.daysOfWeek.thursday}
                                </FlexBox>
                            </FlexBox>
                            <FlexBox gap={styles.size(1)} mainAxisAlignment="center" crossAxisAlignment="center">
                                <FlexBox className={className([classes.eventsBox, day === "wednesday" ? classes.eventBoxActive : null])} style={{ width: styles.size(8) }} mainAxisAlignment="center" crossAxisAlignment="center">
                                    12am - 2pm
                                </FlexBox>
                                <FlexBox flex={1} className={className([classes.eventsBox, day === "wednesday" ? classes.eventBoxActive : null])} mainAxisAlignment="start" crossAxisAlignment="center">
                                    {itn.daysOfWeek.wednesday}
                                </FlexBox>
                            </FlexBox>
                            <FlexBox gap={styles.size(1)} mainAxisAlignment="center" crossAxisAlignment="center">
                                <FlexBox className={className([classes.eventsBox, day === "tuesday" ? classes.eventBoxActive : null])} style={{ width: styles.size(8) }} mainAxisAlignment="center" crossAxisAlignment="center">
                                    12am - 2pm
                                </FlexBox>
                                <FlexBox flex={1} className={className([classes.eventsBox, day === "tuesday" ? classes.eventBoxActive : null])} mainAxisAlignment="start" crossAxisAlignment="center">
                                    {itn.daysOfWeek.tuesday}
                                </FlexBox>
                            </FlexBox>
                            <FlexBox gap={styles.size(1)} mainAxisAlignment="center" crossAxisAlignment="center">
                                <FlexBox className={className([classes.eventsBox, day === "friday" ? classes.eventBoxActive : null])} style={{ width: styles.size(8) }} mainAxisAlignment="center" crossAxisAlignment="center">
                                    12am - 2pm
                                </FlexBox>
                                <FlexBox flex={1} className={className([classes.eventsBox, day === "friday" ? classes.eventBoxActive : null])} mainAxisAlignment="start" crossAxisAlignment="center">
                                    {itn.daysOfWeek.friday}
                                </FlexBox>
                            </FlexBox>
                        </FlexBox>
                    </Card>
                </FlexBox>
                <FlexBox gap={styles.size(1)} column={width <= 992} flex={1}>
                    <Card
                        style={{ flex: 1 }}
                        leftHeader={
                            <FlexBox
                                gap={styles.size(1)}
                                mainAxisAlignment="center"
                                crossAxisAlignment="center">
                                {/* <div onClick={() => navigate(-1)} className={classes.goBack}>Voltar</div> */}
                                <h5 className={classes.title}>
                                    {itn.classroom.subjects.title}
                                </h5>
                            </FlexBox>
                        }>
                        {/* <FlexBox gap={styles.size(1)} column flex={1}>asdf</FlexBox> */}
                        <FlexBox gap={styles.size(1)} column flex={1}>
                            <FlexBox
                                flex={1}
                                column
                                gap={styles.size(1)}
                                mainAxisAlignment="start"
                                crossAxisAlignment="stretch">
                                {viewModel.loadingStudents ? (
                                    <Loading />
                                ) : viewModel.subjects && viewModel.subjects?.length > 0 ? (
                                    viewModel.subjects.map((subject, index) => (
                                        <FlexBox
                                            key={subject.id}
                                            onClick={() => {
                                                viewModel.subjectSelected = subject;
                                                navigate(`/professors/subjects`); // ?id=${classroom.id}`);
                                            }}
                                            className={classes.item}
                                            gap={styles.size(1)}
                                            mainAxisAlignment="center"
                                            crossAxisAlignment="center">
                                            <FlexBox
                                                flex={1}
                                                gap={styles.size(1)}
                                                className={classes.eventsBox}
                                                mainAxisAlignment="start"
                                                crossAxisAlignment="center">
                                                {/* <img src={`http://static.studium.academy/${student?.photo}`} */}
                                                {/*     alt={student?.name} style={{ */}
                                                {/*    width: styles.size(2), */}
                                                {/*    height: styles.size(2), */}
                                                {/*    borderRadius: styles.size(1) */}
                                                {/* }}/> */}
                                                {subject?.name}
                                            </FlexBox>
                                        </FlexBox>
                                    ))
                                ) : (
                                    itn.classroom.subjects.emptySubjects
                                )}
                            </FlexBox>
                        </FlexBox>
                    </Card>
                    <Card
                        style={{ flex: 1 }}
                        leftHeader={
                            <FlexBox
                                gap={styles.size(1)}
                                mainAxisAlignment="center"
                                crossAxisAlignment="center">
                                {/* <div onClick={() => navigate(-1)} className={classes.goBack}>Voltar</div> */}
                                <h5 className={classes.title}>
                                    {itn.classroom.students.title}
                                </h5>
                            </FlexBox>
                        }>
                        {/* <FlexBox gap={styles.size(1)} column flex={1}>asdf</FlexBox> */}
                        <FlexBox gap={styles.size(1)} column flex={1}>
                            <FlexBox
                                flex={1}
                                column
                                gap={styles.size(1)}
                                mainAxisAlignment="start"
                                crossAxisAlignment="stretch">
                                {viewModel.loadingStudents ? (
                                    <Loading />
                                ) : viewModel.students && viewModel.students?.length > 0 ? (
                                    viewModel.students.map((student, index) => (
                                        <FlexBox
                                            key={student.id}
                                            onClick={() => {
                                                viewModel.studentSelected = student;
                                                viewModel.showUserModal = true;
                                            }}
                                            className={classes.item}
                                            gap={styles.size(1)}
                                            mainAxisAlignment="center"
                                            crossAxisAlignment="center">
                                            <FlexBox
                                                flex={1}
                                                gap={styles.size(1)}
                                                className={classes.eventsBox}
                                                mainAxisAlignment="start"
                                                crossAxisAlignment="center">
                                                <img
                                                    src={`http://static.studium.academy/${student?.photo}`}
                                                    alt={student?.name}
                                                    style={{
                                                        width: styles.size(2),
                                                        height: styles.size(2),
                                                        borderRadius: styles.size(1),
                                                    }}
                                                />
                                                {student?.name}
                                            </FlexBox>
                                        </FlexBox>
                                    ))
                                ) : (
                                    itn.classroom.students.emptyStudents
                                )}
                            </FlexBox>
                        </FlexBox>
                    </Card>
                </FlexBox>
                {viewModel.showUserModal && <UserModal viewModel={viewModel} />}
            </FlexBox>
        );
        // endregion
    }
);
// endregion

// region style
const useStyles = createUseStyles({
    gradeItem: {
        color: ({ theme }: any) => theme.foregroundSecondary,
        border: ({ theme }: any) => `1px solid ${theme.border}`,
        borderRadius: styles.size(0.75),
        height: styles.size(4),
        paddingInline: styles.size(1),
        transition: "all 0.2s ease-in-out",
    },
    goBack: {
        cursor: "pointer",
    },
    title: {
        fontWeight: 500,
        color: ({ theme }: any) => theme.foreground,
        fontSize: styles.size(1.5),
        margin: 0,
    },
    item: {
        "&:hover": {
            "& > div": {
                backgroundColor: ({ theme }: any) => theme.elementBackgroundSecondary,
            },
        },
    },
    eventsBox: {
        border: ({ theme }: any) =>
            styles.border(theme.border, "solid", 1),
        borderRadius: styles.size(0.75),
        fontSize: styles.size(1),
        color: ({ theme }: any) => theme.foregroundSecondary,
        height: styles.size(3),
        paddingInline: styles.size(1),
        cursor: "pointer",
    },
    eventBoxActive: {
        backgroundColor: ({ theme }: any) => theme.elementBackgroundSecondary,
    },
});
// endregion

// region export
export default function ProfessorClassroom() {
    const viewModel: ProfessorViewModel = useOutletContext();
    return <ProfessorClassroomView viewModel={viewModel} />;
}
// endregion
