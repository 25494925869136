import { FlexBox } from "../../components/studium_ui";
import Card from "../../components/cards/card";
import * as styles from "../../utils/styles";
import BreadCrumbs from "../../components/breadcrumbs/breadcrumbs";
import DropDown from "../../components/drop_down/drop_down";
import Icon from "../../components/icon/icon";
import icons from "../../utils/icons";
import { itns } from "./texts/subjects.itn";
import type StudentViewModel from "../../view_models/students/student_view_model";
import { observer } from "mobx-react";
import Loading from "../../components/loading/loading";
import { useItn } from "../../utils/itn";
import { createUseStyles, useTheme } from "react-jss";
import { useNavigate, useOutletContext } from "react-router-dom";
import React, { ReactElement, useEffect } from "react";
import { className } from "../../utils/className";

const StudentSubjectsListView = observer(
	({ viewModel }: { viewModel: StudentViewModel }): ReactElement => {
		const navigate = useNavigate();
		const theme = useTheme();
		const classes = useStyles({ theme });
		const { itn } = useItn(itns);
		// const items = [
		//   { id: "1", name: "Engenharia da Computação" },
		//   { id: "2", name: "Engenharia Civil" },
		// ];
		// const [selectedItem, setSelectedItem] = useState(items[0]);
		// const yearsItems = [
		//   { id: "1", name: itn.header.dropDownSchoolPeriodActual },
		//   { id: "2", name: itn.header.dropDownSchoolPeriodAll },
		// ];
		// const [selectedYearsItems, setSelectedYearsItems] = useState(
		//   items[0]
		// );
		const [firstRender, setFirstRender] = React.useState(true);

		useEffect(() => {
			setTimeout(() => {
				setFirstRender(false);
			}, 10);
		}, []);
		return (
			<FlexBox
				className={className([
					classes.container,
					!firstRender ? classes.containerInitialized : null,
				])}
				flex={1}
				column
				gap={styles.size(1)}>
				{/* region breadcrumbs */}
				<BreadCrumbs
					items={[
						{
							text: itn.breadcrumbs.subjects,
						},
					]}
				/>
				{/* endregion */}
				<FlexBox flex={1} gap={styles.size(1)} column>
					{/* region header */}
					<FlexBox gap={styles.size(1)}>
						<Card
							flex={1}
							style={{
								padding: `${styles.size(1)} ${styles.size(1)}`,
							}}>
							<FlexBox
								mainAxisAlignment="space-between"
								wrap
								gap={styles.size(1)}>
								<FlexBox
									crossAxisAlignment="center"
									gap={styles.size(1)}>
									<div
										style={{
											whiteSpace: "nowrap",
											fontSize: styles.size(1),
										}}>
										{itn.header.dropDownCourseTitle}
									</div>

									<DropDown
										style={{
											width: "14rem",
											flexShrink: 1,
										}}
										selected={viewModel.courseSelected?.id}
										items={
											viewModel.courses?.map((course) => {
												return {
													id: course.id,
													name: course.name,
												};
											}) ?? []
										}
										displayValuePath="name"
										onChange={(item: any) =>
											(viewModel.courseSelected =
												viewModel.courses?.find(
													(e) => e.id === item.id
												))
										}
									/>
								</FlexBox>

								<FlexBox
									crossAxisAlignment="center"
									gap={styles.size(1)}>
									<div
										style={{
											whiteSpace: "nowrap",
											fontSize: styles.size(1),
										}}>
										{itn.header.dropDownSchoolPeriodTitle}
									</div>

									<DropDown
										style={{
											width: "14rem",
											flexShrink: 1,
										}}
										selected={viewModel.gradeSelected?.id}
										items={
											viewModel.grades
												? [
														{
															id: undefined,
															name: itn.header
																.dropDownSchoolPeriodAll,
														},
														...viewModel.grades?.map(
															(grade) => {
																return {
																	id: grade.id,
																	name: grade.name,
																};
															}
														),
												  ]
												: []
										}
										displayValuePath="name"
										onChange={(item: any) =>
											(viewModel.gradeSelected =
												viewModel.grades?.find(
													(e) => e.id === item.id
												))
										}
									/>
								</FlexBox>
							</FlexBox>
						</Card>
					</FlexBox>
					{/* endregion */}
					{/* region cards */}
					{viewModel.loading ? (
						<Loading />
					) : (
						<div
							style={{
								display: "grid",
								gridTemplateColumns:
									"repeat(auto-fill, minmax(350px, 1fr))",
								gridGap: styles.size(1),
							}}>
							{viewModel.subjects ? (
								viewModel.subjects.map((subject) => (
									<Card
										key={subject.id}
										onClick={() => {
											viewModel.subjectSelected = subject;
											navigate(
												`/students/details?subjectId=${subject.id}`
											);
										}}
										flex={1}
										className={classes.subjectCard}>
										<FlexBox
											flex={1}
											crossAxisAlignment="stretch"
											gap={styles.size(2)}>
											<div
												className={classes.image}
												style={{
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
												}}>
												<Icon
													className={classes.icon}
													size={styles.size(12)}
													name={icons.counterFill}
												/>
											</div>
											<div
												className={classes.description}>
												{subject.name}
											</div>
										</FlexBox>
									</Card>
								))
							) : (
								<div>Este período não possui aulas</div>
							)}
						</div>
					)}
					{/* endregion */}
				</FlexBox>
			</FlexBox>
		);
	}
);

// region styles
const useStyles = createUseStyles({
	container: {
		// transition: "all 0.2s ease-in-out",
		// transform: "translateY(.5%) scale(0.995)",
		// opacity: 0.95,
	},
	containerInitialized: {
		// transform: "translateY(0%) scale(1)",
		// opacity: 1
	},
	image: {
		backgroundColor: ({ theme }: any) =>
			styles.blend(theme.backgroundAlt, theme.background, 0.5),
		borderRadius: styles.size(1),
		flex: 1,
		border: ({ theme }: any) =>
			`1px solid ${styles.blend(
				theme.backgroundAlt,
				theme.background,
				0.75
			)}`,
	},
	icon: {
		color: ({ theme }: any) => theme.elementBlue,
	},
	description: {
		flex: 1,
	},
	subjectCard: {
		height: styles.size(20),
		cursor: "pointer",
		transition: "all 0.2s ease-in-out",
		"&:hover": {
			backgroundColor: ({ theme }: any) =>
				styles.blend(theme.backgroundAlt, theme.background, 0.05),
			boxShadow: ({ theme }: any) =>
				`0px 5px 7px 3.5px rgba(0,0, 0, 0.1)`,
			transform: "translateY(-2px) scale(1.01)",
		},
		"&:active": {
			backgroundColor: ({ theme }: any) =>
				styles.blend(theme.backgroundAlt, theme.background, 0.15),
		},
	},
});
// endregion

// region export
export default function StudentSubjectsList(): ReactElement {
	const viewModel: StudentViewModel = useOutletContext();
	return <StudentSubjectsListView viewModel={viewModel} />;
}
// endregion

// // region export
// export default function SubjectsList() {
//     const store = useContext(StoreContext);
//     // const location = useLocation();
//     // const [searchParams] = useSearchParams();
//     const {notify} = useNotifications()
//     // const language = useContext(LanguageContext);
//     // const itn = itns[language];
//
//     const viewModel: SubjectsViewModel = useMemo(() => new SubjectsViewModel(), []);
//
//     // viewModel.notification = (notification, status) => notify(tt("auth.notFound"), status);
//
//     useEffect(() => {
//         viewModel.onInitialized(store);
//     }, [store, viewModel])
//
//     return <SubjectsListView viewModel={viewModel}/>
// }
// // endregion
