type iconProps = {
    width?: number | string,
    height?: number | string,
    color?: string,
    onClick?: () => void;
    className?: string;
}

export default function Logo(props: iconProps) {
    const color = props.color ?? "#000000";
    return (
        <svg
            onClick={props.onClick}
            className={props.className}
            style={{
                width: props.width ? typeof props.width === "string" ? props.width : `${props.width}px` : 137,
                height: props.height ? typeof props.height === "string" ? props.height : `${props.height}px` : 25,
            }}
            width="137"
            height="25"
            viewBox="0 0 137 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M9.06376 25C3.34169 25 0.320436 22.9138 0 18.7414H6.79782C6.86648 19.357 7.09537 19.8016 7.48447 20.0752C7.87357 20.3488 8.48011 20.4856 9.30408 20.4856C10.746 20.4856 11.467 20.1436 11.467 19.4596C11.467 18.9352 10.9635 18.5819 9.9564 18.3995L6.48883 17.9207C2.30027 17.3279 0.205994 15.4469 0.205994 12.2777C0.205994 10.3625 0.961308 8.90333 2.47193 7.90014C3.98256 6.87415 6.15695 6.36115 8.99509 6.36115C14.6256 6.36115 17.5668 8.36753 17.8185 12.3803H11.3984C11.3526 11.7875 11.1466 11.3657 10.7804 11.1149C10.4142 10.8413 9.81907 10.7045 8.99509 10.7045C7.64468 10.7045 6.96948 11.0237 6.96948 11.6621C6.96948 12.1865 7.47302 12.5399 8.48011 12.7223L11.9477 13.2011C14.1678 13.5203 15.7929 14.1359 16.8229 15.0479C17.8529 15.9599 18.3678 17.2025 18.3678 18.7756C18.3678 20.7592 17.5553 22.2982 15.9302 23.3926C14.3052 24.4642 12.0163 25 9.06376 25Z" fill={color}/>
            <path d="M32.1684 6.77155V11.6621H28.4948V17.5787C28.4948 18.2399 28.6322 18.6731 28.9068 18.8782C29.2044 19.0606 29.7422 19.1518 30.5204 19.1518H31.8251V24.4186C30.6807 24.5326 29.6736 24.5896 28.8038 24.5896C26.1488 24.5896 24.2376 24.1564 23.0703 23.29C21.9259 22.4236 21.3537 20.9986 21.3537 19.015V11.6621H18.7788V6.77155H21.3537V1.33379H28.4948V6.77155H32.1684Z" fill={color}/>
            <path d="M45.5897 16.5185V6.77155H52.7308V24.4186H45.727V21.8536H45.624C44.5254 23.8372 42.6943 24.829 40.1308 24.829C36.1025 24.829 34.0883 22.4578 34.0883 17.7155V6.77155H41.2295V16.5527C41.2295 18.1031 41.9047 18.8782 43.2551 18.8782C43.9417 18.8782 44.5025 18.6845 44.9374 18.2969C45.3723 17.8865 45.5897 17.2937 45.5897 16.5185Z" fill={color}/>
            <path d="M67.3988 9.06293V0.0684006H74.54V24.4186H67.6048V21.7852C66.4604 23.8144 64.6408 24.829 62.146 24.829C59.8114 24.829 57.9688 23.9854 56.6184 22.2982C55.2451 20.611 54.5585 18.3767 54.5585 15.5951C54.5585 12.7679 55.2451 10.5221 56.6184 8.85773C57.946 7.19334 59.7885 6.36115 62.146 6.36115C64.4806 6.36115 66.2315 7.26174 67.3988 9.06293ZM64.7209 19.357C66.7122 19.357 67.7078 18.1145 67.7078 15.6293C67.7078 13.1213 66.7122 11.8673 64.7209 11.8673C62.7067 11.8673 61.6996 13.1213 61.6996 15.6293C61.6996 18.1145 62.7067 19.357 64.7209 19.357Z" fill={color}/>
            <path d="M84.145 5.33516H77.0038V0H84.145V5.33516ZM84.145 24.4186H77.0038V6.77155H84.145V24.4186Z" fill={color}/>
            <path d="M98.1277 16.5185V6.77155H105.269V24.4186H98.265V21.8536H98.162C97.0634 23.8372 95.2323 24.829 92.6688 24.829C88.6405 24.829 86.6263 22.4578 86.6263 17.7155V6.77155H93.7674V16.5527C93.7674 18.1031 94.4427 18.8782 95.7931 18.8782C96.4797 18.8782 97.0405 18.6845 97.4754 18.2969C97.9102 17.8865 98.1277 17.2937 98.1277 16.5185Z" fill={color}/>
            <path d="M131.06 6.36115C132.892 6.36115 134.334 6.91975 135.386 8.03694C136.462 9.13133 137 10.7159 137 12.7907V24.4186H129.859V14.6375C129.859 13.0871 129.252 12.3119 128.039 12.3119C127.444 12.3119 126.941 12.5171 126.529 12.9275C126.14 13.3151 125.945 13.8965 125.945 14.6717V24.4186H118.804V14.6375C118.804 13.0871 118.197 12.3119 116.984 12.3119C116.389 12.3119 115.886 12.5171 115.474 12.9275C115.084 13.3151 114.89 13.8965 114.89 14.6717V24.4186H107.749V6.77155H114.753V9.78112H114.821C115.874 7.50114 117.682 6.36115 120.246 6.36115C122.786 6.36115 124.492 7.35294 125.361 9.33653H125.499C126.895 7.35294 128.749 6.36115 131.06 6.36115Z" fill={color}/>
        </svg>
    )
}