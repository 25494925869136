// region imports
import Card from "components/cards/card";
import { FlexBox } from "components/studium_ui";
import { createUseStyles, useTheme } from "react-jss";
import * as styles from "utils/styles";
import { useOutletContext } from "react-router-dom";
import { observer } from "mobx-react";
import BreadCrumbs from "components/breadcrumbs/breadcrumbs";
import ProfessorViewModel from "view_models/professors/professor_view_model";
import useWindowDimensions from "utils/useWindowDimensions";
import React, { useContext } from "react";
import { className } from "utils/className";
import { LanguageContext } from "utils/itn";
import { itns } from "pages/professors/texts/professor.itn";
// endregion

// region ProfessorsSubjects
// this page is the professor's students, here they can see the students enrolled in the grade and the students
const ProfessorSubjectView = observer(
	({ viewModel }: { viewModel: ProfessorViewModel }) => {
		// region properties
		const theme = useTheme();
		const classes = useStyles({ theme });
		// const navigate = useNavigate();
		const language = useContext(LanguageContext);
		const itn = itns[language];
		const { width } = useWindowDimensions();
		const days = [
			"sunday",
			"monday",
			"tuesday",
			"wednesday",
			"thursday",
			"friday",
			"saturday",
		];
		const now = new Date();
		const day = days[now.getDay()];
		// endregion

		// region render
		return (
			<FlexBox gap={styles.size(1)} column flex={1}>
				<BreadCrumbs
					homeRoute="/professors/"
					items={[
						{
							text: viewModel.courseSelected?.name,
							route: `/professors/courses`, //?courseId=${viewModel.courseSelected?.id}`
						},
						{
							text: viewModel.gradeSelected?.name,
							route: `/professors/grades`, //?gradeId=${viewModel.gradeSelected?.id}`
						},
						{
							text:
								viewModel.classroomSelected?.name &&
								`Classe ${
									viewModel.classroomSelected?.name ?? ""
								}`,
							route: "/professors/classrooms",
						},
						{
							text: viewModel.subjectSelected?.name,
						},
					]}
				/>
				<FlexBox gap={styles.size(1)} flex={1} direction="column">
					<FlexBox
						shrink={0}
						column={width <= 992}
						flex={1}
						gap={styles.size(1)}>
						<Card title={itn.subjects.nextEvents} flex={1}>
							<FlexBox
								flex={1}
								column
								gap={styles.size(1)}
								mainAxisAlignment="start"
								crossAxisAlignment="stretch">
								<FlexBox
									gap={styles.size(1)}
									mainAxisAlignment="center"
									crossAxisAlignment="center">
									<FlexBox
										className={classes.eventsBox}
										style={{ width: styles.size(8) }}
										mainAxisAlignment="center"
										crossAxisAlignment="center">
										07/08/2023
									</FlexBox>
									<FlexBox
										flex={1}
										className={classes.eventsBox}
										mainAxisAlignment="start"
										crossAxisAlignment="center">
										Entrega do trabalho 1
									</FlexBox>
								</FlexBox>
								<FlexBox
									gap={styles.size(1)}
									mainAxisAlignment="center"
									crossAxisAlignment="center">
									<FlexBox
										className={classes.eventsBox}
										style={{ width: styles.size(8) }}
										mainAxisAlignment="center"
										crossAxisAlignment="center">
										07/08/2023
									</FlexBox>
									<FlexBox
										flex={1}
										className={classes.eventsBox}
										mainAxisAlignment="start"
										crossAxisAlignment="center">
										Prova 1
									</FlexBox>
								</FlexBox>
								<FlexBox
									gap={styles.size(1)}
									mainAxisAlignment="center"
									crossAxisAlignment="center">
									<FlexBox
										className={classes.eventsBox}
										style={{ width: styles.size(8) }}
										mainAxisAlignment="center"
										crossAxisAlignment="center">
										07/08/2023
									</FlexBox>
									<FlexBox
										flex={1}
										className={classes.eventsBox}
										mainAxisAlignment="start"
										crossAxisAlignment="center">
										Entrega do trabalho 2
									</FlexBox>
								</FlexBox>
								<FlexBox
									gap={styles.size(1)}
									mainAxisAlignment="center"
									crossAxisAlignment="center">
									<FlexBox
										className={classes.eventsBox}
										style={{ width: styles.size(8) }}
										mainAxisAlignment="center"
										crossAxisAlignment="center">
										07/08/2023
									</FlexBox>
									<FlexBox
										flex={1}
										className={classes.eventsBox}
										mainAxisAlignment="start"
										crossAxisAlignment="center">
										Entrega do trabalho 3
									</FlexBox>
								</FlexBox>
								<FlexBox
									gap={styles.size(1)}
									mainAxisAlignment="center"
									crossAxisAlignment="center">
									<FlexBox
										className={classes.eventsBox}
										style={{ width: styles.size(8) }}
										mainAxisAlignment="center"
										crossAxisAlignment="center">
										07/08/2023
									</FlexBox>
									<FlexBox
										flex={1}
										className={classes.eventsBox}
										mainAxisAlignment="start"
										crossAxisAlignment="center">
										Prova 2
									</FlexBox>
								</FlexBox>
							</FlexBox>
						</Card>
						<Card title={itn.subjects.scheduleOfTheWeek} flex={1}>
							<FlexBox
								flex={1}
								column
								gap={styles.size(1)}
								mainAxisAlignment="start"
								crossAxisAlignment="stretch">
								<FlexBox
									gap={styles.size(1)}
									mainAxisAlignment="center"
									crossAxisAlignment="center">
									<FlexBox
										className={className([
											classes.eventsBox,
											day === "monday"
												? classes.eventBoxActive
												: null,
										])}
										style={{ width: styles.size(8) }}
										mainAxisAlignment="center"
										crossAxisAlignment="center">
										12am - 2pm
									</FlexBox>
									<FlexBox
										flex={1}
										className={className([
											classes.eventsBox,
											day === "monday"
												? classes.eventBoxActive
												: null,
										])}
										mainAxisAlignment="start"
										crossAxisAlignment="center">
										{itn.daysOfWeek.monday}
									</FlexBox>
								</FlexBox>
								<FlexBox
									gap={styles.size(1)}
									mainAxisAlignment="center"
									crossAxisAlignment="center">
									<FlexBox
										className={className([
											classes.eventsBox,
											day === "thursday"
												? classes.eventBoxActive
												: null,
										])}
										style={{ width: styles.size(8) }}
										mainAxisAlignment="center"
										crossAxisAlignment="center">
										12am - 2pm
									</FlexBox>
									<FlexBox
										flex={1}
										className={className([
											classes.eventsBox,
											day === "thursday"
												? classes.eventBoxActive
												: null,
										])}
										mainAxisAlignment="start"
										crossAxisAlignment="center">
										{itn.daysOfWeek.thursday}
									</FlexBox>
								</FlexBox>
								<FlexBox
									gap={styles.size(1)}
									mainAxisAlignment="center"
									crossAxisAlignment="center">
									<FlexBox
										className={className([
											classes.eventsBox,
											day === "wednesday"
												? classes.eventBoxActive
												: null,
										])}
										style={{ width: styles.size(8) }}
										mainAxisAlignment="center"
										crossAxisAlignment="center">
										12am - 2pm
									</FlexBox>
									<FlexBox
										flex={1}
										className={className([
											classes.eventsBox,
											day === "wednesday"
												? classes.eventBoxActive
												: null,
										])}
										mainAxisAlignment="start"
										crossAxisAlignment="center">
										{itn.daysOfWeek.wednesday}
									</FlexBox>
								</FlexBox>
								<FlexBox
									gap={styles.size(1)}
									mainAxisAlignment="center"
									crossAxisAlignment="center">
									<FlexBox
										className={className([
											classes.eventsBox,
											day === "tuesday"
												? classes.eventBoxActive
												: null,
										])}
										style={{ width: styles.size(8) }}
										mainAxisAlignment="center"
										crossAxisAlignment="center">
										12am - 2pm
									</FlexBox>
									<FlexBox
										flex={1}
										className={className([
											classes.eventsBox,
											day === "tuesday"
												? classes.eventBoxActive
												: null,
										])}
										mainAxisAlignment="start"
										crossAxisAlignment="center">
										{itn.daysOfWeek.tuesday}
									</FlexBox>
								</FlexBox>
								<FlexBox
									gap={styles.size(1)}
									mainAxisAlignment="center"
									crossAxisAlignment="center">
									<FlexBox
										className={className([
											classes.eventsBox,
											day === "friday"
												? classes.eventBoxActive
												: null,
										])}
										style={{ width: styles.size(8) }}
										mainAxisAlignment="center"
										crossAxisAlignment="center">
										12am - 2pm
									</FlexBox>
									<FlexBox
										flex={1}
										className={className([
											classes.eventsBox,
											day === "friday"
												? classes.eventBoxActive
												: null,
										])}
										mainAxisAlignment="start"
										crossAxisAlignment="center">
										{itn.daysOfWeek.friday}
									</FlexBox>
								</FlexBox>
							</FlexBox>
						</Card>
					</FlexBox>
					<FlexBox
						column={width <= 992}
						flex={1}
						gap={styles.size(1)}>
						<Card title="!TODO" flex={1}>
							<FlexBox
								flex={1}
								mainAxisAlignment="center"
								crossAxisAlignment="center">
								{/*<RadarChart />*/}
							</FlexBox>
						</Card>
						<Card title="!TODO" flex={1}>
							<FlexBox
								flex={1}
								mainAxisAlignment="center"
								crossAxisAlignment="center">
								{/*<Bar options={options(theme)} data={data2} />*/}
							</FlexBox>
						</Card>
					</FlexBox>
				</FlexBox>
			</FlexBox>
		);
		// endregion
	}
);
// endregion

// region style
const useStyles = createUseStyles({
	gradeItem: {
		color: ({ theme }: any) => theme.foregroundSecondary,
		border: ({ theme }: any) => `1px solid ${theme.foregroundSecondary}33`,
		borderRadius: styles.size(0.75),
		height: styles.size(4),
		paddingInline: styles.size(1),
		transition: "all 0.2s ease-in-out",
	},
	goBack: {
		cursor: "pointer",
	},
	title: {
		fontWeight: 500,
		color: ({ theme }: any) => theme.foreground,
		fontSize: styles.size(1.5),
		margin: 0,
	},
	item: {
		"&:hover": {
			"& > div": {
				backgroundColor: ({ theme }: any) =>
					theme.elementBackgroundSecondary,
			},
		},
	},
	eventsBox: {
		border: ({ theme }: any) =>
			`1px solid ${theme.elementBackgroundSecondary}`,
		borderRadius: styles.size(0.75),
		fontSize: styles.size(1),
		color: ({ theme }: any) => theme.foregroundSecondary,
		height: styles.size(3),
		paddingInline: styles.size(1),
		cursor: "pointer",
	},
	eventBoxActive: {
		backgroundColor: ({ theme }: any) => theme.elementBackgroundSecondary,
	},
});
// endregion

// region export
export default function ProfessorSubject() {
	const viewModel: ProfessorViewModel = useOutletContext();
	return <ProfessorSubjectView viewModel={viewModel} />;
}
// endregion
