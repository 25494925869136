import { daysOfWeek, ItnType, months } from "utils/itn";

type Type = {
	breadcrumbs: {
		calendar: string;
	};
	daysOfWeek: {
		sunday: string;
		monday: string;
		tuesday: string;
		wednesday: string;
		thursday: string;
		friday: string;
		saturday: string;
	};
	months: {
		january: string;
		february: string;
		march: string;
		april: string;
		may: string;
		june: string;
		july: string;
		august: string;
		september: string;
		october: string;
		november: string;
		december: string;
	};
	actions: {
		nextButton: string;
		previousButton: string;
	};
};

export const itns: ItnType<Type> = {
	ptBr: {
		breadcrumbs: {
			calendar: "Calendário",
		},
		daysOfWeek: {
			sunday: "Domingo",
			monday: "Segunda",
			tuesday: "Terça",
			wednesday: "Quarta",
			thursday: "Quinta",
			friday: "Sexta",
			saturday: "Sábado",
		},
		months: {
			january: "Janeiro",
			february: "Fevereiro",
			march: "Março",
			april: "Abril",
			may: "Maio",
			june: "Junho",
			july: "Julho",
			august: "Agosto",
			september: "Setembro",
			october: "Outubro",
			november: "Novembro",
			december: "Dezembro",
		},
		actions: {
			nextButton: "Próximo",
			previousButton: "Anterior",
		},
	},
	ptEu: {
		breadcrumbs: {
			calendar: "Calendário",
		},
		daysOfWeek: daysOfWeek.ptEu,
		months: months.ptEu,
		actions: {
			nextButton: "Próximo",
			previousButton: "Anterior",
		},
	},
	en: {
		breadcrumbs: {
			calendar: "Calendar",
		},
		daysOfWeek: {
			sunday: "Sunday",
			monday: "Monday",
			tuesday: "Tuesday",
			wednesday: "Wednesday",
			thursday: "Thursday",
			friday: "Friday",
			saturday: "Saturday",
		},
		months: {
			january: "January",
			february: "February",
			march: "March",
			april: "April",
			may: "May",
			june: "June",
			july: "July",
			august: "August",
			september: "September",
			october: "October",
			november: "November",
			december: "December",
		},
		actions: {
			nextButton: "Next",
			previousButton: "Previous",
		},
	},
	es: {
		breadcrumbs: {
			calendar: "Calendario",
		},
		daysOfWeek: {
			sunday: "Domingo",
			monday: "Lunes",
			tuesday: "Martes",
			wednesday: "Miércoles",
			thursday: "Jueves",
			friday: "Viernes",
			saturday: "Sábado",
		},
		months: {
			january: "Enero",
			february: "Febrero",
			march: "Marzo",
			april: "Abril",
			may: "Mayo",
			june: "Junio",
			july: "Julio",
			august: "Agosto",
			september: "Septiembre",
			october: "Octubre",
			november: "Noviembre",
			december: "Diciembre",
		},
		actions: {
			nextButton: "Siguiente",
			previousButton: "Anterior",
		},
	},
	fr: {
		breadcrumbs: {
			calendar: "Calendrier",
		},
		daysOfWeek: {
			sunday: "Dimanche",
			monday: "Lundi",
			tuesday: "Mardi",
			wednesday: "Mercredi",
			thursday: "Jeudi",
			friday: "Vendredi",
			saturday: "Samedi",
		},
		months: {
			january: "Janvier",
			february: "Février",
			march: "Mars",
			april: "Avril",
			may: "Mai",
			june: "Juin",
			july: "Juillet",
			august: "Août",
			september: "Septembre",
			october: "Octobre",
			november: "Novembre",
			december: "Décembre",
		},
		actions: {
			nextButton: "Suivant",
			previousButton: "Précédent",
		},
	},
	it: {
		breadcrumbs: {
			calendar: "Calendario",
		},
		daysOfWeek: {
			sunday: "Domenica",
			monday: "Lunedì",
			tuesday: "Martedì",
			wednesday: "Mercoledì",
			thursday: "Giovedì",
			friday: "Venerdì",
			saturday: "Sabato",
		},
		months: {
			january: "Gennaio",
			february: "Febbraio",
			march: "Marzo",
			april: "Aprile",
			may: "Maggio",
			june: "Giugno",
			july: "Luglio",
			august: "Agosto",
			september: "Settembre",
			october: "Ottobre",
			november: "Novembre",
			december: "Dicembre",
		},
		actions: {
			nextButton: "Successivo",
			previousButton: "Precedente",
		},
	},
	de: {
		breadcrumbs: {
			calendar: "Kalender",
		},
		daysOfWeek: {
			sunday: "Sonntag",
			monday: "Montag",
			tuesday: "Dienstag",
			wednesday: "Mittwoch",
			thursday: "Donnerstag",
			friday: "Freitag",
			saturday: "Samstag",
		},
		months: {
			january: "Januar",
			february: "Februar",
			march: "März",
			april: "April",
			may: "Mai",
			june: "Juni",
			july: "Juli",
			august: "August",
			september: "September",
			october: "Oktober",
			november: "November",
			december: "Dezember",
		},
		actions: {
			nextButton: "Weiter",
			previousButton: "Zurück",
		},
	},
};
