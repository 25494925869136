import React from "react";

type iconProps = {
    width?: number,
    height?: number,
    onClick?: () => void;
    color?: string;
    style?: React.CSSProperties;
    className?: string;
}

export default function BookIcon(props: iconProps) {
    return (
        <svg
            style={props.style}
            className={props.className}
            width={props.width ?? 24}
            height={props.height ?? 24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M12.6424 3.81015C13.0442 3.55939 13.5355 3.5159 13.9697 3.68144L14.1121 3.74436L21.5556 7.50517C22.2205 7.84112 22.5196 8.59793 22.3192 9.28014C21.9281 10.6114 21.9721 11.1694 22.2271 11.8013C22.4671 12.3965 22.3275 13.1426 21.7663 13.5823L21.6396 13.6717L11.3564 20.181C10.9248 20.4542 10.376 20.4962 9.89932 20.263L6.059 18.3681L4.45464 17.5631L3.31468 16.9808L2.61933 16.6161L2.25889 16.4203L1.99781 16.2704C0.978566 15.6551 0.661207 14.4053 0.665696 13.4489C0.670452 12.4776 1.00403 11.2651 1.93049 10.5984L2.2312 10.392L3.08866 9.83087L5.13394 8.52495L12.6424 3.81015ZM20.0332 10.6884L11.36 16.1786C11.2896 16.2232 11.2163 16.2614 11.141 16.2932C11.0309 16.7007 10.9926 16.9943 10.9966 17.2664C10.999 17.4286 11.0164 17.5941 11.0522 17.7828L11.0943 17.9799L20.2418 12.1895C20.1088 11.7745 20.0405 11.3693 20.0315 10.9453L20.0332 10.6884ZM2.79948 12.678C2.72205 12.8835 2.66726 13.147 2.66571 13.4585C2.66255 14.1302 2.90327 14.4809 3.03141 14.5582L3.33876 14.7328L3.96506 15.066L5.73456 15.9692L9.01142 17.5987C9.00327 17.4983 8.99832 17.3977 8.9968 17.2963C8.9899 16.8334 9.05447 16.3823 9.17769 15.8951L2.79948 12.678ZM19.4574 8.68585L13.4616 5.65648L6.70237 9.89878L4.50366 11.2975L10.5298 14.3371L19.4574 8.68585ZM12.6142 7.33611C12.8521 6.88183 13.3902 6.68654 13.8576 6.86613L13.964 6.91428L16.064 8.01429C16.5532 8.27055 16.7421 8.87489 16.4858 9.36412C16.2479 9.81841 15.7097 10.0137 15.2424 9.8341L15.136 9.78595L13.036 8.68595C12.5468 8.42968 12.3579 7.82534 12.6142 7.33611Z" fill="black"/>
</svg>

)
}

