import { Response } from "domains/response";

const baseUrls = {
	local: "http://localhost:7070",
	production: "https://api.studium.academy",
};

export function requests(baseUrl = baseUrls.production) {
	function handleUrl(url: string): string {
		return url.startsWith("/") ? baseUrl + url : url;
	}
	return {
		get: async function <T>(
			url: string,
			token: string | undefined = undefined
		): Promise<Response<T>> {
			const response = await fetch(handleUrl(url), {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: token || "",
				},
			});
			const json = await response.json();
			return {
				status: response.status,
				payload: await json.payload,
				message: await json.message,
			};
		},
		put: async function <T>(
			url: string,
			body: any,
			token: string | undefined = undefined
		): Promise<Response<T>> {
			const response = await fetch(handleUrl(url), {
				method: "PUT",
				body: JSON.stringify(body),
				headers: {
					"Content-Type": "application/json",
					Authorization: token || "",
				},
			});
			const json = await response.json();
			return {
				status: response.status,
				payload: await json.payload,
				message: await json.message,
			};
		},
		putFormData: async function <T>(
			url: string,
			body: FormData,
			token: string | undefined = undefined
		): Promise<Response<T>> {
			const response = await fetch(handleUrl(url), {
				method: "PUT",
				body: body,
				headers: {
					// "Content-Type": "multipart/form-data",
					Authorization: token || "",
				},
			});
			const json = await response.json();
			return {
				status: response.status,
				payload: await json.payload,
				message: await json.message,
			};
		},
		post: async function <T>(
			url: string,
			body: any,
			token: string | undefined = undefined
		): Promise<Response<T>> {
			const response = await fetch(handleUrl(url), {
				method: "POST",
				body: JSON.stringify(body),
				headers: {
					"Content-Type": "application/json",
					"Access-Control-Allow-Origin": "*",
					...(token ? { Authorization: token } : {}),
				},
			});
			let json = await response.json();
			return {
				status: response.status,
				payload: await json.payload,
				message: await json.message,
			};
			// .then((response) => response.json());
		},
		delete: async function <T>(url: string): Promise<Response<T>> {
			const response = await fetch(handleUrl(url), {
				method: "DELETE",
			});
			const json = await response.json();
			return {
				status: response.status,
				payload: await json.payload,
				message: await json.message,
			};
		},
	};
}
