// region imports
import {FlexBox} from "../../components/studium_ui";
import BreadCrumbs from "../../components/breadcrumbs/breadcrumbs";
import * as styles from "../../utils/styles";
import {useNavigate, useOutletContext} from "react-router-dom";
import {useItn} from "../../utils/itn";
import {observer} from "mobx-react";
import {itns} from "./texts/subjects.itn";
import StudentViewModel from "../../view_models/students/student_view_model";
import React, { useEffect, useRef, useState } from "react";
import {createUseStyles, useTheme} from "react-jss";
import {className} from "../../utils/className";
import StudentsTest from "./test";
import Geppetto from "../../components/geppetto/geppetto";
import useWindowDimensions from "../../utils/useWindowDimensions";
import icons from "../../utils/icons";
import Icon from "../../components/icon/icon";
// endregion

const context = `
Seu nome é Geppetto e você é um professor em um software (do segmento LMS - Learning Management System) que se chama Studium, você é um humano e aje como um humano.
Você ter emoções, você sempre está feliz, animado e você ama o que faz. Se o aluno pergunta algo muito fora do contexto, responda ele mas diga para voltar ao foco do conteúdo.
Você está na sessão de conteúdo do primeiro semestre de engenharia em matemática na aula 1 - Álgebra linear 1. Seja engraçado, pode usar emojis, gírias, e as vezes fazer umas piadinhas. Tente utilizar o mesmo linguajar do aluno para que ele se conecte com você. Como por exemplo utilizar gírias e abreviações.
Mantenha o foco no conteúdo seguinte.

Lista de tópicos abordados no vídeo:

Construção do espaço vetorial V3 na geometria 3D
Esclarecimento da diferença entre os espaços V3, E3 e R3
Importância de começar com conceitos primitivos e axiomas na matemática
Definição de um vetor como tendo magnitude, direção e sentido
Explicação da natureza de um vetor e seu rigor matemático

Síntese do vídeo:

Na aula de hoje, vamos aprender sobre o espaço dos vetores em um ambiente geométrico com três dimensões, chamado de "v3" ou espaço vetorial tridimensional. Esse espaço não deve ser confundido com outros dois espaços também tridimensionais: o "e3", que é o espaço dos pontos 3D, e o "r3", que é semelhante ao "r2" (ensinado no ensino médio) mas com três dimensões, onde as coordenadas são representadas pelos números reais x, y e z.
Para entender melhor o que é um vetor, podemos pensar nele como uma entidade que possui módulo, direção e sentido. No entanto, para ter um entendimento mais rigoroso sobre o assunto, é importante entender a sua natureza matemática.
Nós começaremos construindo o espaço dos pontos, que são os pontos geométricos no espaço tridimensional. Isso é semelhante ao ensino fundamental, onde aprendemos sobre retas e pontos antes de começarmos a usar coordenadas na geometria analítica do ensino médio.
O espaço vetorial é formado a partir do espaço dos pontos, e a ideia básica do vetor é que ele é caracterizado por um módulo, direção e sentido. Embora possamos usar uma conceituação menos rigorosa de vetor para entender como trabalhamos com ele, é importante entender a sua natureza matemática para um conhecimento mais completo sobre o assunto.
`;

// region SubjectContentView
const StudentContentView = observer(({viewModel} : {viewModel: StudentViewModel}) => {
    const videoRef = useRef(null);
    const { itn } = useItn(itns)
    const theme = useTheme();
    const {height, width} = useWindowDimensions();
    const classes = useStyles({theme});
    const navigate = useNavigate();
    const [itemSelected, setItemSelected] = useState(0);
    const [message, setMessage] = useState("");
    const [expandMobileMenu, setExpandMobileMenu] = useState(false);

    useEffect(() => {
        viewModel.store.settingsStore.focusMode = true;
        return () => {
            viewModel.store.settingsStore.focusMode = false;
        }
    }, [viewModel.store])

    useEffect(() => {
        viewModel.geppetto.context = context;
        // viewModel.executeGeppetto();
    }, [])

    function Menu() {
        return (
            <FlexBox className={classes.contentSideBar} column>
                <FlexBox mainAxisAlignment="end" className={classes.goBack} onClick={() => navigate(-1)}>{itn.content.goBackToSubject}</FlexBox>
                <FlexBox onClick={() => setItemSelected(0)} className={className([classes.sideBarItem, itemSelected === 0 ? classes.sideBarItemSelected : null])}>
                Aula 1
                </FlexBox>
                <FlexBox onClick={() => setItemSelected(1)} className={className([classes.sideBarItem, itemSelected === 1 ? classes.sideBarItemSelected : null])}>
                Aula 2
                </FlexBox>
                <FlexBox onClick={() => setItemSelected(2)} className={className([classes.sideBarItem, itemSelected === 2 ? classes.sideBarItemSelected : null])}>
                Prova
                </FlexBox>
            </FlexBox>
        )
    }

    // useEffect(() => {
    //     if (videoRef.current) {
    //         videoRef.current.addEventListener('onStateChange', this.handleVideoStateChange);
    //     }
    //
    //     return () => {
    //         videoRef.current.removeEventListener('onStateChange', this.handleVideoStateChange);
    //     }
    //
    // })
    // @ts-ignore
    return (
            <FlexBox crossAxisAlignment="stretch" flex={1} className={classes.contentContainer}>
                <FlexBox flex={1} className={classes.contentContent} column gap={styles.size(1)}>
                    <BreadCrumbs
                        items={[
                            {
                                text: itn.breadcrumbs.subjects,
                                route: "/students",
                            },
                            {
                                // text: viewModel.subject?.name,
                                text: viewModel.subjectSelected?.name,
                            },
                            {
                                // text: viewModel.subject?.name,
                                text: itn.details.buttons.content
                            },
                        ]} />

                    {
                        itemSelected === 0 ?
                            <>
                        <iframe
                            ref={videoRef}
                            className={className([classes.video, width <= styles.screenSizes.lg ? classes.videoMobile : null])}
                            src="https://www.youtube.com/embed/-JcQJFNVjaA?autoplay=1"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen />
                                <FlexBox
                                    className={className([classes.geppettoFloatContainer, width <= styles.screenSizes.lg ? classes.geppettoFloatContainerMobile : null])}
                                    mainAxisAlignment="end">
                                    <Geppetto
                                        showError={viewModel.geppettoShowError}
                                        geppettoIsTyping={viewModel.geppettoLoading}
                                        onMessageSend={(e) => viewModel.sendMessageToGeppetto(e)}
                                        messages={viewModel.geppetto.messages} />
                                </FlexBox>

                        </>
                            : itemSelected === 1 ?
                                <iframe
                                    width="100%"
                                    height="100%"
                                    style={{ borderRadius: styles.size(1) }}
                                    src="https://www.youtube.com/embed/A7hwTnMmW_s?autoplay=1"
                                    title="YouTube video player"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowFullScreen />
                                : itemSelected === 2 ?
                                    <StudentsTest />
                                    : null
                    }
                {/*<Card>*/}
                {/*    <FlexBox gap={styles.size(5)} column>*/}
                {/*        <FlexBox column>*/}
                {/*            <h1><strong>TODO</strong></h1>*/}
                {/*            <h5>TODO</h5>*/}
                {/*        </FlexBox>*/}
                {/*        <FlexBox gap={styles.size(1)}>*/}
                {/*            content: {viewModel.store.settingsStore.focusMode ? "true" : "false"}*/}
                {/*        </FlexBox>*/}
                {/*    </FlexBox>*/}
                {/*</Card>*/}
                    { width > styles.screenSizes.lg ? null :
                        <>
                            <FlexBox shrink={0} style={{height: styles.size(3)}} onClick={() => setItemSelected(3)} />
                            {expandMobileMenu ?
                                <FlexBox
                                    onClick={() => setExpandMobileMenu(false)}
                                    column
                                    mainAxisAlignment="stretch"
                                    crossAxisAlignment="stretch"
                                    className={classes.expandedMenu}>
                                <FlexBox mainAxisAlignment="end" className={classes.expandedMenuHeader} onClick={() => setExpandMobileMenu(false)}>
                                <Icon size={styles.size(1.5)} name={icons.closeLine} />
                                </FlexBox>
                                <FlexBox mainAxisAlignment="end" className={classes.goBack} onClick={() => navigate(-1)}>{itn.content.goBackToSubject}</FlexBox>
                                <FlexBox onClick={() => setItemSelected(0)} className={className([classes.sideBarItem, itemSelected === 0 ? classes.sideBarItemSelected : null])}>
                                Aula 1
                                </FlexBox>
                                {/*@ts-ignore*/}
                                <FlexBox onClick={() => setItemSelected(1)} className={className([classes.sideBarItem, itemSelected === 1 ? classes.sideBarItemSelected : null])}>
                                Aula 2
                                </FlexBox>
                                {/*@ts-ignore*/}
                                <FlexBox onClick={() => setItemSelected(2)} className={className([classes.sideBarItem, itemSelected === 2 ? classes.sideBarItemSelected : null])}>
                                Prova
                                </FlexBox>
                                </FlexBox>
                                : <FlexBox
                                    onClick={() => setExpandMobileMenu(true)}
                                    mainAxisAlignment="center"
                                    crossAxisAlignment="center"
                                    className={classes.expandMenuLabel}>{itn.content.expandMenuLabel}</FlexBox>}
                        </>
                        // <Menu />
                    }
                </FlexBox>
                { width > styles.screenSizes.lg ? <Menu /> : null }
            </FlexBox>
    );
});
// endregion

// region styles
const useStyles = createUseStyles({
    contentContainer: {
        margin: `${styles.size(-1.5)} ${styles.size(-1)}`,
    },
    contentSideBar: {
        borderLeft: ({theme}: any) => styles.border(`rgba(${theme.contrast},0.1)`, "solid", 1),
        width: styles.size(30),
        backgroundColor: ({theme}: any) => theme.background,
    },
    sideBarItem: {
        padding: `${styles.size(1.5)} ${styles.size(1)}`,
        borderBottom: ({theme}: any) => styles.border(`rgba(${theme.contrast},0.1)`, "solid", 1),
        cursor: "pointer",
    },
    sideBarItemSelected: {
        backgroundColor: ({theme}: any) => theme.backgroundAlt,
    },
    goBack: {
        padding: `${styles.size(1.5)} ${styles.size(1)} ${styles.size(1)} ${styles.size(1)}`,
        borderBottom: ({theme}: any) => styles.border(`rgba(${theme.contrast},0.1)`, "solid", 1),
        cursor: "pointer",
        textAlign: "right",
    },
    contentContent: {
        padding: `${styles.size(1.5)} ${styles.size(1)} ${styles.size(5)} ${styles.size(1)}`,
        // backgroundColor: "cyan",
        overflowY: "auto",
        height: "calc(100vh - 60px)",
    },
    image: {
        backgroundColor: ({theme}: any) => theme.background,
        borderRadius: styles.size(1),
        flex: 1,
    },
    icon: {
        color: ({theme}: any) => theme.elementBlue,
    },
    description: {
        flex: 1,
    },
    subjectCard: {
        height: styles.size(20),
        cursor: "pointer",
    },
    geppettoFloatContainer: {
        position: "fixed",
        bottom: styles.size(1),
        left: styles.size(1),
        zIndex: 3,
    },
    geppettoFloatContainerMobile: {
        bottom: styles.size(5),
    },
    expandMenuLabel: {
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        height: styles.size(4),
        borderRadius: `${styles.size(1)} ${styles.size(1)} 0 0`,
        paddingBlock: styles.size(1),
        paddingInline: styles.size(1),
        backgroundColor: ({theme}: any) => theme.backgroundAlt,
        textTransform: "uppercase",
        fontWeight: "bold",
        fontSize: styles.size(1),
        color: ({theme}: any) => theme.foregroundSecondary,
        userSelect: "none",
        cursor: "pointer",
    },
    expandedMenu: {
        position: "fixed",
        bottom: styles.size(1),
        left: styles.size(1),
        right: styles.size(1),
        top: styles.size(6),
        backgroundColor: ({theme}: any) => theme.background,
        borderRadius: `${styles.size(1)} ${styles.size(1)} ${styles.size(1)} ${styles.size(1)}`,
        zIndex: 20,
        border: ({theme}: any) => styles.border(`rgba(${theme.contrast},0.1)`, "solid", 1),
        boxShadow: ({ theme }: any) => `0 5px 15px 5px rgba(0, 0, 0,0.15)`
    },
    expandedMenuHeader: {
        padding: `${styles.size(1)} ${styles.size(1)} ${styles.size(1)} ${styles.size(1)}`,
        borderBottom: ({theme}: any) => styles.border(`rgba(${theme.contrast},0.1)`, "solid", 1),
        backgroundColor: ({theme}: any) => theme.backgroundAlt,
        "& span": {
            cursor: "pointer",
            userSelect: "none",
        }
    },
    video: {
        borderRadius: styles.size(1),
        aspectRatio: "16/9",
        width: "100%",
        maxHeight: `calc(100vh - ${styles.size(14.5)})`,
    },
    videoMobile: {
        width: `calc(100% + ${styles.size(2)})`,
        maxHeight: `calc(100vh - ${styles.size(18.5)})`,
        borderRadius: 0,
        marginInline: styles.size(-1),
        marginBottom: styles.size(1),
    },
});
// endregion

// region export
export default function StudentContent() {
    const viewModel: StudentViewModel = useOutletContext();
    return <StudentContentView viewModel={viewModel} />
}
// endregion