import {ItnLanguage, ItnMessagesType, ItnType} from "../../../utils/itn";

type Type = {
    breadcrumbs: {
        subjects: string;
        details: string;
    },
    header: {
        dropDownCourseTitle: string;
        dropDownSchoolPeriodTitle: string;
        dropDownSchoolPeriodActual: string;
        dropDownSchoolPeriodAll: string;
    },
    details: {
        buttons: {
            content: string;
            schedule: string;
            reports: string;
        }
        tabView: {
            aboutTheSubject: string;
            calendar: string;
            discussions: string;
            gradeBook: string;
            announcements: string;
            messages: string;
            analytics: string;
        }
        aboutTheSubject: {
            courseFaculty: string;
            classRoster: string;
            courseDescription: string;
            courseGroups: string;
            courseMaterials: string;
            courseSettings: string;
            courseStatistics: string;
            courseTools: string;
            questionBanks: string;
        }
    },
    content: {
        goBackToSubject: string;
        expandMenuLabel: string;
        geppetto: {
            chatWithGeppetto: string;
            askGeppettoSomething: string;
            errorTryAgain: string;
            typing: string;
        }
    }
}

export const itns: ItnType<Type> = {
    ptBr: {
        breadcrumbs: {
            subjects: 'Disciplinas',
            details: 'Detalhes',
        },
        header: {
            dropDownCourseTitle: 'Curso',
            dropDownSchoolPeriodTitle: 'Período letivo',
            dropDownSchoolPeriodActual: 'Atual',
            dropDownSchoolPeriodAll: 'Todos',
        },
        details: {
            buttons: {
                content: 'Conteúdo',
                schedule: 'Horário',
                reports: 'Relatórios',
            },
            tabView: {
                aboutTheSubject: 'Sobre a disciplina',
                calendar: 'Calendário',
                discussions: 'Discussões',
                gradeBook: 'Boletim',
                announcements: 'Anúncios',
                messages: 'Mensagens',
                analytics: 'Análises',
            },
            aboutTheSubject: {
                courseFaculty: 'Coordenador do curso',
                classRoster: 'Lista da turma',
                courseDescription: 'Descrição do curso',
                courseGroups: 'Grupos do curso',
                courseMaterials: 'Materiais do curso',
                courseSettings: 'Configurações do curso',
                courseStatistics: 'Estatísticas do curso',
                courseTools: 'Ferramentas do curso',
                questionBanks: 'Bancos de questões',
            },
        },
        content: {
            goBackToSubject: 'Voltar para a disciplina',
            expandMenuLabel: 'Expandir menu',
            geppetto: {
                chatWithGeppetto: 'Converse com o Geppetto',
                askGeppettoSomething: 'Pergunte algo ao Geppetto',
                errorTryAgain: 'Ocorreu um erro, tente novamente',
                typing: 'Digitando',
            },
        },
    },
    ptEu: {
        breadcrumbs: {
            subjects: 'Disciplinas',
            details: 'Detalhes',
        },
        header: {
            dropDownCourseTitle: 'Curso',
            dropDownSchoolPeriodTitle: 'Período letivo',
            dropDownSchoolPeriodActual: 'Atual',
            dropDownSchoolPeriodAll: 'Todos',
        },
        details: {
            buttons: {
                content: 'Conteúdo',
                schedule: 'Horário',
                reports: 'Relatórios',
            },
            tabView: {
                aboutTheSubject: 'Sobre a disciplina',
                calendar: 'Calendário',
                discussions: 'Discussões',
                gradeBook: 'Boletim',
                announcements: 'Anúncios',
                messages: 'Mensagens',
                analytics: 'Análises',
            },
            aboutTheSubject: {
                courseFaculty: 'Coordenador do curso',
                classRoster: 'Lista da turma',
                courseDescription: 'Descrição do curso',
                courseGroups: 'Grupos do curso',
                courseMaterials: 'Materiais do curso',
                courseSettings: 'Configurações do curso',
                courseStatistics: 'Estatísticas do curso',
                courseTools: 'Ferramentas do curso',
                questionBanks: 'Bancos de questões',
            },
        },
        content: {
            goBackToSubject: 'Voltar para a disciplina',
            expandMenuLabel: 'Expandir menu',
            geppetto: {
                chatWithGeppetto: 'Converse com o Geppetto',
                askGeppettoSomething: 'Pergunte algo ao Geppetto',
                errorTryAgain: 'Ocorreu um erro, tente novamente',
                typing: 'Digitando',
            },
        },
    },
    en: {
        breadcrumbs: {
            subjects: 'Subjects',
            details: 'Details',
        },
        header: {
            dropDownCourseTitle: 'Course',
            dropDownSchoolPeriodTitle: 'School period',
            dropDownSchoolPeriodActual: 'Actual',
            dropDownSchoolPeriodAll: 'All',
        },
        details: {
            buttons: {
                content: 'Content',
                schedule: 'Schedule',
                reports: 'Reports',
            },
            tabView: {
                aboutTheSubject: 'About the subject',
                calendar: 'Calendar',
                discussions: 'Discussions',
                gradeBook: 'Grade book',
                announcements: 'Announcements',
                messages: 'Messages',
                analytics: 'Analytics',
            },
            aboutTheSubject: {
                courseFaculty: 'Course Faculty',
                classRoster: 'Class Roster',
                courseDescription: 'Course Description',
                courseGroups: 'Course Groups',
                courseMaterials: 'Course Materials',
                courseSettings: 'Course Settings',
                courseStatistics: 'Course Statistics',
                courseTools: 'Course Tools',
                questionBanks: 'Question Banks',
            },
        },
        content: {
            goBackToSubject: 'Go back to subject',
            expandMenuLabel: 'Expand menu',
            geppetto: {
                chatWithGeppetto: 'Chat with Geppetto',
                askGeppettoSomething: 'Ask something to Geppetto',
                errorTryAgain: 'An error occurred, try again',
                typing: 'Typing',
            },
        },
    },
    es: {
        breadcrumbs: {
            subjects: 'Asignaturas',
            details: 'Detalles',
        },
        header: {
            dropDownCourseTitle: 'Curso',
            dropDownSchoolPeriodTitle: 'Período escolar',
            dropDownSchoolPeriodActual: 'Actual',
            dropDownSchoolPeriodAll: 'Todos',
        },
        details: {
            buttons: {
                content: 'Contenido',
                schedule: 'Horario',
                reports: 'Informes',
            },
            tabView: {
                aboutTheSubject: 'Sobre la asignatura',
                calendar: 'Calendario',
                discussions: 'Discusiones',
                gradeBook: 'Libro de calificaciones',
                announcements: 'Anuncios',
                messages: 'Mensajes',
                analytics: 'Análisis',
            },
            aboutTheSubject: {
                courseFaculty: 'Coordinador del curso',
                classRoster: 'Lista de clase',
                courseDescription: 'Descripción del curso',
                courseGroups: 'Grupos del curso',
                courseMaterials: 'Materiales del curso',
                courseSettings: 'Configuración del curso',
                courseStatistics: 'Estadísticas del curso',
                courseTools: 'Herramientas del curso',
                questionBanks: 'Bancos de preguntas',
            },
        },
        content: {
            goBackToSubject: 'Volver a la asignatura',
            expandMenuLabel: 'Expandir menú',
            geppetto: {
                chatWithGeppetto: "Chatea con Geppetto",
                askGeppettoSomething: 'Pregúntale algo a Geppetto',
                errorTryAgain: 'Ocurrió un error, inténtalo de nuevo',
                typing: 'Escribiendo',
            },
        },
    },
    fr: {
        breadcrumbs: {
            subjects: 'Matières',
            details: 'Détails',
        },
        header: {
            dropDownCourseTitle: 'Cours',
            dropDownSchoolPeriodTitle: 'Période scolaire',
            dropDownSchoolPeriodActual: 'Actuel',
            dropDownSchoolPeriodAll: 'Tous',
        },
        details: {
            buttons: {
                content: 'Contenu',
                schedule: 'Horaire',
                reports: 'Rapports',
            },
            tabView: {
                aboutTheSubject: 'À propos de la matière',
                calendar: 'Calendrier',
                discussions: 'Discussions',
                gradeBook: 'Carnet de notes',
                announcements: 'Annonces',
                messages: 'Messages',
                analytics: 'Analyses',
            },
            aboutTheSubject: {
                courseFaculty: 'Coordinateur du cours',
                classRoster: 'Liste des élèves',
                courseDescription: 'Description du cours',
                courseGroups: 'Groupes du cours',
                courseMaterials: 'Matériaux du cours',
                courseSettings: 'Paramètres du cours',
                courseStatistics: 'Statistiques du cours',
                courseTools: 'Outils du cours',
                questionBanks: 'Banques de questions',
            },
        },
        content: {
            goBackToSubject: 'Retour à la matière',
            expandMenuLabel: 'Développer le menu',
            geppetto: {
                chatWithGeppetto: 'Parlez avec Geppetto',
                askGeppettoSomething: 'Demandez quelque chose à Geppetto',
                errorTryAgain: 'Une erreur s\'est produite, réessayez',
                typing: 'Tapez',
            },
        },
    },
    it: {
        breadcrumbs: {
            subjects: 'Materie',
            details: 'Dettagli',
        },
        header: {
            dropDownCourseTitle: 'Corso',
            dropDownSchoolPeriodTitle: 'Periodo scolastico',
            dropDownSchoolPeriodActual: 'Attuale',
            dropDownSchoolPeriodAll: 'Tutti',
        },
        details: {
            buttons: {
                content: 'Contenuto',
                schedule: 'Orario',
                reports: 'Rapporti',
            },
            tabView: {
                aboutTheSubject: 'Informazioni sulla materia',
                calendar: 'Calendario',
                discussions: 'Discussioni',
                gradeBook: 'Registro delle valutazioni',
                announcements: 'Annunci',
                messages: 'Messaggi',
                analytics: 'Analisi',
            },
            aboutTheSubject: {
                courseFaculty: 'Coordinatore del corso',
                classRoster: 'Elenco dei partecipanti',
                courseDescription: 'Descrizione del corso',
                courseGroups: 'Gruppi del corso',
                courseMaterials: 'Materiali del corso',
                courseSettings: 'Impostazioni del corso',
                courseStatistics: 'Statistiche del corso',
                courseTools: 'Strumenti del corso',
                questionBanks: 'Banche di domande',
            },
        },
        content: {
            goBackToSubject: 'Torna alla materia',
            expandMenuLabel: 'Espandi menu',
            geppetto: {
                chatWithGeppetto: 'Parla con Geppetto',
                askGeppettoSomething: 'Chiedi qualcosa a Geppetto',
                errorTryAgain: 'Si è verificato un errore, riprova',
                typing: 'Digitazione',
            },
        },
    },
    de: {
        breadcrumbs: {
            subjects: 'Fächer',
            details: 'Einzelheiten',
        },
        header: {
            dropDownCourseTitle: 'Kurs',
            dropDownSchoolPeriodTitle: 'Schulzeit',
            dropDownSchoolPeriodActual: 'Aktuell',
            dropDownSchoolPeriodAll: 'Alle',
        },
        details: {
            buttons: {
                content: 'Inhalt',
                schedule: 'Zeitplan',
                reports: 'Berichte',
            },
            tabView: {
                aboutTheSubject: 'Über das Fach',
                calendar: 'Kalender',
                discussions: 'Diskussionen',
                gradeBook: 'Notenbuch',
                announcements: 'Ankündigungen',
                messages: 'Nachrichten',
                analytics: 'Analytik',
            },
            aboutTheSubject: {
                courseFaculty: 'Kurskoordinator',
                classRoster: 'Klassenliste',
                courseDescription: 'Kursbeschreibung',
                courseGroups: 'Kursgruppen',
                courseMaterials: 'Kursmaterialien',
                courseSettings: 'Kurseinstellungen',
                courseStatistics: 'Kursstatistiken',
                courseTools: 'Kurswerkzeuge',
                questionBanks: 'Fragebänke',
            },
        },
        content: {
            goBackToSubject: 'Zurück zum Fach',
            expandMenuLabel: 'Menü erweitern',
            geppetto: {
                chatWithGeppetto: 'Sprechen Sie mit Geppetto',
                askGeppettoSomething: 'Fragen Sie Geppetto etwas',
                errorTryAgain: 'Es ist ein Fehler aufgetreten, versuchen Sie es erneut',
                typing: 'Tippen',
            },
        },
    },
}

export const messages: ItnMessagesType = {
    // "User or password incorrect.": (t: ItnLanguage) => itns[t].messages.userOrPasswordIncorrect
}