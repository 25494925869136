import React from "react";

type iconProps = {
    width?: number,
    height?: number,
    onClick?: () => void;
    color?: string;
    style?: React.CSSProperties;
    className?: string;
}

export default function SubjectsIcon(props: iconProps) {
    return (
        <svg
            style={props.style}
            className={props.className}
            width={props.width ?? 24}
            height={props.height ?? 24}
            viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M3 4C3 2.89543 3.89543 2 5 2H7C7.36429 2 7.70583 2.09739 8 2.26757C8.29418 2.09739 8.63571 2 9 2H11C11.7274 2 12.3642 2.38835 12.7142 2.96904C12.9235 2.80144 13.1702 2.67346 13.4459 2.5996L15.3777 2.08196C16.4447 1.79607 17.5413 2.42924 17.8272 3.49617L21.9683 18.951C22.2542 20.0179 21.621 21.1146 20.5541 21.4005L18.6223 21.9181C17.5553 22.204 16.4587 21.5708 16.1728 20.5039L13 8.66297V20C13 21.1046 12.1046 22 11 22H9C8.63571 22 8.29417 21.9026 8 21.7324C7.70583 21.9026 7.36429 22 7 22H5C3.89543 22 3 21.1046 3 20V4ZM5 4H7V20H5V4ZM11 20H9V4H11V20ZM13.9635 4.53145L15.8954 4.01381L20.0365 19.4686L18.1046 19.9863L13.9635 4.53145Z" fill="black" />
</svg>
    )
}
