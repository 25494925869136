import { format, subHours, startOfMonth } from "date-fns";
import {
	MonthlyBody,
	MonthlyDay,
	MonthlyCalendar,
	MonthlyNav,
	DefaultMonthlyEventItem,
} from "@zach.codes/react-calendar";
import React, { useContext, useEffect, useState } from "react";
import Card from "components/cards/card";
import { FlexBox } from "components/studium_ui";
import BreadCrumbs from "components/breadcrumbs/breadcrumbs";
import * as styles from "utils/styles";
import { LanguageContext } from "utils/itn";
import { itns } from "pages/calendar/texts/calendar.itn";
import { createUseStyles, useTheme } from "react-jss";
import { className } from "utils/className";

export default function Calendar() {
	const theme = useTheme();
	// @ts-ignore
	const classes = useStyles({ theme });
	const language = useContext(LanguageContext);
	const itn = itns[language];
	let [currentMonth, setCurrentMonth] = useState<Date>(
		startOfMonth(new Date())
	);

	const [firstRender, setFirstRender] = React.useState(true);

	useEffect(() => {
		setTimeout(() => {
			setFirstRender(false);
		}, 10);
	}, []);

	useEffect(() => {
		const daysOfWeek = {
			Sunday: itn.daysOfWeek.sunday,
			Monday: itn.daysOfWeek.monday,
			Tuesday: itn.daysOfWeek.tuesday,
			Wednesday: itn.daysOfWeek.wednesday,
			Thursday: itn.daysOfWeek.thursday,
			Friday: itn.daysOfWeek.friday,
			Saturday: itn.daysOfWeek.saturday,
		};
		const months = {
			January: itn.months.january,
			February: itn.months.february,
			March: itn.months.march,
			April: itn.months.april,
			May: itn.months.may,
			June: itn.months.june,
			July: itn.months.july,
			August: itn.months.august,
			September: itn.months.september,
			October: itn.months.october,
			November: itn.months.november,
			December: itn.months.december,
		};

		let dayOfWeekList = document.querySelectorAll(
			'[aria-label="Day of Week"]'
		);
		dayOfWeekList.forEach((item) => {
			Object.keys(daysOfWeek).forEach(function (key, index) {
				let previousText = item.textContent ?? "";
				// @ts-ignore
				item.textContent = previousText.replace(key, daysOfWeek[key]);
			});
		});
		let monthList = document.querySelectorAll(
			'[aria-label="Current Month"]'
		);
		monthList.forEach((item) => {
			Object.keys(months).forEach(function (key, index) {
				let previousText = item.textContent ?? "";
				// @ts-ignore
				item.textContent = previousText.replace(key, months[key]);
			});
		});

		let daysOfWeekOnDays = document.querySelectorAll(
			".lg\\:rc-hidden.rc-block"
		);
		daysOfWeekOnDays.forEach((item) => {
			Object.keys(daysOfWeek).forEach(function (key, index) {
				let previousText = item.textContent ?? "";
				// @ts-ignore
				item.textContent = previousText.replace(key, daysOfWeek[key]);
			});
		});

		let previousAndNextButtonList =
			document.querySelectorAll(".rc-cursor-pointer");
		previousAndNextButtonList.forEach((item) => {
			switch (item.textContent) {
				case "Previous":
					item.textContent = itn.actions.previousButton;
					break;
				case "Next":
					item.textContent = itn.actions.nextButton;
					break;
			}
		});
	}, [
		currentMonth,
		language,
		itn.actions.nextButton,
		itn.actions.previousButton,
		itn.daysOfWeek.friday,
		itn.daysOfWeek.monday,
		itn.daysOfWeek.saturday,
		itn.daysOfWeek.sunday,
		itn.daysOfWeek.thursday,
		itn.daysOfWeek.tuesday,
		itn.daysOfWeek.wednesday,
		itn.months.april,
		itn.months.august,
		itn.months.december,
		itn.months.february,
		itn.months.january,
		itn.months.july,
		itn.months.june,
		itn.months.march,
		itn.months.may,
		itn.months.november,
		itn.months.october,
		itn.months.september,
	]);

	return (
		<MonthlyCalendar
			currentMonth={currentMonth}
			onCurrentMonthChange={(date) => setCurrentMonth(date)}>
			<FlexBox
				className={className([
					classes.container,
					!firstRender ? classes.containerInitialized : null,
				])}
				column
				gap={styles.size(1)}>
				<BreadCrumbs items={[{ text: itn.breadcrumbs.calendar }]} />
				<FlexBox
					gap={styles.size(1)}
					style={{ height: styles.size(5) }}>
					<Card
						flex={1}
						style={{
							padding: `${styles.size(1)} ${styles.size(1)}`,
						}}>
						<FlexBox
							flex={1}
							mainAxisAlignment="end"
							crossAxisAlignment="center">
							<MonthlyNav />
						</FlexBox>
					</Card>
				</FlexBox>
				{/*<button onClick={() => {*/}
				{/*    let userLang = navigator.language;*/}
				{/*    alert(userLang);*/}
				{/*}}>userlang</button>*/}
				<Card style={{ height: "unset" }}>
					<MonthlyBody
						events={[
							{
								title: "Entrega do trabalho de História",
								date: subHours(new Date(), 245),
							},
							{
								title: "Prova de Física",
								date: subHours(new Date(), 2),
							},
							{
								title: "Prova de Português",
								date: subHours(new Date(), 1),
							},
							{
								title: "Olimpiadas de Matemática",
								date: new Date(),
							},
						]}>
						<MonthlyDay<any>
							renderDay={(data) =>
								data.map((item, index) => (
									<DefaultMonthlyEventItem
										key={index}
										title={item.title}
										// Format the date here to be in the format you prefer
										date={format(item.date, "k:mm")}
									/>
								))
							}
						/>
					</MonthlyBody>
				</Card>
			</FlexBox>
		</MonthlyCalendar>
	);
}

// region styles
const useStyles = createUseStyles({
	container: {
		// transition: "all 0.2s ease-in-out",
		// transform: "translateY(.5%) scale(0.995)",
		// opacity: 0.95,
	},
	containerInitialized: {
		// transform: "translateY(0%) scale(1)",
		// opacity: 1,
	},
});
