import { createUseStyles, useTheme } from "react-jss";
import * as styles from "utils/styles";
import { ReactNode, CSSProperties } from "react";
import { className } from "utils/className";

type Props = {
	onClick?: () => void;
	children: ReactNode;
	disabled?: boolean;
	style?: CSSProperties;
};

export default function Button(props: Props) {
	const theme = useTheme();
	const classes = useStyles({ theme });
	return (
		<button
			className={className([
				classes.button,
				props.disabled ? classes.disabled : null,
			])}
			style={props.style}
			onClick={() =>
				!props.disabled && props.onClick !== undefined
					? props.onClick()
					: {}
			}>
			{props.children}
		</button>
	);
}

const useStyles = createUseStyles({
	button: {
		height: styles.size(5),
		marginTop: styles.size(2),
		borderRadius: styles.size(0.75),
		fontSize: styles.size(1),
		fontWeight: "bold",
		border: "none",
		color: "white",
		cursor: "pointer",
		transition:
			"background-color 0.2s ease-in-out, transform 0.2s ease-in-out",
		backgroundColor: ({ theme }: any) => theme.elementBlue,
		boxShadow: ({ theme }: any) =>
			`0px 10px 20px 5px ${theme.elementBlue}33`,
		"&:hover": {
			backgroundColor: ({ theme }: any) =>
				styles.darken(theme.elementBlue, 0.1),
			transform: "translateY(-2px) scale(1.01)",
		},
		"&:active": {
			backgroundColor: ({ theme }: any) =>
				styles.darken(theme.elementBlue, 0.3),
		},
	},
	disabled: {
		backgroundColor: ({ theme }: any) => theme.backgroundAlt,
		color: ({ theme }: any) => `${theme.foregroundSecondary}77`,
		boxShadow: ({ theme }: any) => "unset",
		cursor: "not-allowed",
	},
});
