import { ItnType } from "utils/itn";

type Type = {
	breadcrumbs: {
		libraries: string;
	};
};

export const itns: ItnType<Type> = {
	ptBr: {
		breadcrumbs: {
			libraries: "Bibliotecas",
		},
	},
	ptEu: {
		breadcrumbs: {
			libraries: "Bibliotecas",
		},
	},
	en: {
		breadcrumbs: {
			libraries: "Libraries",
		},
	},
	es: {
		breadcrumbs: {
			libraries: "Bibliotecas",
		},
	},
	fr: {
		breadcrumbs: {
			libraries: "Bibliothèques",
		},
	},
	it: {
		breadcrumbs: {
			libraries: "Biblioteche",
		},
	},
	de: {
		breadcrumbs: {
			libraries: "Bibliotheken",
		},
	},
};
