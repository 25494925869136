import {useEffect, useState} from "react";
import {usePageVisibility} from "../../components/visibility_manager/visibility_manager";
import {useNotifications} from "reapop";
import Card from "../../components/cards/card";
import {FlexBox} from "../../components/studium_ui";
import {createUseStyles, useTheme} from "react-jss";
import * as styles from "../../utils/styles";

export default function StudentsTest() {
    const {notify} = useNotifications();
    const isVisible = usePageVisibility()
    const [wasInvisible, setWasInvisible] = useState(false)
    const theme = useTheme();
    // @ts-ignore
    const classes = useStyles({theme});

    useEffect(() => {
        if (isVisible && wasInvisible) {
            notify("Você saiu da página, este incidente foi reportado", "warning")
            setWasInvisible(false)
        }
        if (!isVisible) setWasInvisible(true);
    }, [wasInvisible, isVisible, notify])
    return (
        <Card>
            <FlexBox column gap={styles.size(2)}>
                <FlexBox gap={styles.size(1)}>
                    <FlexBox flex={1} mainAxisAlignment="center" crossAxisAlignment="center" className={classes.questionToggle}>1</FlexBox>
                    <FlexBox flex={1} mainAxisAlignment="center" crossAxisAlignment="center" className={classes.questionToggle}>2</FlexBox>
                    <FlexBox flex={1} mainAxisAlignment="center" crossAxisAlignment="center" className={classes.questionToggle}>3</FlexBox>
                    <FlexBox flex={1} mainAxisAlignment="center" crossAxisAlignment="center" className={classes.questionToggle}>4</FlexBox>
                    <FlexBox flex={1} mainAxisAlignment="center" crossAxisAlignment="center" className={classes.questionToggle}>5</FlexBox>
                    <FlexBox flex={1} mainAxisAlignment="center" crossAxisAlignment="center" className={classes.questionToggle}>6</FlexBox>
                    <FlexBox flex={1} mainAxisAlignment="center" crossAxisAlignment="center" className={classes.questionToggle}>7</FlexBox>
                    <FlexBox flex={1} mainAxisAlignment="center" crossAxisAlignment="center" className={classes.questionToggle}>8</FlexBox>
                    <FlexBox flex={1} mainAxisAlignment="center" crossAxisAlignment="center" className={classes.questionToggle}>9</FlexBox>
                    <FlexBox flex={1} mainAxisAlignment="center" crossAxisAlignment="center" className={classes.questionToggle}>10</FlexBox>
                </FlexBox>
                <div onCopy={(e) => notify("Você copiou o conteúdo da prova, este incidente foi reportado.", "warning")}>
                    <p>Em uma escola, a turma do 6º ano possui 30 alunos e a turma do 7º ano possui 35 alunos. O diretor da escola deseja formar uma comissão de 5 alunos para organizar um evento. Quantas possibilidades diferentes de escolha de comissão podem ser formadas se o diretor exigir que a comissão tenha exatamente 3 alunos do 6º ano e 2 alunos do 7º ano?</p>
                    <div className={classes.alternatives}>
                        a) 14 possibilidades diferentes<br />
                        b) 15 possibilidades diferentes<br />
                        c) 420 possibilidades diferentes<br />
                        d) 525 possibilidades diferentes<br />
                        e) 900 possibilidades diferentes<br />
                    </div>
                </div>
            </FlexBox>
        </Card>
    )
}

// region styles
const useStyles = createUseStyles({
    questionToggle: {
        backgroundColor: ({theme}: any) => theme.background,
        height: styles.size(3),
        border: ({theme}: any) => `1px solid rgba(${theme.contrast}, 0.1)`,
        borderRadius: styles.size(0.5),
        userSelect: "none",
        cursor: "pointer",
        transition: "all 0.2s ease-in-out",
        "&:hover": {
            backgroundColor: ({theme}: any) => theme.elementBlue,
        }
    },
    alternatives: {
        marginTop: styles.size(2),
        lineHeight: styles.size(3),
    },
});
// endregion