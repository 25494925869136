import {
	CSSProperties,
	ReactNode,
	useContext,
	useEffect,
	useState,
} from "react";
import { useNavigate } from "react-router-dom";
import "./sidebar.scss";
import { createUseStyles, useTheme } from "react-jss";
import * as styles from "utils/styles";
import { FlexBox } from "components/studium_ui";
import Dashboard from "assets/images/icons/dashboard";
import Logo from "assets/images/Logo";
import SidebarItems from "./sidebar_items";
import { StoreContext } from "store";
import { LanguageContext } from "utils/itn";
import { itns } from "./texts/sidebar.itn";
import MenuIcon from "assets/images/icons/menu";
import { className } from "utils/className";
import SidebarElevatedWrapper from "./sidebar_elevated_wrapper";
import { is } from "date-fns/locale";

type Props = {
	children?: ReactNode;
	style?: CSSProperties;
	mobile?: boolean;
	focus?: boolean;
	onItemChange?: (e: React.MouseEvent) => void;
	setIsMobileOpened: (isOpened: boolean) => void;
	isMobileOpened: boolean;
	setIsDesktopOpened: (isOpened: boolean) => void;
	isDesktopOpened: boolean;
};

export default function Sidebar(props: Props) {
	const theme = useTheme();
	const classes = useStyles({ theme });
	const language = useContext(LanguageContext);
	const itn = itns[language];
	// const institutionGroup = useRecoilValue(store.institutionGroup)
	const store = useContext(StoreContext);
	const navigate = useNavigate();

	return (
		<SidebarElevatedWrapper
			setIsMobileOpened={props.setIsMobileOpened}
			isMobileOpened={props.isMobileOpened}
			setIsDesktopOpened={props.setIsDesktopOpened}
			isDesktopOpened={props.isDesktopOpened}
			mobile={props.mobile}
			focus={props.focus}>
			<FlexBox
				gap={styles.size(1)}
				direction="column"
				onClick={(e) => {
					e.stopPropagation();
					e.nativeEvent.stopImmediatePropagation();
				}}
				mainAxisAlignment="space-between"
				className={classes.sidebarContainer}
				style={props.style}>
				{props.mobile || props.focus ? (
					<FlexBox
						crossAxisAlignment="center"
						style={{
							height: styles.size(5),
							marginTop: styles.size(-1.5),
							marginBottom: styles.size(0.5),
							marginLeft: styles.size(0.5),
						}}>
						<MenuIcon
							isOpened={
								props.mobile
									? props.isMobileOpened
									: props.isDesktopOpened
							}
							className={classes.menuIcon}
							onClick={(e) => props.onItemChange?.(e)}
						/>
					</FlexBox>
				) : null}
				<FlexBox flex={1} gap={styles.size(1)} direction="column">
					<Logo
						onClick={() => navigate("/")}
						width="100%"
						className={classes.logoSvg}
					/>
					<FlexBox
						onClick={() => navigate("/")}
						gap={styles.size(1)}
						crossAxisAlignment="center"
						className={classes.homeButton}>
						<Dashboard />
						{itn.principal.homePage}
					</FlexBox>
					<FlexBox
						gap={styles.size(0.5)}
						direction="column"
						className={classes.expanderContainer}>
						<SidebarItems onItemChange={props.onItemChange} />
					</FlexBox>
				</FlexBox>
				<FlexBox
					gap={styles.size(1)}
					crossAxisAlignment="center"
					className={classes.userCard}>
					<FlexBox
						crossAxisAlignment="center"
						mainAxisAlignment="center"
						className={classes.userCardPhoto}>
						{store.userStore.user?.photo !== undefined &&
						store.userStore.user?.photo !== "" ? (
							<img
								className={classes.userImg}
								src={`http://static.studium.academy/${store.userStore.user.photo}`}
								alt="photo"
							/>
						) : (
							""
						)}
					</FlexBox>
					<FlexBox
						style={{ minWidth: 0 }}
						flex={1}
						gap={styles.size(0.5)}
						direction="column">
						<FlexBox className={classes.userCardName}>
							<span className={classes.userCardParagraph}>
								{store.userStore.user?.name}
							</span>
						</FlexBox>
						<FlexBox className={classes.userCardDescription}>
							<span className={classes.userCardParagraph}>
								{store.userStore.user?.email}
							</span>
						</FlexBox>
					</FlexBox>
					<FlexBox
						crossAxisAlignmentSelf="start"
						style={{ paddingTop: styles.size(1.5) }}>
						<svg
							width="18"
							height="4"
							viewBox="0 0 18 4"
							fill="none"
							xmlns="http://www.w3.org/2000/svg">
							<circle cx="2" cy="2" r="2" fill="#D9D9D9" />
							<circle cx="9" cy="2" r="2" fill="#D9D9D9" />
							<circle cx="16" cy="2" r="2" fill="#D9D9D9" />
						</svg>
					</FlexBox>
				</FlexBox>
			</FlexBox>
		</SidebarElevatedWrapper>
	);
}

// region styles
const useStyles = createUseStyles({
	sidebarContainer: {
		position: "relative",
		cursor: "pointer",
		zIndex: 1,
		width: styles.size(20),
		height: "100%",
		backgroundColor: ({ theme }: any) => theme.backgroundAlt,
		paddingInline: styles.size(1),
		paddingBlock: styles.size(1.5),
		boxShadow: "2px 0px 4px rgba(0, 0, 0, 0.025)",
	},
	homeButton: {
		width: "100%",
		height: styles.size(3),
		backgroundColor: "black",
		borderRadius: styles.size(0.75),
		fontWeight: 500,
		fontSize: styles.size(1),
		paddingInline: styles.size(1),
		color: "rgba(255, 255, 255, 0.8)",
	},
	menuIcon: {
		"& *": {
			fill: ({ theme }: any) => theme.foreground,
		},
	},
	logo: {
		paddingInline: styles.size(3),
	},
	logoSvg: {
		"& *": {
			fill: ({ theme }: any) => theme.foreground,
		},
	},
	userImg: {
		width: "100%",
		height: "100%",
		objectFit: "cover",
	},
	expanderContainer: {
		// marginTop: styles.size(1),
	},
	expanderHeader: {
		fontWeight: 400,
		fontSize: styles.size(1),
		color: ({ theme }: any) => theme.foregroundSecondary,
		height: styles.size(3.5),
		paddingRight: styles.size(0.5),
	},
	expanderItem: {
		fontWeight: 500,
		fontSize: styles.size(1),
		borderRadius: styles.size(0.75),
		color: ({ theme }: any) => theme.foregroundSecondary,
		width: "100%",
		height: styles.size(3),
		paddingInline: styles.size(1.5),
	},
	expanderItemActive: {
		backgroundColor: ({ theme }: any) => theme.elementBackgroundSecondary,
		color: ({ theme }: any) => theme.elementBlue,
	},
	expanderDivider: {
		height: "1px",
		width: "100%",
		backgroundColor: "rgba(0, 0, 0, 0.03)",
	},
	userCard: {
		width: "100%",
		height: styles.size(5),
		backgroundColor: ({ theme }: any) => theme.elementBackgroundSecondary,
		borderRadius: styles.size(0.75),
		paddingInline: styles.size(1),
	},
	userCardPhoto: {
		width: styles.size(3),
		height: styles.size(3),
		borderRadius: styles.size(1.5),
		backgroundColor: ({ theme }: any) => theme.elementBlue,
		overflow: "hidden",
		flexShrink: 0,
	},
	userCardName: {
		fontWeight: "700",
		fontSize: styles.size(0.75),
		color: ({ theme }: any) => theme.foreground,
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
		overflow: "hidden",
		minWidth: 0,
	},
	userCardDescription: {
		fontWeight: "400",
		fontSize: styles.size(0.75),
		color: ({ theme }: any) => theme.foregroundSecondary,
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
		overflow: "hidden",
		minWidth: 0,
	},
	userCardParagraph: {
		overflow: "hidden",
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
		display: "block", // or display: inline-block
	},
});
// endregion

// <div onClick={(e) => e.stopPropagation()}>
//     <div style={{cursor: "pointer", userSelect: "none"}} onClick={() => navigate("/app")}>
//         {
//             institutionGroup?.institution !== undefined && institutionGroup?.institution.logo !== undefined
//                 ? <img src={`http://localhost:7070/static/${institutionGroup?.institution.logo}`} alt="logo"
//                        className="sidebar_logo"/>
//                 : <img src={logo} alt="logo" className="sidebar_logo"/>
//         }
//     </div>
//     <div>
//         {
//             institutionGroup?.units !== undefined && institutionGroup?.units.length > 0 ?
//                 <DropDown selected={unitSelected?.id} items={institutionGroup?.units}
//                           displayValuePath="name" onChange={function (item: InstitutionUnit): void {
//                     setUnitSelected(item);
//                 }}/>
//                 : null}
//     </div>
//     <div>
//         {items.map((item, index) => {
//             return (
//                 <SidebarItem
//                     key={index}
//                     path={item.path}
//                     location={location.pathname}
//                     text={item.text}
//                     icon={item.icon}
//                 />
//             )
//         })
//         }
//     </div>
// </div>
