// region imports
import {makeAutoObservable, runInAction} from "mobx";
import {Classroom} from "../../domains/classroom";
import {Grade} from "../../domains/grade";
import {fetchGrade, fetchGrades} from "../../api/grades";
import {Subject} from "../../domains/subject";
import Store from "../../store";
import {fetchCourse, fetchCourseRequestDTO, fetchCourses} from "../../api/courses";
import {Course} from "../../domains/course";
import {fetchClassroom, fetchClassrooms} from "../../api/classrooms";
import {User} from "../../domains/user";
import { Row } from "../../components/list/list";
import * as styles from "../../utils/styles";
import { FetchProfessorsAgreementsQueryRequestDTO, FetchProfessorsAgreementsResponseDTO } from "../../adapters/dto/professor_agreement";
import { fetchProfessorsAgreements } from "../../api/professor_agreement";
// endregion

// region ProfessorsGradesViewModel
export default class ProfessorViewModel {
    // region properties
    public courses: Course[] = [];
    public grades: Grade[] = [];
    public classrooms: Classroom[] = [];
    public students: User[] = [];
    public subjects: Subject[] = [];
    public professorsAgreements: FetchProfessorsAgreementsResponseDTO[] | undefined;

    public rows: Row[] = [];
    public rowsGrades: Row[] = [];

    public _courseSelected: Course | null = null;
    public _gradeSelected: Grade | null = null;
    public _classroomSelected: Classroom | null = null;
    public studentSelected: User | null = null;
    public subjectSelected?: Subject | null = null;

    public loading: boolean = true;
    public loadingGrades: boolean = true;
    public loadingCourses: boolean = true;
    public loadingClassrooms: boolean = true;
    public loadingStudents: boolean = true;
    public loadingSubjects: boolean = true;

    public notificationCallback: (message: string, status: "error" | "success" | "info" | "warning") => void = () => {};
    public texto: string = "";
    private searchParams: URLSearchParams;
    public store: Store;

    public showUserModal: boolean = false;
    // endregion

    // region constructor
    constructor(
        store: Store,
        searchParams: URLSearchParams,
    ) {
        makeAutoObservable(this, {}, { deep: false, autoBind: true });
        this.store = store;
        this.searchParams = searchParams;
    }
    // endregion

    // region onInitialized
    public async onInitialized() {
        await this.fetchProfessorsAgreements();
        await this.fetchCourses();
    }
    // endregion

    // region getters and setters
    public get courseSelected() {
        return this._courseSelected;
    }

    public set courseSelected(course: Course | null) {
        this._courseSelected = course;
        runInAction(() => {
            this.fetchGrades().then(r => {});
        });
    }

    public get gradeSelected() {
        return this._gradeSelected;
    }

    public set gradeSelected(course: Course | null) {
        this._gradeSelected = course;
        runInAction(() => {
            this.fetchClassrooms().then(r => {});
            this.fetchGrade().then(r => {});
        });
    }

    public get classroomSelected() {
        return this._classroomSelected;
    }

    public set classroomSelected(course: Course | null) {
        this._classroomSelected = course;
        runInAction(() => {
            this.fetchStudents().then(r => {});
        });
    }
    // endregion

    // region methods
    // region fetchCourse
    public async fetchCourse(courseId: string) {
        const response = await fetchCourse(courseId, this.store.userStore.user?.id);
        if (!response.payload) {
            this.notificationCallback("Erro ao carregar os cursos", "error");
            return;
        }
        this._courseSelected = response.payload;
    }
    // endregion

    // region fetchCourses
    public async fetchCourses() {
        this.loadingCourses = true;
        const params : fetchCourseRequestDTO = {
            institutionUnitId : this.store.institutionStore.units[0]?.id,
            professorId: this.store.userStore.user?.id,
            page: 1,
            pageSize: 10
        };
        const response = await fetchCourses(params, this.store.userStore.user?.id);
        if (!response.payload) {
            this.notificationCallback("Erro ao carregar os cursos", "error");
            return;
        }
        this.courses = response.payload;
        this.rows = await Promise.all(styles.sortListAlphabetically(this.courses, "name").map(async course => {
            const params = {
                courseId: course.id,
                page: 1,
                pageSize: 10,
            };
            const childrenResponse = await fetchGrades(params, this.store.authStore?.token);
            if (!response.payload) {
                this.notificationCallback("Erro ao carregar os anos letivos", "error");
                return {id: "", name: ""};
            }
            return {
                id: course.id!,
                name: course.name!,
                children: await Promise.all(styles.sortListAlphabetically(childrenResponse.payload!, "name").map(async grade => {
                    const params = {
                        gradeId: grade.id,
                        page: 1,
                        pageSize: 5,
                    }
                    let classroomResponse = await fetchClassrooms(params, this.store.authStore.token);
                    return{
                        id: grade.id!,
                        name: grade.name!,
                        children: styles.sortListAlphabetically(classroomResponse.payload!, "name").map(classroom => {
                            return {
                                id: classroom.id!,
                                name: classroom.name!,
                                onClick: () => {
                                    this.courseSelected = course;
                                    this.gradeSelected = grade;
                                    this.classroomSelected = classroom;
                                },
                                navigate: "/professors/classrooms",
                            }}),
                    }})),
            }
        }));
        this.loadingCourses = false;
    }
    // endregion

    // region fetchGrade
    private async fetchGrade(): Promise<unknown> {
        this.loadingSubjects = true;
        let {payload, status} = await fetchGrade(this._gradeSelected?.id || "", this.store.authStore.token)
        if (status >= 300 && payload === null) return alert("Erro ao buscar dados das séries");
        if (!(payload && payload.grade)) return;
        // this.gradeSelected = payload?.grade;
        this.subjects = payload?.subjects || [];
        this.loadingSubjects = false;
    };
    // endregion

    // region fetchGrades
    public async fetchGrades() {
        this.loadingGrades = true;
        const params = {
            courseId: this.courseSelected?.id,
            page: 1,
            pageSize: 10,
        };
        const response = await fetchGrades(params, this.store.authStore?.token);
        if (!response.payload) {
            this.notificationCallback("Erro ao carregar os anos letivos", "error");
            return;
        }
        this.grades = response.payload;
        this.loadingGrades = false;
    }
    // endregion

    // region fetchClassroomsData
    private async fetchClassrooms(): Promise<unknown> {
        this.loadingClassrooms = true;
        if (!this.gradeSelected) return;
        const params = {
            gradeId: this.gradeSelected.id,
            page: 1,
            pageSize: 5,
        }
        let {payload, status} = await fetchClassrooms(params, this.store.authStore.token);
        if (status >= 300 && payload === null) return alert("Erro ao buscar dados das salas de aula2");
        if (payload === undefined) return;
        this.classrooms = payload;
        this.loadingClassrooms = false;
    };
    // endregion

    // region fetchStudents
    private async fetchStudents(): Promise<unknown> {
        if (!this.classroomSelected?.id) return;
        this.loadingStudents = true;
        let response = await fetchClassroom(this.classroomSelected.id, this.store.authStore.token)
        if (response.status >= 300 && response.payload === null) return alert("Erro ao buscar dados dos alunos");
        if (!response.payload) return;
        this.students = response.payload.students;
        this.loadingStudents = false;
    };
    // endregion

    // region handleSearchParams
    public async handleSearchParams(searchParams: URLSearchParams) {
        if (searchParams.get("courseId")) {
            await this.fetchCourse(searchParams.get("courseId") as string);
            await this.fetchGrades();
        }
    }
    // endregion

    // region fetchProfessorsAgreements
    private async fetchProfessorsAgreements() {
        const params: FetchProfessorsAgreementsQueryRequestDTO = {
            page: 1,
            pageSize: 10,
            userId: this.store.userStore.user?.id,
        };
        const response = await fetchProfessorsAgreements(params, this.store.authStore?.token);
        if (!response.payload) {
            this.notificationCallback("Erro ao carregar os convênios", "error");
            return;
        }
        this.professorsAgreements = response.payload;
    }

    // endregion
}
// endregion