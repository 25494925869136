// region imports
import {
    createHashRouter,
    RouterProvider,
    Navigate,
} from "react-router-dom";
import MainLayout from "../layouts/main_layout/main_layout";
import Calendar from "../pages/calendar/calendar";
import Enrollments from "../pages/enrollments/enrollments";
import Home from "../pages/home/home";
import LanguageSettings from "../pages/settings/language_settings";
import Libraries from "../pages/libraries/libraries";
import NotFound from "../pages/not_found/not_found";
import NotificationsSystem, {atalhoTheme, setUpNotifications, useNotifications} from "reapop";
import ProfessorClassroom from "../pages/professors/professor_classroom";
import ProfessorCourse from "../pages/professors/professor_course";
import ProfessorGrade from "../pages/professors/professor_grade";
import Profile from "../pages/user/profile";
import React, {useContext} from "react";
import RecoveryPassword from "../pages/auth/recovery_password";
import ResetPassword from "../pages/auth/reset_password";
import SignIn from "../pages/auth/sign_in";
import StudentsTest from "../pages/students/test";
import SubjectsList from "../pages/students/student_subjects_list";
import SubjectsDetails from "../pages/students/subjects_details";
import {StoreContext} from "../store";
import {observer} from "mobx-react";
import Professor from "../pages/professors/professor";
import ProfessorCourses from "../pages/professors/professor_courses";
import ProfessorSubject from "../pages/professors/professor_subject";
import ProfessorsAgreements from "../pages/professors/professors_agreements";
import Students from "../pages/students/students";
import StudentContent from "../pages/students/student_content";
// endregion

// region Router
// export default function Router() {
const Router = observer(() => {
    // region hooks
    const store = useContext(StoreContext);
    // const institutionGroup = useRecoilValue(store1.institutionGroup)
    // endregion

    const {notifications, dismissNotification} = useNotifications()
    setUpNotifications({
        defaultProps: {
            position: 'top-right',
            dismissible: true,
            dismissAfter: 5000,
            closeButton: true,
        }
    })

    // region functions
    const router = createHashRouter(
        [
            {
                path: "/",
                element: !!store.authStore.token ? <MainLayout header="Disciplinas" /> :
                    <Navigate to={`/sign-in/${store.institutionStore?.institution.username}`}/>,
                children: [
                    {
                        path: "/",
                        element: <Home/>
                    },
                    {
                        path: "/libraries",
                        element: <Libraries/>
                    },
                    {
                        path: "/calendar",
                        element: <Calendar/>
                    },
                    {
                        path: "/profile",
                        element: <Profile/>
                    },
                    {
                        path: "/language-settings",
                        element: <LanguageSettings/>
                    },
                    {
                        path: "/enrollments",
                        element: <Enrollments/>
                    },

                    // students
                    {
                        path: "/students",
                        element: <Students/>,
                        children: [
                            {
                                path: "/students/",
                                element: <SubjectsList/>
                            },
                            {
                                path: "/students/details",
                                element: <SubjectsDetails/>
                            },
                            {
                                path: "/students/content",
                                element: <StudentContent />
                            },
                        ],
                    },

                    // professors
                    {
                        path: "/professors",
                        element: <Professor/>,
                        children: [
                            {
                                path: "/professors/",
                                element: <ProfessorCourses />,
                            },
                            {
                                path: "/professors/courses",
                                element: <ProfessorCourse />
                            },
                            {
                                path: "/professors/grades",
                                element: <ProfessorGrade/>
                            },
                            {
                                path: "/professors/classrooms",
                                element: <ProfessorClassroom/>
                            },
                            {
                                path: "/professors/subjects",
                                element: <ProfessorSubject/>
                            },
                            {
                                path: "/professors/agreements",
                                element: <ProfessorsAgreements/>
                            }
                        ],
                    },

                    // students
                    {
                        path: "/students/test",
                        element: !!store.authStore.token ? <MainLayout header="Prova"><StudentsTest/></MainLayout> :
                            <Navigate to={`/sign-in/${store.institutionStore?.institution.username}`}/>,
                    },
                ]
            },
            {
                path: "/sign-in/:id",
                element: !store.authStore.token ? <SignIn/> : <Navigate to="/"/>,
            },
            {
                path: "/recovery-password",
                element: !store.authStore.token ? <RecoveryPassword/> : <Navigate to="/"/>,
            },
            {
                path: "/reset-password",
                element: !store.authStore.token ? <ResetPassword/> : <Navigate to="/"/>,
            },

            // others
            // {
            //     path: "/",
            //     element: <Navigate to="/app"/>,
            // },
            {
                path: "*",
                element: <NotFound />
            },
        ]
    );
    // endregion

    // region render
    return (
        <>
            <RouterProvider router={router}/>
            <NotificationsSystem
                // 2. Pass the notifications you want Reapop to display.
                notifications={notifications}
                // 3. Pass the function used to dismiss a notification.
                dismissNotification={(id) => dismissNotification(id)}
                // 4. Pass a builtIn theme or a custom theme.
                theme={atalhoTheme} />
        </>
    )
    // endregion
});
// endregion

export default Router;