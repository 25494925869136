// region imports
import Card from "components/cards/card";
import { FlexBox } from "components/studium_ui";
import { createUseStyles, useTheme } from "react-jss";
import * as styles from "utils/styles";
import { useNavigate, useOutletContext } from "react-router-dom";
import { observer } from "mobx-react";
import BreadCrumbs from "components/breadcrumbs/breadcrumbs";
import Loading from "components/loading/loading";
import ProfessorViewModel from "view_models/professors/professor_view_model";
import { useContext, useEffect } from "react";
import { LanguageContext } from "utils/itn";
import { itns } from "pages/professors/texts/professor.itn";
// endregion

// region ProfessorsGradesView
// this page is the professor grade, here they can see the students enrolled in the grade and the classrooms
const ProfessorGradeView = observer(
	({ viewModel }: { viewModel: ProfessorViewModel }) => {
		// region properties
		const theme = useTheme();
		const classes = useStyles({ theme });
		const navigate = useNavigate();
		const language = useContext(LanguageContext);
		const itn = itns[language];
		// endregion

		useEffect(() => {});

		// region render
		return (
			<FlexBox gap={styles.size(1)} column flex={1}>
				<BreadCrumbs
					homeRoute="/professors/"
					items={[
						{
							text: viewModel.courseSelected?.name,
							route: `/professors/courses`,
						}, //?courseId=${viewModel.courseSelected?.id}`},
						{ text: viewModel.gradeSelected?.name },
					]}
				/>
				<Card
					leftHeader={
						<FlexBox
							gap={styles.size(2)}
							mainAxisAlignment="center"
							crossAxisAlignment="center">
							<h5 className={classes.title}>{itn.classrooms}</h5>
						</FlexBox>
					}>
					<FlexBox gap={styles.size(1)} column flex={1}>
						{viewModel.loadingClassrooms ? (
							<Loading />
						) : viewModel.classrooms.length > 0 ? (
							viewModel.classrooms.map((classroom, index) => (
								<FlexBox
									key={classroom.id}
									column
									gap={styles.size(1)}>
									<FlexBox
										key={classroom.id}
										onClick={() => {
											viewModel.classroomSelected =
												classroom;
											navigate(`/professors/classrooms`); //?id=${classroom.id}`);
										}}
										flex={1}
										column
										gap={styles.size(1)}
										mainAxisAlignment="start"
										crossAxisAlignment="stretch">
										<FlexBox
											className={classes.item}
											gap={styles.size(1)}
											mainAxisAlignment="center"
											crossAxisAlignment="center">
											<FlexBox
												className={classes.eventsBox}
												style={{
													width: styles.size(20),
												}}
												mainAxisAlignment="center"
												crossAxisAlignment="center">
												{classroom?.name}
											</FlexBox>
											<FlexBox
												flex={1}
												className={classes.eventsBox}
												mainAxisAlignment="start"
												crossAxisAlignment="center">
												{classroom?.description}
											</FlexBox>
										</FlexBox>
									</FlexBox>
								</FlexBox>
							))
						) : (
							<FlexBox
								flex={1}
								column
								gap={styles.size(1)}
								mainAxisAlignment="start">
								{itn.emptyClassrooms}
							</FlexBox>
						)}
					</FlexBox>
				</Card>
			</FlexBox>
		);
		// endregion
	}
);
// endregion

// region styles
const useStyles = createUseStyles({
	gradeItem: {
		color: ({ theme }: any) => theme.foregroundSecondary,
		border: ({ theme }: any) => `1px solid ${theme.foregroundSecondary}33`,
		borderRadius: styles.size(0.75),
		height: styles.size(4),
		paddingInline: styles.size(1),
		transition: "all 0.2s ease-in-out",
	},
	goBack: {
		cursor: "pointer",
	},
	goBackIcon: {
		fontSize: styles.size(1.5),
		"&:before": {
			color: ({ theme }: any) => theme.foregroundSecondary,
		},
	},
	title: {
		fontWeight: 500,
		color: ({ theme }: any) => theme.foreground,
		fontSize: styles.size(1.5),
		margin: 0,
	},
	item: {
		"&:hover": {
			"& > div": {
				backgroundColor: ({ theme }: any) =>
					theme.elementBackgroundSecondary,
			},
		},
	},
	eventsBox: {
		border: ({ theme }: any) =>
			`1px solid ${theme.elementBackgroundSecondary}`,
		borderRadius: styles.size(0.75),
		fontSize: styles.size(1),
		color: ({ theme }: any) => theme.foregroundSecondary,
		height: styles.size(3),
		paddingInline: styles.size(1),
		cursor: "pointer",
	},
	eventBoxActive: {
		backgroundColor: ({ theme }: any) => theme.elementBackgroundSecondary,
	},
});
// endregion

// region export
export default function ProfessorGrade() {
	const viewModel: ProfessorViewModel = useOutletContext();
	return <ProfessorGradeView viewModel={viewModel} />;
}
// endregion
