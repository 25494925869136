type iconProps = {
    width?: number,
    height?: number,
    onClick?: () => void;
}

export default function Dashboard(props: iconProps) {
    return (
        <svg
            width={props.width ?? 19}
            height={props.height ?? 19}
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.36892 10.4033C4.848 10.4033 5.23637 10.0149 5.23637 9.53585C5.23637 9.05677 4.848 8.6684 4.36892 8.6684C3.88984 8.6684 3.50146 9.05677 3.50146 9.53585C3.50146 10.0149 3.88984 10.4033 4.36892 10.4033Z"
                fill="#F7F7F7"/>
            <path
                d="M5.75832 6.78044C6.2374 6.78044 6.62577 6.39207 6.62577 5.91299C6.62577 5.43391 6.2374 5.04553 5.75832 5.04553C5.27924 5.04553 4.89087 5.43391 4.89087 5.91299C4.89087 6.39207 5.27924 6.78044 5.75832 6.78044Z"
                fill="#F7F7F7"/>
            <path
                d="M9.47292 5.08259C9.952 5.08259 10.3404 4.69422 10.3404 4.21514C10.3404 3.73606 9.952 3.34769 9.47292 3.34769C8.99384 3.34769 8.60547 3.73606 8.60547 4.21514C8.60547 4.69422 8.99384 5.08259 9.47292 5.08259Z"
                fill="#F7F7F7"/>
            <path
                d="M14.7237 10.5182C15.2027 10.5182 15.5911 10.1298 15.5911 9.65075C15.5911 9.17167 15.2027 8.78329 14.7237 8.78329C14.2446 8.78329 13.8562 9.17167 13.8562 9.65075C13.8562 10.1298 14.2446 10.5182 14.7237 10.5182Z"
                fill="#F7F7F7"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M9.94917 11.307C9.51396 11.7996 8.76043 11.8464 8.2673 11.4111C7.77469 10.9759 7.72793 10.2224 8.16314 9.72981C8.16314 9.72981 8.24551 9.5773 8.42937 9.34561C8.54628 9.19895 8.70677 9.02412 8.89754 8.83016C9.24613 8.47572 9.684 8.05592 10.1713 7.63293C11.7421 6.26937 13.6179 4.87233 13.6179 4.87233C13.7343 4.74055 13.9352 4.72832 14.067 4.84417C14.1987 4.96054 14.2109 5.16141 14.0946 5.29319C14.0946 5.29319 12.9399 7.32738 11.7814 9.05494C11.4217 9.59059 11.0598 10.0773 10.751 10.4669C10.5821 10.6799 10.4285 10.8612 10.2972 10.9951C10.09 11.206 9.94917 11.307 9.94917 11.307Z"
                  fill="#F7F7F7"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M9.49975 0C14.7432 0 19 4.25681 19 9.50025C19 14.7437 14.7432 19.0005 9.49975 19.0005C4.25681 19.0005 0 14.7437 0 9.50025C0 4.25681 4.25681 0 9.49975 0ZM9.49975 1.59412C5.13654 1.59412 1.59412 5.13704 1.59412 9.50025C1.59412 13.864 5.13654 17.4064 9.49975 17.4064C13.8635 17.4064 17.4059 13.864 17.4059 9.50025C17.4059 5.13704 13.8635 1.59412 9.49975 1.59412Z"
                  fill="#F7F7F7"/>
        </svg>
    )
}