// region imports
import { FlexBox } from "components/studium_ui";
import SmallCard from "components/cards/small_card";
import * as styles from "utils/styles";
import React, { useContext, useEffect } from "react";
import Card from "components/cards/card";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Tooltip,
	PointElement,
	LineElement,
	Filler,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { itns } from "./texts/home.itn";
// import useElementDimensions from "../../utils/useElementDimensions";
import { createUseStyles, useTheme } from "react-jss";
import { RadarChart } from "../../components/chart/radar_chart";
import { className } from "../../utils/className";
import { LanguageContext } from "../../utils/itn";
// endregion

// region configs

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	// Title,
	Tooltip,
	PointElement,
	Filler,
	LineElement
	// Legend
);

function rnd(min: number = 0, max: number = 255) {
	return Math.random() * (max - min) + min;
}

export const options = (theme: any) => ({
	responsive: true,
	maintainAspectRatio: false,
	y: {
		ticks: {
			backdropColor: theme.backgroundAlt,
			color: theme.foregroundSecondary,
		},
		pointLabels: {
			color: theme.foreground,
		},
	},
});
// endregion

// region Home
export default function Home() {
	// region properties
	const theme = useTheme();
	const classes = useStyles({ theme });
	const language = useContext(LanguageContext);
	const itn = itns[language];
	const { width } = useWindowDimensions();
	const [firstRender, setFirstRender] = React.useState(true);
	// const ref = React.useRef<HTMLDivElement>(null);
	// const {height: elementHeight, width: elementWidth} = useElementDimensions(ref);
	// endregion

	useEffect(() => {
		setTimeout(() => {
			setFirstRender(false);
		}, 10);
	}, []);

	const labels = [
		itn.months.january,
		itn.months.february,
		itn.months.march,
		itn.months.april,
		itn.months.may,
		itn.months.june,
		itn.months.july,
	];

	const color = (opacity = 1) => {
		let arrayTranslucent: string[] = [];
		let arrayOpaque: string[] = [];

		for (let i = 0; i < labels.length; i++) {
			let r1 = rnd();
			let r2 = rnd();
			let r3 = rnd();
			arrayTranslucent.push(`rgba(${r1}, ${r2}, ${r3}, 0.1)`);
			arrayOpaque.push(`rgba(${r1}, ${r2}, ${r3}, 0.7)`);
		}
		return [arrayTranslucent, arrayOpaque];
	};

	let colors = color();

	const data2 = {
		labels: labels,
		fill: true,
		datasets: [
			{
				label: itn.monthsQuantityLabel,
				data: labels.map(() => rnd(0, 100)),
				backgroundColor: colors[0],
				borderColor: colors[1],
				borderWidth: 1,
			},
		],
	};

	// region render
	return (
		<FlexBox
			className={className([
				classes.container,
				!firstRender ? classes.containerInitialized : null,
			])}
			gap={styles.size(1)}
			flex={1}
			direction="column">
			{/*<FlexBox column={width <= 992} gap={styles.size(1)}>
                <FlexBox gap={styles.size(1)} flex={1}>
                    <SmallCard title="Título" flex={1}/>
                    <SmallCard title="Título" flex={1}/>
                    <SmallCard title="Título" flex={1}/>
                    <SmallCard title="Título" flex={1}/>
                </FlexBox>
                <FlexBox gap={styles.size(1)} flex={1}>
                    <SmallCard title="Título" flex={1}/>
                    <SmallCard title="Título" flex={1}/>
                    <SmallCard title="Título" flex={1}/>
                    <SmallCard title="Título" flex={1}/>
                </FlexBox>
            </FlexBox>*/}
			<FlexBox
				shrink={0}
				column={width <= 992}
				flex={1}
				gap={styles.size(1)}>
				<Card title={itn.nextEvents} flex={1}>
					<FlexBox
						flex={1}
						column
						gap={styles.size(1)}
						mainAxisAlignment="start"
						crossAxisAlignment="stretch">
						<FlexBox
							gap={styles.size(1)}
							mainAxisAlignment="center"
							crossAxisAlignment="center">
							<FlexBox
								className={classes.eventsBox}
								style={{ width: styles.size(8) }}
								mainAxisAlignment="center"
								crossAxisAlignment="center">
								07/08/2023
							</FlexBox>
							<FlexBox
								flex={1}
								className={classes.eventsBox}
								mainAxisAlignment="start"
								crossAxisAlignment="center">
								Prova de matemática
							</FlexBox>
						</FlexBox>
						<FlexBox
							gap={styles.size(1)}
							mainAxisAlignment="center"
							crossAxisAlignment="center">
							<FlexBox
								className={classes.eventsBox}
								style={{ width: styles.size(8) }}
								mainAxisAlignment="center"
								crossAxisAlignment="center">
								07/08/2023
							</FlexBox>
							<FlexBox
								flex={1}
								className={classes.eventsBox}
								mainAxisAlignment="start"
								crossAxisAlignment="center">
								Prova de história
							</FlexBox>
						</FlexBox>
						<FlexBox
							gap={styles.size(1)}
							mainAxisAlignment="center"
							crossAxisAlignment="center">
							<FlexBox
								className={className([
									classes.eventsBox,
									classes.eventBoxActive,
								])}
								style={{ width: styles.size(8) }}
								mainAxisAlignment="center"
								crossAxisAlignment="center">
								07/08/2023
							</FlexBox>
							<FlexBox
								flex={1}
								className={className([
									classes.eventsBox,
									classes.eventBoxActive,
								])}
								mainAxisAlignment="start"
								crossAxisAlignment="center">
								Prova de geografia
							</FlexBox>
						</FlexBox>
						<FlexBox
							gap={styles.size(1)}
							mainAxisAlignment="center"
							crossAxisAlignment="center">
							<FlexBox
								className={classes.eventsBox}
								style={{ width: styles.size(8) }}
								mainAxisAlignment="center"
								crossAxisAlignment="center">
								07/08/2023
							</FlexBox>
							<FlexBox
								flex={1}
								className={classes.eventsBox}
								mainAxisAlignment="start"
								crossAxisAlignment="center">
								Prova de português
							</FlexBox>
						</FlexBox>
						<FlexBox
							gap={styles.size(1)}
							mainAxisAlignment="center"
							crossAxisAlignment="center">
							<FlexBox
								className={classes.eventsBox}
								style={{ width: styles.size(8) }}
								mainAxisAlignment="center"
								crossAxisAlignment="center">
								07/08/2023
							</FlexBox>
							<FlexBox
								flex={1}
								className={classes.eventsBox}
								mainAxisAlignment="start"
								crossAxisAlignment="center">
								Prova de inglês
							</FlexBox>
						</FlexBox>
					</FlexBox>
				</Card>
				<Card title={itn.scheduleOfTheDay} flex={1}>
					<FlexBox
						flex={1}
						column
						gap={styles.size(1)}
						mainAxisAlignment="start"
						crossAxisAlignment="stretch">
						<FlexBox
							gap={styles.size(1)}
							mainAxisAlignment="center"
							crossAxisAlignment="center">
							<FlexBox
								className={classes.eventsBox}
								style={{ width: styles.size(8) }}
								mainAxisAlignment="center"
								crossAxisAlignment="center">
								12am - 2pm
							</FlexBox>
							<FlexBox
								flex={1}
								className={classes.eventsBox}
								mainAxisAlignment="start"
								crossAxisAlignment="center">
								Aula de matemática
							</FlexBox>
						</FlexBox>
						<FlexBox
							gap={styles.size(1)}
							mainAxisAlignment="center"
							crossAxisAlignment="center">
							<FlexBox
								className={classes.eventsBox}
								style={{ width: styles.size(8) }}
								mainAxisAlignment="center"
								crossAxisAlignment="center">
								12am - 2pm
							</FlexBox>
							<FlexBox
								flex={1}
								className={classes.eventsBox}
								mainAxisAlignment="start"
								crossAxisAlignment="center">
								Aula de história
							</FlexBox>
						</FlexBox>
						<FlexBox
							gap={styles.size(1)}
							mainAxisAlignment="center"
							crossAxisAlignment="center">
							<FlexBox
								className={className([
									classes.eventsBox,
									classes.eventBoxActive,
								])}
								style={{ width: styles.size(8) }}
								mainAxisAlignment="center"
								crossAxisAlignment="center">
								12am - 2pm
							</FlexBox>
							<FlexBox
								flex={1}
								className={className([
									classes.eventsBox,
									classes.eventBoxActive,
								])}
								mainAxisAlignment="start"
								crossAxisAlignment="center">
								Aula de geografia
							</FlexBox>
						</FlexBox>
						<FlexBox
							gap={styles.size(1)}
							mainAxisAlignment="center"
							crossAxisAlignment="center">
							<FlexBox
								className={classes.eventsBox}
								style={{ width: styles.size(8) }}
								mainAxisAlignment="center"
								crossAxisAlignment="center">
								12am - 2pm
							</FlexBox>
							<FlexBox
								flex={1}
								className={classes.eventsBox}
								mainAxisAlignment="start"
								crossAxisAlignment="center">
								Aula de português
							</FlexBox>
						</FlexBox>
						<FlexBox
							gap={styles.size(1)}
							mainAxisAlignment="center"
							crossAxisAlignment="center">
							<FlexBox
								className={classes.eventsBox}
								style={{ width: styles.size(8) }}
								mainAxisAlignment="center"
								crossAxisAlignment="center">
								12am - 2pm
							</FlexBox>
							<FlexBox
								flex={1}
								className={classes.eventsBox}
								mainAxisAlignment="start"
								crossAxisAlignment="center">
								Aula de inglês
							</FlexBox>
						</FlexBox>
					</FlexBox>
				</Card>
			</FlexBox>
			<FlexBox column={width <= 992} flex={1} gap={styles.size(1)}>
				<Card title={itn.performance} flex={1}>
					<FlexBox
						flex={1}
						mainAxisAlignment="center"
						crossAxisAlignment="center">
						<RadarChart />
					</FlexBox>
				</Card>
				<Card title={itn.performance} flex={1}>
					<FlexBox
						flex={1}
						mainAxisAlignment="center"
						crossAxisAlignment="center">
						<Bar options={options(theme)} data={data2} />
					</FlexBox>
				</Card>
			</FlexBox>
		</FlexBox>
	);
	// endregion
}
// endregion

// region styles
const useStyles = createUseStyles({
	container: {
		// transition: "all 0.2s ease-in-out",
		// transform: "translateY(.5%) scale(0.995)",
		// opacity: 0.95,
	},
	containerInitialized: {
		// transform: "translateY(0%) scale(1)",
		// opacity: 1,
	},
	child: {
		gridArea: "area",
		height: "auto",
	},
	grid: {
		display: "grid",
		gridTemplateColumns: "auto",
		gridTemplateRows: "auto",
		grid: "'area'",
		minWidth: styles.size(2),
		// height: styles.size(15),
		height: "auto",
		background: ({ theme }: any) => theme.backgroundAlt,
		boxShadow: "0px 2px 7px rgba(0, 0, 0, 0.15)",
		borderRadius: "12px",
	},
	flexContainer: {
		gridArea: "area",
		paddingTop: styles.size(1),
		paddingBottom: "20%",
		paddingInline: styles.size(1),
		color: ({ theme }: any) => theme.foreground,
	},
	smooth: {
		backgroundColor: "transparent",
		height: "0px",
		width: "0px",
		borderRadius: "100%",
		// boxShadow: "0 0 50px 35px rgba(180, 180, 180, 0.5)",
	},
	number: {
		color: "white",
		fontWeight: "bold",
		textShadow: "0px 1px 4px rgba(0, 0, 0, 0.25)",
	},
	eventsBox: {
		border: ({ theme }: any) => `1px solid ${theme.border}`,
		borderRadius: styles.size(0.75),
		fontSize: styles.size(1),
		color: ({ theme }: any) => theme.foregroundSecondary,
		height: styles.size(3),
		paddingInline: styles.size(1),
	},
	eventBoxActive: {
		backgroundColor: ({ theme }: any) => theme.elementBackgroundSecondary,
	},
});
// endregion
