// region imports
import Card from "../../components/cards/card";
import {FlexBox} from "../../components/studium_ui";
import {createUseStyles, useTheme} from "react-jss";
import * as styles from "../../utils/styles";
import {useNavigate, useOutletContext} from "react-router-dom";
import {observer} from "mobx-react";
import BreadCrumbs from "../../components/breadcrumbs/breadcrumbs";
import Loading from "../../components/loading/loading";
import ProfessorViewModel from "../../view_models/professors/professor_view_model";
import {useContext} from "react";
import {LanguageContext} from "../../utils/itn";
import {itns} from "./texts/professor.itn";
// endregion

// region ProfessorsGradesView
// this page is the professor grade, here they can see the students enrolled in the grade and the classrooms
const ProfessorCourseView = observer(({viewModel} : { viewModel: ProfessorViewModel }) => {
    // region properties
    const theme = useTheme();
    const classes = useStyles({theme});
    const navigate = useNavigate();
    const language = useContext(LanguageContext);
    const itn = itns[language];
    // endregion

    // region render
    return (
        <FlexBox gap={styles.size(1)} column flex={1}>
            <BreadCrumbs homeRoute="/professors/" items={[{text: viewModel?.courseSelected?.name}]} />
            <Card
                leftHeader={
                    <FlexBox gap={styles.size(2)} mainAxisAlignment="center" crossAxisAlignment="center">
                        <h5 className={classes.title}>{itn.academicYears}</h5>
                    </FlexBox>
                }>
                <FlexBox gap={styles.size(1)} column flex={1}>
                    {viewModel?.loadingGrades ? <Loading /> :
                        viewModel?.grades?.map((grade, index) => (
                            <FlexBox
                                onClick={() => {
                                    viewModel.gradeSelected = grade;
                                    navigate(`/professors/grades`); //?id=${grade.id}`);
                                }}
                                key={index}
                                className={classes.item}
                                gap={styles.size(1)}
                                mainAxisAlignment="center"
                                crossAxisAlignment="center">
                                <FlexBox
                                    className={classes.eventsBox}
                                    flex={1}
                                    // style={{ width: styles.size(20) }}
                                    mainAxisAlignment="center"
                                    crossAxisAlignment="center">
                                    {grade.name}
                                </FlexBox>
                                {/*<FlexBox
                                    flex={1}
                                    className={classes.eventsBox}
                                    mainAxisAlignment="start"
                                    crossAxisAlignment="center">
                                    {grade.description}
                                </FlexBox>*/}
                            </FlexBox>
                            // <FlexBox crossAxisAlignment="center" className={classes.gradeItem} key={index}>
                            //     <FlexBox flex={2}>{grade.name}</FlexBox>
                            //     <FlexBox flex={7}>{grade.description}</FlexBox>
                            //     {/*<FlexBox flex={1}>{grade.created && new Date(grade.created * 1000)?.toLocaleDateString()}</FlexBox>*/}
                            // </FlexBox>
                        ))
                    }
                </FlexBox>
                {/*<pre>*/}
                {/*    {JSON.stringify(viewModel.grades, null, 2)}*/}
                {/*</pre>*/}
            </Card>
        </FlexBox>
    )
    // endregion
});
// endregion

// region styles
const useStyles = createUseStyles({
    gradeItem: {
        color: ({theme}: any) => theme.foregroundSecondary,
        border: ({theme}: any) => `1px solid ${theme.foregroundSecondary}33`,
        borderRadius: styles.size(0.75),
        height: styles.size(4),
        paddingInline: styles.size(1),
        transition: "all 0.2s ease-in-out",
    },
    goBack: {
        cursor: "pointer",
    },
    goBackIcon: {
        fontSize: styles.size(1.5),
        "&:before": {
            color: ({theme}: any) => theme.foregroundSecondary,
        }
    },
    title: {
        fontWeight: 500,
        color: ({theme}: any) => theme.foreground,
        fontSize: styles.size(1.5),
        margin: 0,
    },
    item: {
        "&:hover": {
            "& > div": {
                backgroundColor: ({theme}: any) => theme.elementBackgroundSecondary,
            }
        },
    },
    eventsBox: {
        border: ({theme}: any) => `1px solid ${theme.elementBackgroundSecondary}`,
        borderRadius: styles.size(0.75),
        fontSize: styles.size(1),
        color: ({theme}: any) => theme.foregroundSecondary,
        height: styles.size(3),
        paddingInline: styles.size(1),
        cursor: "pointer",
    },
    eventBoxActive: {
        backgroundColor: ({theme}: any) => theme.elementBackgroundSecondary,
    },
});
// endregion

// region export
export default function ProfessorCourse() {
    const viewModel: ProfessorViewModel = useOutletContext();
    return <ProfessorCourseView viewModel={viewModel} />
}
// endregion