import { FlexBox } from "components/studium_ui";
import * as styles from "utils/styles";
import { GeppettoMessage } from "api/geppetto";
import React, { useEffect, useRef, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import icons from "utils/icons";
import Icon from "components/icon/icon";
import { className } from "utils/className";
import { useNotifications } from "reapop";
import { useItn } from "utils/itn";
import { itns } from "pages/students/texts/subjects.itn";
import useWindowDimensions from "utils/useWindowDimensions";
import { useOutsideEvent } from "utils/useOutsideEvent";
import chatBackground from "assets/images/chat_background.svg";
import geppettoPicture from "assets/images/geppetto.webp";

type GeppettoProps = {
	messages?: GeppettoMessage[];
	onMessageSend?: (message: string) => void;
	geppettoIsTyping?: boolean;
	showError?: boolean;
};

export default function Geppetto(props: GeppettoProps) {
	const theme = useTheme();
	const classes = useStyles({ theme });
	const { notify } = useNotifications();
	const [actualMessage, setActualMessage] = useState<string>("");
	const [isChatOpened, setIsChatOpened] = useState(false);
	const [isChatOpenedEffect, setIsChatOpenedEffect] = useState(false);
	const [ellipsis, setEllipsis] = useState(0);
	const chatContainerRef = useRef<HTMLDivElement>(null);
	const chatContentRef = useRef<HTMLDivElement>(null);
	const [atBottom, setAtBottom] = useState(true);
	const { itn } = useItn(itns);
	const { height, width } = useWindowDimensions();
	// useOutsideEvent(chatContainerRef, () => setOpenChat(false));

	function sendMessage() {
		if (!actualMessage || !props.onMessageSend) return;
		props.onMessageSend(actualMessage);

		setActualMessage("");
	}

	function handleKeyDown(event: React.KeyboardEvent<HTMLTextAreaElement>) {
		if (event.key === "Enter" && !event.shiftKey) {
			event.preventDefault();
			if (props.geppettoIsTyping)
				return notify(
					"Espere o Geppetto terminar de escrever",
					"warning"
				);
			sendMessage();
		}
	}

	function openChat() {
		setIsChatOpened(true);
		setTimeout(() => {
			setIsChatOpenedEffect(true);
		}, 10);
	}

	function closeChat() {
		setIsChatOpenedEffect(false);
		setTimeout(() => {
			setIsChatOpened(false);
		}, 300);
	}

	useEffect(() => {
		const chatContent = chatContentRef.current;

		if (!chatContent) return;
		function handleScroll() {
			if (!chatContent) return;
			const isAtBottom =
				chatContent.scrollTop + chatContent.clientHeight ===
				chatContent.scrollHeight;
			setAtBottom(isAtBottom);
		}

		chatContent.addEventListener("scroll", handleScroll);

		return () => {
			chatContent.removeEventListener("scroll", handleScroll);
		};
	}, []);

	useEffect(() => {
		if (atBottom) {
			if (!chatContentRef.current) return;
			chatContentRef.current.scrollTop =
				chatContentRef.current.scrollHeight;
		}
	}, [atBottom, props.messages, props.geppettoIsTyping]); // atualiza a lógica para verificar as novas mensagens

	// function handleNewMessage() {
	//     // adicione a nova mensagem à lista de mensagens
	//     messages.push("Nova mensagem");
	//
	//     // atualiza o estado para forçar o chat a rolar para a parte inferior
	//     setAtBottom(true);
	// }

	useEffect(() => {
		if (!props.geppettoIsTyping) return setEllipsis(0);
		const intervalId = setInterval(() => {
			setEllipsis((ellipsis) => (ellipsis + 1) % 4);
		}, 350);

		return () => clearInterval(intervalId);
	}, [props.geppettoIsTyping]);

	return (
		<FlexBox
			mainAxisAlignment="center"
			crossAxisAlignment="center"
			className={classes.container}
			direction="column"
			gap={styles.size(1)}>
			{isChatOpened ? (
				<FlexBox
					ref={chatContainerRef}
					column
					className={className([
						classes.chatContainer,
						isChatOpenedEffect ? classes.chatContainerOpened : null,
					])}
					style={{ maxHeight: `${height - 120}px` }}>
					<FlexBox
						className={classes.chatHeader}
						mainAxisAlignment="space-between"
						crossAxisAlignment="center">
						<FlexBox
							crossAxisAlignment="center"
							gap={styles.size(1)}>
							<img
								src={geppettoPicture}
								alt="Geppetto"
								style={{
									width: styles.size(2.5),
									// @ts-ignore
									border: `1px solid rgba(255, 255, 255, .2)`,
									height: styles.size(2.5),
									borderRadius: "50%",
								}}
							/>
							<span style={{ fontSize: styles.size(1.2) }}>
								Geppetto
							</span>
						</FlexBox>
						<FlexBox
							style={{ cursor: "pointer", userSelect: "none" }}
							onClick={() => closeChat()}>
							<Icon
								// className={classes.icon}
								size={styles.size(1.5)}
								name={icons.closeLine}
							/>
						</FlexBox>
					</FlexBox>
					<FlexBox
						ref={chatContentRef}
						column
						className={classes.chatContent}
						style={{ backgroundImage: `url(${chatBackground})` }}
						gap={styles.size(1)}>
						{props.messages?.map((item, index) => (
							<FlexBox
								key={index}
								crossAxisAlignmentSelf={
									item.role === "user" ? "end" : "start"
								}
								className={
									item.role === "user"
										? classes.userMessage
										: classes.geppettoMessage
								}>
								{/*{item.role === "User"   ? <FlexBox>Eu</FlexBox> : null}*/}
								{/*{item.role === "Assistant" ? <FlexBox>Geppetto</FlexBox> : null}*/}
								<FlexBox>{item.content}</FlexBox>
							</FlexBox>
						))}
						{props.geppettoIsTyping ? (
							<FlexBox
								crossAxisAlignmentSelf="start"
								className={classes.geppettoMessage}>
								<FlexBox>
									{itn.content.geppetto.typing}
									<span style={{ width: "10px" }}>
										{Array(ellipsis + 1).join(".")}
									</span>
								</FlexBox>
							</FlexBox>
						) : null}
					</FlexBox>
					{props.showError ? (
						<FlexBox
							onClick={() => window.location.reload()}
							className={classes.errorMessage}>
							{itn.content.geppetto.errorTryAgain}
						</FlexBox>
					) : null}
					<FlexBox
						gap={styles.size(0.5)}
						crossAxisAlignment="center"
						className={classes.messageField}>
						<textarea
							value={actualMessage}
							onChange={(e) => setActualMessage(e.target.value)}
							onKeyDown={handleKeyDown}
							placeholder={
								itn.content.geppetto.askGeppettoSomething
							}
						/>
						<Icon
							className={className([
								classes.sendButton,
								props.geppettoIsTyping ||
								actualMessage.length === 0
									? classes.sendButtonDisabled
									: null,
							])}
							style={
								props.geppettoIsTyping ? { opacity: 0.5 } : {}
							}
							onClick={sendMessage}
							size={styles.size(1.5)}
							name={icons.sendPlaneFill}
						/>
					</FlexBox>
				</FlexBox>
			) : null}
			<FlexBox
				crossAxisAlignment="center"
				mainAxisAlignment="center"
				className={classes.buttonTrigger}
				onClick={() => (isChatOpened ? closeChat() : openChat())}>
				{itn.content.geppetto.chatWithGeppetto}
			</FlexBox>
		</FlexBox>
	);
}

// region styles
const useStyles = createUseStyles({
	container: {
		position: "relative",
		// width: styles.size(20),
		// height: styles.size(3),
		zIndex: 10,
	},
	buttonTrigger: {
		backgroundColor: ({ theme }: any) => theme.elementBlue,
		borderRadius: styles.size(0.5),
		paddingInline: styles.size(1),
		height: styles.size(3),
		userSelect: "none",
		cursor: "pointer",
		color: "white",
		transition: "background-color 0.2s ease-in-out",
		"&:hover": {
			backgroundColor: ({ theme }: any) =>
				styles.darken(theme.elementBlue, 0.1),
		},
		"&:active": {
			backgroundColor: ({ theme }: any) =>
				styles.darken(theme.elementBlue, 0.3),
		},
	},
	chatContainer: {
		position: "absolute",
		top: styles.size(-1),
		left: 0,
		width: styles.size(30),
		border: ({ theme }: any) =>
			styles.border(`rgba(${theme.contrast},0.1)`, "solid", 1),
		borderRadius: styles.size(1),
		height: styles.size(40),
		// maxHeight: `calc(100vh - ${styles.size(10)})`,
		maxWidth: `calc(100vw - ${styles.size(2)})`,
		backgroundColor: ({ theme }: any) => theme.background,
		boxShadow: ({ theme }: any) => `0 5px 15px 5px rgba(0, 0, 0,0.15)`,
		transition: "transform 0.2s ease-out, opacity 0.15s ease-out",
		transform: "translateY(-75%) translateX(-25%) scale(0.5)",
		opacity: 0,
	},
	chatContainerOpened: {
		transform: "translateY(-100%) translateX(0%) scale(1)",
		opacity: 1,
	},
	chatHeader: {
		backgroundColor: ({ theme }: any) => theme.elementBlue,
		color: "white",
		borderRadius: `${styles.size(1)} ${styles.size(1)} 0 0`,
		paddingInline: styles.size(1),
		paddingBlock: styles.size(0.5),
		borderBottom: ({ theme }: any) =>
			styles.border(`${theme.background}77`, "solid", 1),
		"& span": {
			color: "white",
		},
	},
	chatContent: {
		padding: styles.size(1),
		overflowY: "auto",
		flex: 1,
	},
	userMessage: {
		backgroundColor: ({ theme }: any) => theme.elementBlue,
		marginLeft: styles.size(2),
		paddingBlock: styles.size(0.5),
		paddingInline: styles.size(1),
		borderRadius: `${styles.size(1)} ${styles.size(1)} ${styles.size(
			0.25
		)} ${styles.size(1)}`,
		fontSize: styles.size(1),
		flex: 0,
		boxShadow: ({ theme }: any) => `0 1px 2px 1px rgba(0, 0, 0,0.1)`,
		border: ({ theme }: any) =>
			styles.border(`rgba(${theme.contrast}, .15)`, "solid", 1),
		color: "white",
	},
	geppettoMessage: {
		backgroundColor: ({ theme }: any) => theme.backgroundAlt,
		borderRadius: `${styles.size(1)} ${styles.size(1)} ${styles.size(
			1
		)} ${styles.size(0.25)}`,
		marginRight: styles.size(2),
		paddingBlock: styles.size(0.5),
		paddingInline: styles.size(1),
		fontSize: styles.size(1),
		flex: 0,
		boxShadow: ({ theme }: any) => `0 1px 2px 1px rgba(0, 0, 0,0.1)`,
		border: ({ theme }: any) =>
			styles.border(`rgba(${theme.contrast}, .15)`, "solid", 1),
	},
	errorMessage: {
		backgroundColor: "rgba(255, 40, 80, 0.2)",
		color: "rgba(255, 40, 80, 1)",
		margin: styles.size(1),
		border: styles.border("rgba(255, 40, 80, 1)", "solid", 1),
		textAlign: "center",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: styles.size(0.5),
		fontSize: styles.size(1),
		"&:hover": {
			backgroundColor: "rgba(255, 40, 80, 0.3)",
		},
		"&:active": {
			backgroundColor: "rgba(255, 40, 80, 0.4)",
		},
	},
	messageField: {
		borderTop: ({ theme }: any) =>
			styles.border(`${theme.background}77`, "solid", 1),
		backgroundColor: ({ theme }: any) => theme.backgroundAlt,
		paddingRight: styles.size(1),
		borderRadius: `0 0 ${styles.size(1)} ${styles.size(1)}`,
		"& textarea": {
			border: "none",
			backgroundColor: "transparent",
			outline: "none",
			flex: 1,
			minHeight: styles.size(4),
			height: "auto",
			outlineWidth: 0,
			paddingInline: styles.size(1),
			paddingBlock: styles.size(0.5),
			fontSize: styles.size(1),
			resize: "none",
		},
		"& textarea::placeholder": {
			// color: ({ theme }: any) => theme.textAlt,
		},
		"& span": {
			cursor: "pointer",
		},
	},
	sendButton: {
		cursor: "pointer",
	},
	sendButtonDisabled: {
		opacity: 0.5,
		cursor: "not-allowed !important",
	},
});
